import React from "react";

//IMPORTING STYLE
import '../styles/MainPage.css';

import HomePage from './HomePage';
import RegisterPage from './RegisterPage';
import DietPageLogged from "./DietPageLogged";
import DietPageNonLogged from "./DietPageNonLogged";
import EditProfile from "./EditProfile";
import EditPassword from "./EditPassword";
import HistoricPage from "./HistoricPage";
import HistoricPageNonLogged from "./HistoricPageNonLogged";

export default function MainPage(props) {
    //_________________________________RENDERING_COMPONENT_________________________________
    return (
        <main id='main'>
            {props.currentPage == "home-page" && <HomePage />}
            {props.currentPage == "register-page" && <RegisterPage
                handleLoggedState={props.handleLoggedState}
                handleCurrentPage={props.handleCurrentPage}
                changeUserInfos={props.changeUserInfos}
                showLoginTab={props.showLoginTab} />}
            {props.currentPage == "diet-page" && props.currentLoggedState && <DietPageLogged
                userInfos={props.userInfos}
            />}
            {props.currentPage == "diet-page" && !props.currentLoggedState && <DietPageNonLogged
                showLoginTab={props.showLoginTab}
                handleCurrentPage={props.handleCurrentPage}
            />}
            {props.currentPage === "edit-profile-page" && <EditProfile
                userInfos={props.userInfos}
                changeUserInfos={props.changeUserInfos}
                changePage={props.handleCurrentPage}
            />}
            {props.currentPage === "edit-password-profile-page" && <EditPassword
                handleCurrentPage={props.handleCurrentPage}
                showLoginTab={props.showLoginTab}
            />}
            {props.currentPage === "historic-page" && props.currentLoggedState && <HistoricPage />}
            {props.currentPage === "historic-page" && !props.currentLoggedState && <HistoricPageNonLogged
                showLoginTab={props.showLoginTab}
                handleCurrentPage={props.handleCurrentPage}
            />}
        </main>
    )
}