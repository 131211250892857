import React from "react";

//IMPORTING STYLE
import '../styles/SideMenu.css';

//IMPORTING ICONS
import logo_x from "../icons/x_icon.png"

export default function SideMenu(props) {
    //_________________________________DECLARING_FUNCTIONS_________________________________
    function dark_mode_switch() {
        if (document.getElementById("dark_mode_switch").className == "dark_mode_switch_off") {
            document.getElementById("dark_mode_switch").className = "dark_mode_switch_on"
        } else {
            document.getElementById("dark_mode_switch").className = "dark_mode_switch_off"
        }
        if (document.getElementById("dark_mode_container").className == "dark_mode_container_off") {
            document.getElementById("dark_mode_container").className = "dark_mode_container_on"
        } else {
            document.getElementById("dark_mode_container").className = "dark_mode_container_off"
        }
        //document.body.classList.toggle("dark_mode")

        document.getElementById("side_menu").className = "side_menu_off"
        document.body.style.overflow = ""
    }

    function close_side_menu() {
        document.getElementById("side_menu").className = "side_menu_off"
        document.body.style.overflow = ""
    }

    //SWITCH PAGE TO HOME
    function goToHomePage() {
        props.handleClick("home-page")
        document.body.style.overflow = ""
    }

    //SWITCH PAGE TO DIET PAGE
    function goToDietPage() {
        props.handleClick("diet-page")
        document.body.style.overflow = ""
    }

    //SWITCH PAGE TO DIET PAGE
    function goToHistoricPage() {
        props.handleClick("historic-page")
        document.body.style.overflow = ""
    }

    //_________________________________RENDERING_COMPONENT_________________________________
    return (
        <div id="side_menu" className="side_menu_off">
            <div>
                <button id="side_menu_top_section" onClick={close_side_menu}>
                    <h3 id="side_menu_title">Menu</h3>
                    <img src={logo_x} id="side_menu_close_icon" draggable="false"></img>
                </button>
                <div id="links">
                    <button id="link" onClick={goToHomePage}>Home</button>
                    <button id="link" onClick={goToDietPage}>Alimentazione</button>
                    <button id="link" onClick={goToHistoricPage}>Storico</button>
                </div>
            </div>
            {/* <div id="dark_mode">
                <label for="dark_mode_button" id="dark_mode_label">Dark Mode</label>
                <button id="dark_mode_button" onClick={dark_mode_switch}>
                    <div id="dark_mode_container" className="dark_mode_container_off">
                        <div id="dark_mode_switch" className="dark_mode_switch_off"></div>
                    </div>
                </button>
            </div> */}
        </div>
    )
}