import React from "react";

//IMPORTING STYLE
import '../styles/LoginTab.css'

//IMPORTING ICONS
import x_icon from "../icons/x_icon.png"
import eye from "../icons/eye.png"
import eye_slashed from "../icons/hidden_password.png"


export default function LoginTab(props) {
    //_________________________________DECLARING_REFS_________________________________
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [remember, toggleRemember] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState("")
    const [showPwdIcon, setShowPwdIcon] = React.useState(eye_slashed)


    //_________________________________DECLARING_FUNCTIONS_________________________________
    //COMUNICATING WITH DATABASE

    const handleSubmit = () => {
        //CREATIGN XMLHTTP REQUEST
        const ajax = new XMLHttpRequest()
        ajax.onreadystatechange = function () {
            if (ajax.readyState == 4 && ajax.status == 200) {
                switch (ajax.response) {
                    case "email_inexistent":
                        setErrorMsg("E-mail non Registrata!")
                        document.getElementById("login_email_input").className = "login_input_invalid"
                        document.getElementById("login_password_input").className = "login_input_invalid"
                        document.getElementById("login_error").className = "login_error_shown"
                        break;
                    case "email_inactive":
                        setErrorMsg("E-mail non Confermata!")
                        document.getElementById("login_error").className = "login_error_shown"
                        break;
                    case "wrong_credentials":
                        setErrorMsg("Credenziali Errate!")
                        document.getElementById("login_email_input").className = "login_input_invalid"
                        document.getElementById("login_password_input").className = "login_input_invalid"
                        document.getElementById("login_error").className = "login_error_shown"
                        break;
                    default:
                        if (ajax.responseText.substring(0, 5) === "Error") {
                            console.log(ajax.response)
                            return;
                        }
                        if (props.currentPage === "register-page") props.handleClick("home-page")
                        props.handleLoginState()
                        closeLoginTab()
                        props.handleUserInfos(JSON.parse(ajax.response))
                }
            }
        }
        ajax.open("POST", "php/login.php", true);
        ajax.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        ajax.send(`email=${email}&password=${password}&remember=${remember}`);
    }

    //TOGGLE THE REMEMBER CHECKBOX VALUE
    function handleRememberState() {
        toggleRemember(prevValue => !prevValue)
    }

    //HANDLING THE REGISTER BUTTON
    function goToRegisterPage() {
        props.handleLoginTab()
        props.handleClick("register-page")
        document.body.style.overflow = ""                                            //ACTIVATE SCROLLING
        document.getElementById("header_account_button").disabled = false
        document.getElementById("header_menu_button").disabled = false
    }

    //HANDLING THE X BUTTON ON TOP OF THE LOGIN TAB
    function closeLoginTab() {
        let login_container = document.getElementById("login_tab_container")
        let login_tab = document.getElementById("login_tab")

        //ANIMATING THE LOGIN TAB CONTAINER
        login_container.style.animation = "blur_disappear .3s cubic-bezier(0.190, 0.465, 0.690, 1.370) forwards 1"

        //ANIMATING THE LOGIN TAB
        login_tab.style.animation = "disappear .3s cubic-bezier(0.190, 0.465, 0.690, 1.370) forwards 1"

        //CLOSING THE WINDOW TAB ON ANIMATION FINISHED
        login_tab.addEventListener('animationend', () => {
            props.handleLoginTab()
            document.body.style.overflow = ""
            document.getElementById("header_account_button").disabled = false
            document.getElementById("header_menu_button").disabled = false
        });
    }

    //RESET ERROR
    function removeError() {
        document.getElementById("login_email_input").className = "login_input"
        document.getElementById("login_password_input").className = "login_input"
        document.getElementById("login_error").className = "login_error_hidden"
        setErrorMsg("")
    }

    //SHOW PASSWORD
    function showPassword() {
        let show_password_icon = document.getElementById("login_password_input")
        if (showPwdIcon === eye_slashed) {
            setShowPwdIcon(eye)
            show_password_icon.type = "text"
        } else {
            setShowPwdIcon(eye_slashed)
            show_password_icon.type = "password"
        }
    }

    //GO TO THE EDIT PASSWORD PAGE
    function goToEditPassowrdPage() {
        props.handleLoginTab()
        document.body.style.overflow = ""
        document.getElementById("header_account_button").disabled = true
        document.getElementById("header_menu_button").disabled = false
        props.handleClick("edit-password-profile-page")
    }

    //_________________________________RENDERING_COMPONENT_________________________________
    return (
        <div id="login_tab_container" className="login_tab_container_off">
            <div id="login_tab" className="login_tab_off">
                <button id="login_tab_close_button" onClick={closeLoginTab}>
                    <img id="login_tab_close_button_icon" src={x_icon}></img>
                </button>
                <div id="login_tab_form_input">
                    <h1 id="login_title">Accedi</h1>
                    <div id="login_error_container">
                        <p id="login_error" className="login_error_hidden">{errorMsg}</p>
                    </div>
                    <input
                        type="text"
                        placeholder="E-mail"
                        id="login_email_input"
                        className="login_input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={removeError}
                    />
                    <div id="login_password_input_container">
                        <input
                            type="password"
                            placeholder="Password"
                            id="login_password_input"
                            className="login_input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={removeError}
                        />
                        <button id="show_password_section" onClick={showPassword}>
                            <img id="show_password_icon" src={showPwdIcon}></img>
                        </button>
                    </div>
                    <section id="login_remember_section">
                        <input
                            type="checkbox"
                            id="login_remember_button"
                            value={remember}
                            onChange={handleRememberState}
                        />
                        <label for="login_remember_button">Ricordami</label>
                    </section>
                    <input type="submit" name="submit" value="Avanti" id="login_form_button" onClick={handleSubmit} />
                    <button id="recover_password" onClick={goToEditPassowrdPage}>Password Dimenticata?</button>
                </div>
                <hr />
                <section id="login_tab_bottom_section">
                    <p>oppure</p>
                    <button id="login_tab_register_button" onClick={goToRegisterPage}>Registrati</button>
                </section>
            </div>
        </div>
    )
}