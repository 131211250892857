import React from "react";

//IMPORTING STYLE
import "../styles/Footer.css"

export default function Footer() {
    return (
        <footer id="footer">
            <div id="footer_links">
                <div id="footer_wrapper">
                    <p>Per informazioni o supporto:</p>
                    <a href="mailto:smartmeal0@gmail.com" id="footer_link">smartmeal0@gmail.com</a>
                </div>
            </div>
            <div id="footer_credits">
                <div id="footer_wrapper">
                    <p>Un'applicazione realizzata da:</p>
                    <section id="names_section">
                        <p>Fabio Fragnelli</p>
                        <p>e</p>
                        <p>Giacinto Lombardi</p>
                    </section>
                </div>
            </div>
        </footer>
    )
}