import React, { useEffect } from "react";

import foodList from "../food_list";

import delete_food from "../icons/delete_food.png"

export default function DietPageNonLogged(props) {

    useEffect(() => {
        document.getElementById("header_account_button").disabled = true

        //SET ANIMATIONS
        document.getElementById("cover_non_logged").style.animation = "not_logged_screen_blur .7s ease forwards"
        document.getElementById("cover_non_logged_label").style.animation = "move_in .6s ease .1s forwards"
        document.getElementById("not_logged_login_button").style.animation = "move_in .6s ease .3s forwards"
        document.getElementById("vertical_bar").style.animation = "move_in .6s ease .4s forwards"
        document.getElementById("not_logged_register_button").style.animation = "move_in .6s ease .5s forwards"
    }, [])

    //_________________________________DECLARING_FUNCTIONS_________________________________
    //LOGIN
    function loginButton() {
        props.showLoginTab()
    }

    //LOGOUT
    function logoutButton() {
        props.handleCurrentPage("register-page")
        document.getElementById("header_account_button").disabled = false
    }


    //_________________________________RENDERING_COMPONENT_________________________________
    return (
        <div id="diet_page">
            <div id="cover_non_logged">
                <div id="options_container">
                    <p id="cover_non_logged_label">Per utilizzare questa pagina:</p>
                    <div id="buttons_container">
                        <button id="not_logged_login_button" onClick={loginButton}>Accedi</button>
                        <div id="vertical_bar"></div>
                        <button id="not_logged_register_button" onClick={logoutButton}>Registrati</button>
                    </div>
                </div>
            </div>
            <div id="add_food_container">
                <div id="add_food_input_container">
                    <input
                        id="add_food_name_input"
                        className="add_food_name_input_valid"
                        type="text"
                        placeholder="Inserisci Alimento"
                    />
                    <input
                        id="add_food_quantity_input"
                        className="add_food_quantity_input_valid"
                        type="number"
                        placeholder="Grammi (g)"
                    />
                </div>
                <button id="add_food_button">Aggiungi</button>
            </div>
            <div id="diet_page_container">
                <div id="foods_eaten_list">
                    <section id="food_section">
                        <p id="food_name">Fragole</p>
                        <p id="food_quantity">100g</p>
                        <button id="delete_food_button">
                            <img id="delete_food_icon" src={delete_food} />
                        </button>
                    </section>
                    <section id="food_section">
                        <p id="food_name">Pistacchi Tostati</p>
                        <p id="food_quantity">40g</p>
                        <button id="delete_food_button">
                            <img id="delete_food_icon" src={delete_food} />
                        </button>
                    </section>
                    <section id="food_section">
                        <p id="food_name">Pasta di Semola</p>
                        <p id="food_quantity">120g</p>
                        <button id="delete_food_button">
                            <img id="delete_food_icon" src={delete_food} />
                        </button>
                    </section>
                </div>
                <div id="foods_eaten_graph">
                    <table id="foods_eaten_graph_table">
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[0]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: "30%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">694.6 K/cal</p>
                            </td>
                            <td>
                                <p id="value_percentage">30%</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[1]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: "0%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">21.02 g</p>
                            </td>
                            <td>
                                <p id="value_percentage">0%</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[2]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: "0%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">22.92 g</p>
                            </td>
                            <td>
                                <p id="value_percentage">0%</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[3]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: "0%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">2.91 g</p>
                            </td>
                            <td>
                                <p id="value_percentage">0%</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[4]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: "0%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">107.94 g</p>
                            </td>
                            <td>
                                <p id="value_percentage">0%</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[5]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: "0%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">10.82 g</p>
                            </td>
                            <td>
                                <p id="value_percentage">0%</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[6]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: "40%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">7.16 g</p>
                            </td>
                            {
                                <td>
                                    <p id="value_percentage">0%</p>
                                </td>
                            }
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}