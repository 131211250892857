import React from "react";

import "../styles/EditProfile.css"

export default function EditProfile(props) {
    //_________________________________DECLARING_STATES_________________________________
    //SETTING THE VALUES STATES
    const [fName, setFName] = React.useState(props.userInfos.fName)
    const [lName, setLName] = React.useState(props.userInfos.lName)
    const [bday, setBday] = React.useState(props.userInfos.birthday)
    const [weight, setWeight] = React.useState(props.userInfos.weight)
    const [height, setHeight] = React.useState(props.userInfos.height)

    //_________________________________DECLARING_FUNCTIONS_________________________________
    //SUBMIT ACTION
    const handleSubmit = (event) => {
        event.preventDefault();

        //RESETTING PREVIOUS ERROR TRIGGERED
        fNameFocus()
        lNameFocus()
        bDayFocus()
        weightFocus()
        heightFocus()

        if (!isFNameValid() || !isLNameValid() || !isBDayValid() || !isWeightValid() || !isHeightValid()) {
            return
        }

        //CREATING THE HTTP REQUEST
        const httpr = new XMLHttpRequest()
        httpr.onreadystatechange = function () {
            if (httpr.readyState == 4 && httpr.status == 200) {
                switch (httpr.response) {
                    case "success":
                        props.changeUserInfos({
                            firstName: fName,
                            lastName: lName,
                            bday: bday,
                            weight: weight,
                            height: height,
                        })
                        props.changePage("home-page")
                        break;
                    default:
                        console.log(httpr.response)
                        break;
                }
            }
        }
        httpr.open("POST", "php/updateProfile.php", true)
        httpr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        httpr.send(
            `fName=${fName}&lName=${lName}&bday=${bday}&weight=${weight}&height=${height}`
        )
    }


    //______________________________FOCUS_HANDLERS______________________________
    function fNameFocus() {
        if (document.getElementById("edit_profile_fName_error").className === "edit_profile_fName_error_on")
            document.getElementById("edit_profile_fName_error").className = "edit_profile_fName_error_off"
    }

    function lNameFocus() {
        if (document.getElementById("edit_profile_lName_error").className === "edit_profile_lName_error_on")
            document.getElementById("edit_profile_lName_error").className = "edit_profile_lName_error_off"
    }

    function bDayFocus() {
        if (document.getElementById("edit_profile_bDay_error").className === "edit_profile_bDay_error_on")
            document.getElementById("edit_profile_bDay_error").className = "edit_profile_bDay_error_off"
    }

    function weightFocus() {
        if (document.getElementById("edit_profile_weight_error").className === "edit_profile_weight_error_on")
            document.getElementById("edit_profile_weight_error").className = "edit_profile_weight_error_off"
    }

    function heightFocus() {
        if (document.getElementById("edit_profile_height_error").className === "edit_profile_height_error_on")
            document.getElementById("edit_profile_height_error").className = "edit_profile_height_error_off"
    }


    //______________________________CHECKING_FOR_VALID_VALUES______________________________
    //CHECKING FIRST NAME
    function isFNameValid() {
        if (fName === "") {
            document.getElementById("edit_profile_fName_error").className = "edit_profile_fName_error_on"
            document.getElementById("edit_profile_fName_error").scrollIntoView({ block: "center", inline: "nearest" })
            return false
        }
        return true
    }

    //CHECKING LAST NAME
    function isLNameValid() {
        if (lName === "") {
            document.getElementById("edit_profile_lName_error").className = "edit_profile_lName_error_on"
            document.getElementById("edit_profile_lName_error").scrollIntoView({ block: "center", inline: "nearest" })
            return false
        }
        return true
    }

    //CHECKING BIRTHDAY
    function isBDayValid() {
        if (bday === "") {
            document.getElementById("edit_profile_bDay_error").className = "edit_profile_bDay_error_on"
            document.getElementById("edit_profile_bDay_error").scrollIntoView({ block: "center", inline: "nearest" })
            return false
        }
        return true
    }

    //CHECKING WEIGHT
    function isWeightValid() {
        if (weight === "") {
            document.getElementById("edit_profile_weight_error").className = "edit_profile_weight_error_on"
            document.getElementById("edit_profile_weight_error").scrollIntoView({ block: "center", inline: "nearest" })
            return false
        }
        return true
    }

    //CHECKING HEIGHT
    function isHeightValid() {
        if (height === "") {
            document.getElementById("edit_profile_height_error").className = "edit_profile_height_error_on"
            document.getElementById("edit_profile_height_error").scrollIntoView({ block: "center", inline: "nearest" })
            return false
        }
        return true
    }

    //_________________________________RENDERING_COMPONENT_________________________________
    return (
        <div id="edit_profile_page">
            <form id="edit_profile_form" onSubmit={handleSubmit}>
                <h1 id="edit_profile_title">Modifica Profilo</h1>
                <div id="edit_profile_input_container">
                    <div className="edit_profile_input_section">
                        <label className="edit_profile_label">Nome</label>
                        <input
                            className="edit_profile_input"
                            placeholder="Nome"
                            type="text"
                            maxLength={20}
                            value={fName}
                            onChange={(e) => setFName(e.target.value)}
                            onFocus={fNameFocus}
                        />
                        <div id="edit_profile_fName_error" className="edit_profile_fName_error_off">
                            <p>Il campo è obbligatorio</p>
                        </div>
                    </div>
                    <div className="edit_profile_input_section">
                        <label className="edit_profile_label">Cognome</label>
                        <input
                            className="edit_profile_input"
                            placeholder="Cognome"
                            type="text"
                            maxLength={20}
                            value={lName}
                            onChange={(e) => setLName(e.target.value)}
                            onFocus={lNameFocus}
                        />
                        <div id="edit_profile_lName_error" className="edit_profile_lName_error_off">
                            <p>Il campo è obbligatorio</p>
                        </div>
                    </div>
                    <div className="edit_profile_input_section">
                        <label className="edit_profile_label">Data di nascita (gg/mm/aaaa)</label>
                        <input
                            className="edit_profile_input"
                            placeholder="Data di Nascita"
                            type="date"
                            maxLength={10}
                            value={bday}
                            onChange={e => setBday(e.target.value)}
                            onFocus={bDayFocus}
                        />
                        <div id="edit_profile_bDay_error" className="edit_profile_bDay_error_off">
                            <p>Inserisci una data valida</p>
                        </div>
                    </div>
                    <div className="edit_profile_input_section">
                        <label className="edit_profile_label">Peso (Kg)</label>
                        <input
                            className="edit_profile_input"
                            placeholder="Peso"
                            type="number"
                            min={20}
                            max={300}
                            maxLength={10}
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                            onFocus={weightFocus}
                        />
                        <div id="edit_profile_weight_error" className="edit_profile_weight_error_off">
                            <p>Il campo è obbligatorio</p>
                        </div>
                    </div>
                    <div className="edit_profile_input_section">
                        <label className="edit_profile_label">Altezza (cm)</label>
                        <input
                            className="edit_profile_input"
                            placeholder="Altezza"
                            type="number"
                            min={50}
                            max={300}
                            maxLength={10}
                            value={height}
                            onChange={(e) => setHeight(e.target.value)}
                            onFocus={heightFocus}
                        />
                        <div id="edit_profile_height_error" className="edit_profile_height_error_off">
                            <p>Il campo è obbligatorio</p>
                        </div>
                    </div>
                </div>
                <input type="submit" id="edit_profile_continue_button" value="Avanti" />
            </form>
        </div>
    )
}