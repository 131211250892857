import React from "react";

//IMPORTING ICONS
import eye from "../icons/eye.png"
import eye_slashed from "../icons/hidden_password.png"

import "../styles/RegisterPage.css"

export default function RegisterPage(props) {
    //_________________________________DECLARING_STATES_________________________________
    //SETTING THE VALUES STATES
    const [fName, setFName] = React.useState("")
    const [lName, setLName] = React.useState("")
    const [bday, setBday] = React.useState("")
    const [weight, setWeight] = React.useState("")
    const [height, setHeight] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [confPassword, setConfPassword] = React.useState("")
    const [showPwdIcon, setShowPwdIcon] = React.useState(eye_slashed)
    const [showConfPwdIcon, setShowConfPwdIcon] = React.useState(eye_slashed)

    //_________________________________DECLARING_FUNCTIONS_________________________________
    //SUBMIT ACTION
    const handleSubmit = (event) => {
        event.preventDefault();

        //RESETTING PREVIOUS ERROR TRIGGERED
        fNameFocus()
        lNameFocus()
        bDayFocus()
        weightFocus()
        heightFocus()
        emailFocus()
        passwordFocus()
        confPasswordFocus()

        if (!isFNameValid() || !isLNameValid() || !isBDayValid() || !isWeightValid() ||
            !isHeightValid() || !isEmailValid() || !isPasswordValid() || !doPasswordsMatch()) {
            return
        }

        emailSent()

        //CREATING THE HTTP REQUEST
        const httpr = new XMLHttpRequest()
        httpr.onreadystatechange = function () {
            if (httpr.readyState == 4 && httpr.status == 200) {
                switch (httpr.response) {
                    case "email_existent":
                        document.getElementById("email_error_tab").className = "email_error_tab_on"
                        document.getElementById("email_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
                        document.getElementById("email_error_message").innerHTML = "L'email è già registrata"
                        return
                    case "email_sent":
                        return
                    default:
                        if (httpr.responseText.substring(0, 5) === "Error") {
                            console.log(httpr.response)
                            return;
                        }
                        break;
                }
            }
        }
        httpr.open("POST", "php/register.php", true)
        httpr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        httpr.send(
            `fName=${fName}&lName=${lName}&bday=${bday}&weight=${weight}&height=${height}&email=${email}&password=${password}&confPassword=${confPassword}`
        )
    }


    //______________________________FOCUS_HANDLERS______________________________
    function fNameFocus() {
        if (document.getElementById("fName_error_tab").className === "fName_error_tab_on")
            document.getElementById("fName_error_tab").className = "fName_error_tab_off"
    }

    function lNameFocus() {
        if (document.getElementById("lName_error_tab").className === "lName_error_tab_on")
            document.getElementById("lName_error_tab").className = "lName_error_tab_off"
    }

    function bDayFocus() {
        if (document.getElementById("bDay_error_tab").className === "bDay_error_tab_on")
            document.getElementById("bDay_error_tab").className = "bDay_error_tab_off"
    }

    function weightFocus() {
        if (document.getElementById("weight_error_tab").className === "weight_error_tab_on")
            document.getElementById("weight_error_tab").className = "weight_error_tab_off"
    }

    function heightFocus() {
        if (document.getElementById("height_error_tab").className === "height_error_tab_on")
            document.getElementById("height_error_tab").className = "height_error_tab_off"
    }

    function emailFocus() {
        if (document.getElementById("email_error_tab").className === "email_error_tab_on") {
            document.getElementById("email_error_tab").className = "email_error_tab_off"
        }
    }

    function passwordFocus() {
        if (document.getElementById("password_error_tab").className === "password_error_tab_on") {
            document.getElementById("password_error_tab").className = "password_error_tab_off"
        }
    }

    function confPasswordFocus() {
        if (document.getElementById("confPassword_error_tab").className === "confPassword_error_tab_on") {
            document.getElementById("confPassword_error_tab").className = "confPassword_error_tab_off"
        }
    }


    //______________________________CHECKING_FOR_VALID_VALUES______________________________
    //CHECKING FIRST NAME
    function isFNameValid() {
        if (fName === "") {
            document.getElementById("fName_error_tab").className = "fName_error_tab_on"
            document.getElementById("fName_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
            return false
        }
        return true
    }

    //CHECKING LAST NAME
    function isLNameValid() {
        if (lName === "") {
            document.getElementById("lName_error_tab").className = "lName_error_tab_on"
            document.getElementById("lName_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
            return false
        }
        return true
    }

    //CHECKING BIRTHDAY
    function isBDayValid() {
        if (bday === "") {
            document.getElementById("bDay_error_tab").className = "bDay_error_tab_on"
            document.getElementById("bDay_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
            return false
        }
        return true
    }

    //CHECKING WEIGHT
    function isWeightValid() {
        if (weight === "") {
            document.getElementById("weight_error_tab").className = "weight_error_tab_on"
            document.getElementById("weight_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
            return false
        }
        return true
    }

    //CHECKING HEIGHT
    function isHeightValid() {
        if (height === "") {
            document.getElementById("height_error_tab").className = "height_error_tab_on"
            document.getElementById("height_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
            return false
        }
        return true
    }

    //CHECKING EMAIL
    function isEmailValid() {
        let atSymbol = 0, atSymbolIndex = -1
        if (email === "") {
            document.getElementById("email_error_tab").className = "email_error_tab_on"
            document.getElementById("email_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
            document.getElementById("email_error_message").innerHTML = "Il campo è obbligatorio"
            return false
        }
        for (let i = 0; i < email.length; i++) {
            if (email[i] === '@') {
                atSymbol++
                atSymbolIndex = i
            }
        }
        if (atSymbol != 1 || !(atSymbolIndex < email.length - 1)) {
            document.getElementById("email_error_tab").className = "email_error_tab_on"
            document.getElementById("email_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
            document.getElementById("email_error_message").innerHTML = "Formato dell'email non valido"
            return false
        }
        return true
    }

    //CHECKING PASSWORD
    function isPasswordValid() {
        if (password === "") {
            document.getElementById("password_error_tab").className = "password_error_tab_on"
            document.getElementById("password_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
            document.getElementById("password_error_message").innerHTML = "<p>Il campo è obbligatorio</p>"
            return false
        }

        let capitalLetter = false, smallLetter = false, specialCharacter = false, minLength = 8
        for (let i = 0; i < password.length; i++) {
            let character = password.charCodeAt(i)
            if (!capitalLetter && character >= 65 && character <= 90) {
                capitalLetter = true
            }
            if (!smallLetter && character >= 97 && character <= 122) {
                smallLetter = true
            }
            if (!specialCharacter && ((character <= 64) || (character >= 91 && character <= 96) || (character >= 123))) {
                specialCharacter = true
            }
        }
        if (!capitalLetter || !smallLetter || !specialCharacter || password.length < minLength) {
            document.getElementById("password_error_tab").className = "password_error_tab_on"
            document.getElementById("password_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
            document.getElementById("password_error_message").innerHTML = `
                <p>La Password deve avere:</p>
                <ul>
                    <li>un carattere mauiscolo e uno minuscolo</li>
                    <li>un carattere speciale</li>
                    <li>lunghezza minima di ${minLength}</li>
                </ul>`
            return false
        }
        return true
    }

    //CHECKING IF PASSWORD AND CONFIRM PASSWORD MATCH
    function doPasswordsMatch() {
        if (confPassword === "") {
            document.getElementById("confPassword_error_tab").className = "confPassword_error_tab_on"
            document.getElementById("confPassword_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
            return false
        }
        if (password === confPassword) {
            return true
        }
        document.getElementById("confPassword_error_tab").className = "confPassword_error_tab_on"
        document.getElementById("confPassword_error_tab").scrollIntoView({ block: "center", inline: "nearest" })
        return false
    }


    //HANDLING SHOW PASSWORD BUTTON
    function showPassword() {
        setShowPwdIcon((prevValue) => {
            let input = document.getElementById("register_password_input")
            if (prevValue === eye_slashed) {
                input.type = "text"
                return eye
            }
            input.type = "password"
            return eye_slashed
        })
    }

    //HANDLING SHOW CONFIRM PASSWORD BUTTON
    function showConfirmPassword() {
        setShowConfPwdIcon((prevValue) => {
            let input = document.getElementById("register_confirm_password_input")
            if (prevValue === eye_slashed) {
                input.type = "text"
                return eye
            }
            input.type = "password"
            return eye_slashed
        })
    }

    //EMAIL SENT FUNCTION
    function emailSent() {
        document.getElementById("email_sent_section").style.animation = "register_page_blur .5s ease .4s 1 forwards"
        document.getElementById("email_sent_section").addEventListener("animationstart", () => {
            document.getElementById("email_sent_container").scrollIntoView({ block: "center", inline: "nearest" })
        })
        document.getElementById("email_sent_label").style.animation = "register_page_move_in .6s ease .7s 1 forwards"
        document.getElementById("login_button_email_sent").style.animation = "register_page_move_in .6s ease .9s 1 forwards"
    }

    //GO TO LOGIN FUNCTION
    function goToLogin() {
        document.body.scrollIntoView({ block: "start", inline: "nearest" })
        props.handleCurrentPage("home-page")
        document.body.style.overflow = "hidden"                                            //ACTIVATE SCROLLING
        document.getElementById("header_account_button").disabled = true
        document.getElementById("header_menu_button").disabled = true
        props.showLoginTab()
    }


    //_________________________________RENDERING_COMPONENT_________________________________
    return (
        <div id="div_register_page">
            <form id="register_form" onSubmit={handleSubmit}>
                <h1 id="register_title">Registrati</h1>
                <div id="input_container">
                    <div id="left_column_input_container">
                        <div className="register_input_container">
                            <label className="register_label">Nome</label>
                            <input
                                id="register_fName_input"
                                className="register_input"
                                placeholder="Nome"
                                name="fName"
                                type="text"
                                maxLength={20}
                                value={fName}
                                onChange={(e) => setFName(e.target.value)}
                                onFocus={fNameFocus}
                            />
                            <div id="fName_error_tab" className="fName_error_tab_off">
                                <p>Il campo è obbligatorio</p>
                            </div>
                        </div>
                        <div className="register_input_container">
                            <label className="register_label">Cognome</label>
                            <input
                                id="register_lName_input"
                                className="register_input"
                                placeholder="Cognome"
                                name="lName"
                                type="text"
                                maxLength={20}
                                value={lName}
                                onChange={(e) => setLName(e.target.value)}
                                onFocus={lNameFocus}
                            />
                            <div id="lName_error_tab" className="lName_error_tab_off">
                                <p>Il campo è obbligatorio</p>
                            </div>
                        </div>
                        <div className="register_input_container">
                            <label className="register_label">Data di nascita (gg/mm/aaaa)</label>
                            <input
                                id="register_bday_input"
                                className="register_input"
                                placeholder="Data di Nascita"
                                name="bday"
                                type="date"
                                maxLength={10}
                                value={bday}
                                onChange={e => setBday(e.target.value)}
                                onFocus={bDayFocus}
                            />
                            <div id="bDay_error_tab" className="bDay_error_tab_off">
                                <p>Inserisci una data valida</p>
                            </div>
                        </div>
                        <div className="register_input_container">
                            <label className="register_label">Peso (Kg)</label>
                            <input
                                id="register_weight_input"
                                className="register_input"
                                placeholder="Peso"
                                name="weight"
                                type="number"
                                min={20}
                                max={300}
                                maxLength={10}
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                                onFocus={weightFocus}
                            />
                            <div id="weight_error_tab" className="weight_error_tab_off">
                                <p>Il campo è obbligatorio</p>
                            </div>
                        </div>
                    </div>
                    <div id="right_column_input_container">
                        <div className="register_input_container">
                            <label className="register_label">Altezza (cm)</label>
                            <input
                                id="register_height_input"
                                className="register_input"
                                placeholder="Altezza"
                                name="height"
                                type="number"
                                min={50}
                                max={300}
                                maxLength={10}
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                                onFocus={heightFocus}
                            />
                            <div id="height_error_tab" className="height_error_tab_off">
                                <p>Il campo è obbligatorio</p>
                            </div>
                        </div>
                        <div className="register_input_container">
                            <label className="register_label">E-mail</label>
                            <input
                                id="register_email_input"
                                className="register_input"
                                placeholder="E-mail"
                                name="email"
                                type="text"
                                maxLength={30}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onFocus={emailFocus}
                            />
                            <div id="email_error_tab" className="email_error_tab_off">
                                <p id="email_error_message">Il campo è obbligatorio</p>
                            </div>
                        </div>
                        <div className="register_input_container">
                            <label className="register_label">Crea Password</label>
                            <input
                                id="register_password_input"
                                className="register_input"
                                placeholder="Password"
                                name="password"
                                type="password"
                                maxLength={20}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onFocus={passwordFocus}
                            />
                            <div id="password_error_tab" className="password_error_tab_off">
                                <section id="password_error_message">
                                    <p>Il campo è obbligatorio</p>
                                </section>
                            </div>
                            <img id="register_show_hide_password" src={showPwdIcon} onClick={showPassword}></img>
                        </div>
                        <div className="register_input_container">
                            <label className="register_label">Conferma Password</label>
                            <input
                                id="register_confirm_password_input"
                                className="register_input"
                                placeholder="Confirm Password"
                                name="password"
                                type="password"
                                maxLength={20}
                                value={confPassword}
                                onChange={(e) => setConfPassword(e.target.value)}
                                onFocus={confPasswordFocus}
                            />
                            <div id="confPassword_error_tab" className="confPassword_error_tab_off">
                                <section id="confPassword_error_message">
                                    <p>Le password non corrispondono</p>
                                </section>
                            </div>
                            <img id="register_show_hide_confirm_password" src={showConfPwdIcon} onClick={showConfirmPassword}></img>
                        </div>
                    </div>
                </div>
                <input type="submit" name="submit" id="register_continue_button" value="Avanti" />
            </form>
            <div id="email_sent_section">
                <div id="email_sent_container">
                    <p id="email_sent_label">Ti abbiamo inviato una e-mail, segui le istruzioni per completare la creazione dell'account</p>
                    <button
                        id="login_button_email_sent"
                        onClick={goToLogin}
                    >Login</button>
                </div>
            </div>
        </div>
    )
}