export default {
	"values_number": 7,
	"value_names": [
		"Calorie",
		"Proteine",
		"Grassi Totali",
		"Grassi Saturi",
		"Carboidrati",
		"Zuccheri",
		"Fibra Alimentare"
	],
	"foods": [
		{
			"name": "acciughe",
			"calorie": 96,
			"proteine": 16.8,
			"grassi_totali": 2.6,
			"grassi_saturi": 0.69,
			"carboidrati": 1.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 0
		},
		{
			"name": "acciughe sott'olio",
			"calorie": 206,
			"proteine": 25.9,
			"grassi_totali": 11.3,
			"grassi_saturi": 2.21,
			"carboidrati": 0.2,
			"zuccheri": 0.2,
			"fibra_alimentare": 0
		},
		{
			"name": "acciughe sotto sale",
			"calorie": 128,
			"proteine": 25,
			"grassi_totali": 3.1,
			"grassi_saturi": 0.82,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "aceto",
			"calorie": 4,
			"proteine": 0.4,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "acqua",
			"calorie": 0,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "acqua tonica",
			"calorie": 33,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 8.8,
			"zuccheri": 8.8,
			"fibra_alimentare": 0
		},
		{
			"name": "agar agar secco",
			"calorie": 16,
			"proteine": 1.6,
			"grassi_totali": 1.2,
			"grassi_saturi": 0.24,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 81.1
		},
		{
			"name": "aglio fresco",
			"calorie": 41,
			"proteine": 0.9,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.11,
			"carboidrati": 8.4,
			"zuccheri": 8.4,
			"fibra_alimentare": 2.3
		},
		{
			"name": "aglio in polvere",
			"calorie": 246,
			"proteine": 18.7,
			"grassi_totali": 1.2,
			"grassi_saturi": 0.21,
			"carboidrati": 42.7,
			"zuccheri": 4.2,
			"fibra_alimentare": 9.9
		},
		{
			"name": "agnello",
			"calorie": 162,
			"proteine": 20.8,
			"grassi_totali": 8.8,
			"grassi_saturi": 4.15,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "agretti",
			"calorie": 17,
			"proteine": 1.8,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.01,
			"carboidrati": 2.2,
			"zuccheri": 2.2,
			"fibra_alimentare": 2.3
		},
		{
			"name": "ala di tacchino",
			"calorie": 193,
			"proteine": 22.3,
			"grassi_totali": 11.5,
			"grassi_saturi": 3.48,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "albicocche",
			"calorie": 28,
			"proteine": 0.4,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 6.8,
			"zuccheri": 6.8,
			"fibra_alimentare": 1.5
		},
		{
			"name": "albume d'uovo di gallina",
			"calorie": 47,
			"proteine": 10.9,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 0
		},
		{
			"name": "alcool puro",
			"calorie": 660,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "ali di pollo",
			"calorie": 193,
			"proteine": 20.3,
			"grassi_totali": 12.4,
			"grassi_saturi": 4.09,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "alici",
			"calorie": 96,
			"proteine": 16.8,
			"grassi_totali": 2.6,
			"grassi_saturi": 0.69,
			"carboidrati": 1.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 0
		},
		{
			"name": "alici sott'olio",
			"calorie": 206,
			"proteine": 25.9,
			"grassi_totali": 11.3,
			"grassi_saturi": 2.21,
			"carboidrati": 0.2,
			"zuccheri": 0.2,
			"fibra_alimentare": 0
		},
		{
			"name": "alici sotto sale",
			"calorie": 128,
			"proteine": 25,
			"grassi_totali": 3.1,
			"grassi_saturi": 0.82,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "alloro secco",
			"calorie": 313,
			"proteine": 7.6,
			"grassi_totali": 8.4,
			"grassi_saturi": 2.29,
			"carboidrati": 48.6,
			"zuccheri": 48.6,
			"fibra_alimentare": 26.3
		},
		{
			"name": "amarene",
			"calorie": 41,
			"proteine": 0.8,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 10.2,
			"zuccheri": 10.2,
			"fibra_alimentare": 1.1
		},
		{
			"name": "amari (> 35 %vol)",
			"calorie": 232,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0.5,
			"zuccheri": 0.5,
			"fibra_alimentare": 0
		},
		{
			"name": "amari (tra 23 e 30 %vol)",
			"calorie": 261,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 32.8,
			"zuccheri": 32.8,
			"fibra_alimentare": 0
		},
		{
			"name": "amido",
			"calorie": 379,
			"proteine": 0.4,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.04,
			"carboidrati": 100.1,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "ananas",
			"calorie": 40,
			"proteine": 0.5,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 10,
			"zuccheri": 10,
			"fibra_alimentare": 1
		},
		{
			"name": "ananas sciroppata",
			"calorie": 77,
			"proteine": 0.3,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 20.2,
			"zuccheri": 20.2,
			"fibra_alimentare": 0.9
		},
		{
			"name": "anatra",
			"calorie": 159,
			"proteine": 21.4,
			"grassi_totali": 8.2,
			"grassi_saturi": 2.29,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "anguilla di fiume",
			"calorie": 261,
			"proteine": 11.8,
			"grassi_totali": 23.7,
			"grassi_saturi": 4.79,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "anguilla di mare",
			"calorie": 237,
			"proteine": 14.6,
			"grassi_totali": 19.6,
			"grassi_saturi": 3.96,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 0
		},
		{
			"name": "anguilla marinata",
			"calorie": 259,
			"proteine": 14.8,
			"grassi_totali": 21.9,
			"grassi_saturi": 4.43,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "anguria",
			"calorie": 33,
			"proteine": 0.8,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 7.4,
			"zuccheri": 7.4,
			"fibra_alimentare": 0.9
		},
		{
			"name": "aperitivi (fino a 22 %vol)",
			"calorie": 186,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 17,
			"zuccheri": 17,
			"fibra_alimentare": 0
		},
		{
			"name": "arachidi crude",
			"calorie": 571,
			"proteine": 26,
			"grassi_totali": 47.2,
			"grassi_saturi": 6.73,
			"carboidrati": 11.2,
			"zuccheri": 4.5,
			"fibra_alimentare": 7.3
		},
		{
			"name": "arachidi salate",
			"calorie": 602,
			"proteine": 24.5,
			"grassi_totali": 53,
			"grassi_saturi": 7.56,
			"carboidrati": 7.1,
			"zuccheri": 3.8,
			"fibra_alimentare": 6.9
		},
		{
			"name": "arachidi tostate",
			"calorie": 597,
			"proteine": 29,
			"grassi_totali": 50,
			"grassi_saturi": 7.13,
			"carboidrati": 8.5,
			"zuccheri": 3.1,
			"fibra_alimentare": 10.9
		},
		{
			"name": "aragosta",
			"calorie": 86,
			"proteine": 16.2,
			"grassi_totali": 1.9,
			"grassi_saturi": 0.3,
			"carboidrati": 1,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "arance",
			"calorie": 34,
			"proteine": 0.7,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.03,
			"carboidrati": 7.8,
			"zuccheri": 7.8,
			"fibra_alimentare": 1.6
		},
		{
			"name": "aranciata in lattina",
			"calorie": 38,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 10,
			"zuccheri": 10,
			"fibra_alimentare": 0
		},
		{
			"name": "aringa",
			"calorie": 190,
			"proteine": 17.8,
			"grassi_totali": 13.2,
			"grassi_saturi": 2.63,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "aringa affumicata",
			"calorie": 194,
			"proteine": 19.9,
			"grassi_totali": 12.7,
			"grassi_saturi": 2.53,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "aringa marinata",
			"calorie": 199,
			"proteine": 18.3,
			"grassi_totali": 14,
			"grassi_saturi": 2.79,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "aringa salata",
			"calorie": 218,
			"proteine": 19.8,
			"grassi_totali": 15.4,
			"grassi_saturi": 3.07,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "asiago",
			"calorie": 356,
			"proteine": 31.4,
			"grassi_totali": 25.6,
			"grassi_saturi": 15.89,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "asparagi di bosco",
			"calorie": 35,
			"proteine": 4.6,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 4,
			"zuccheri": 4,
			"fibra_alimentare": 2.6
		},
		{
			"name": "asparagi di campo",
			"calorie": 29,
			"proteine": 3.6,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 3.3,
			"zuccheri": 3.3,
			"fibra_alimentare": 2.1
		},
		{
			"name": "asparagi di serra",
			"calorie": 24,
			"proteine": 3,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 3,
			"zuccheri": 3,
			"fibra_alimentare": 2.1
		},
		{
			"name": "asparagi in scatola",
			"calorie": 19,
			"proteine": 2.1,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.15,
			"carboidrati": 0.9,
			"zuccheri": 0.9,
			"fibra_alimentare": 1.6
		},
		{
			"name": "astice",
			"calorie": 90,
			"proteine": 18.8,
			"grassi_totali": 0.9,
			"grassi_saturi": 0.06,
			"carboidrati": 0.5,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "avena",
			"calorie": 389,
			"proteine": 16.9,
			"grassi_totali": 6.9,
			"grassi_saturi": 1.22,
			"carboidrati": 55.7,
			"zuccheri": 2.8,
			"fibra_alimentare": 10.6
		},
		{
			"name": "avocado",
			"calorie": 231,
			"proteine": 4.4,
			"grassi_totali": 23,
			"grassi_saturi": 2.7,
			"carboidrati": 1.8,
			"zuccheri": 1.8,
			"fibra_alimentare": 3.3
		},
		{
			"name": "babaco",
			"calorie": 21,
			"proteine": 0.7,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.03,
			"carboidrati": 4.5,
			"zuccheri": 4.5,
			"fibra_alimentare": 1.1
		},
		{
			"name": "babà al rhum",
			"calorie": 252,
			"proteine": 5.9,
			"grassi_totali": 5.7,
			"grassi_saturi": 2.81,
			"carboidrati": 41.4,
			"zuccheri": 18.5,
			"fibra_alimentare": 0.9
		},
		{
			"name": "baccalà ammollato",
			"calorie": 95,
			"proteine": 21.6,
			"grassi_totali": 1,
			"grassi_saturi": 0.19,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "baccalà secco",
			"calorie": 122,
			"proteine": 29,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.14,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "bacon",
			"calorie": 276,
			"proteine": 15.8,
			"grassi_totali": 23.6,
			"grassi_saturi": 8,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "banana",
			"calorie": 66,
			"proteine": 1.2,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.1,
			"carboidrati": 15.5,
			"zuccheri": 12.8,
			"fibra_alimentare": 1.8
		},
		{
			"name": "barbabietole rosse",
			"calorie": 20,
			"proteine": 1.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 4,
			"zuccheri": 4,
			"fibra_alimentare": 2.6
		},
		{
			"name": "basilico fresco",
			"calorie": 40,
			"proteine": 3.1,
			"grassi_totali": 0.8,
			"grassi_saturi": 0.05,
			"carboidrati": 5.1,
			"zuccheri": 5.1,
			"fibra_alimentare": 5.2
		},
		{
			"name": "basilico secco macinato",
			"calorie": 251,
			"proteine": 14.4,
			"grassi_totali": 4,
			"grassi_saturi": 0.27,
			"carboidrati": 43.2,
			"zuccheri": 43.2,
			"fibra_alimentare": 17.8
		},
		{
			"name": "bevande gassate dietetiche",
			"calorie": 17,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 4.3,
			"zuccheri": 4.3,
			"fibra_alimentare": 0
		},
		{
			"name": "bieta",
			"calorie": 17,
			"proteine": 1.3,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 2.8,
			"zuccheri": 2.8,
			"fibra_alimentare": 1.2
		},
		{
			"name": "bieta in scatola",
			"calorie": 31,
			"proteine": 0.9,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 5.5,
			"zuccheri": 5.5,
			"fibra_alimentare": 1.7
		},
		{
			"name": "bignè",
			"calorie": 318,
			"proteine": 4.6,
			"grassi_totali": 15.4,
			"grassi_saturi": 7.37,
			"carboidrati": 43,
			"zuccheri": 30,
			"fibra_alimentare": 0.5
		},
		{
			"name": "birra chiara",
			"calorie": 34,
			"proteine": 0.2,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 3.5,
			"zuccheri": 3.5,
			"fibra_alimentare": 0
		},
		{
			"name": "birra scura",
			"calorie": 30,
			"proteine": 0.3,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 3,
			"zuccheri": 3,
			"fibra_alimentare": 0
		},
		{
			"name": "biscotti 'petit beurre'",
			"calorie": 415,
			"proteine": 6,
			"grassi_totali": 8.2,
			"grassi_saturi": 4.67,
			"carboidrati": 84.7,
			"zuccheri": 30.1,
			"fibra_alimentare": 0.4
		},
		{
			"name": "biscotti al cioccolato",
			"calorie": 524,
			"proteine": 5.7,
			"grassi_totali": 27.6,
			"grassi_saturi": 5.55,
			"carboidrati": 67.4,
			"zuccheri": 43.4,
			"fibra_alimentare": 2.9
		},
		{
			"name": "biscotti al latte",
			"calorie": 417,
			"proteine": 13.8,
			"grassi_totali": 8.5,
			"grassi_saturi": 1.7,
			"carboidrati": 76.1,
			"zuccheri": 35.7,
			"fibra_alimentare": 1.2
		},
		{
			"name": "biscotti frollini",
			"calorie": 493,
			"proteine": 7.4,
			"grassi_totali": 21,
			"grassi_saturi": 10.45,
			"carboidrati": 68.5,
			"zuccheri": 20,
			"fibra_alimentare": 1.5
		},
		{
			"name": "biscotti integrali",
			"calorie": 448,
			"proteine": 8.5,
			"grassi_totali": 19.5,
			"grassi_saturi": 5.96,
			"carboidrati": 59.5,
			"zuccheri": 17,
			"fibra_alimentare": 8
		},
		{
			"name": "biscotti per colazione",
			"calorie": 418,
			"proteine": 6.6,
			"grassi_totali": 7.9,
			"grassi_saturi": 3.06,
			"carboidrati": 85.4,
			"zuccheri": 18.5,
			"fibra_alimentare": 5.7
		},
		{
			"name": "biscotti per l'infanzia",
			"calorie": 417,
			"proteine": 13.8,
			"grassi_totali": 8.5,
			"grassi_saturi": 2.41,
			"carboidrati": 76.1,
			"zuccheri": 35.7,
			"fibra_alimentare": 0.2
		},
		{
			"name": "biscotti savoiardi",
			"calorie": 392,
			"proteine": 11.9,
			"grassi_totali": 9.2,
			"grassi_saturi": 2.83,
			"carboidrati": 69.8,
			"zuccheri": 39.3,
			"fibra_alimentare": 1.8
		},
		{
			"name": "biscotti wafers",
			"calorie": 454,
			"proteine": 7.1,
			"grassi_totali": 15,
			"grassi_saturi": 3.86,
			"carboidrati": 77.6,
			"zuccheri": 26.7,
			"fibra_alimentare": 1.6
		},
		{
			"name": "bistecca di soia",
			"calorie": 329,
			"proteine": 47,
			"grassi_totali": 1.2,
			"grassi_saturi": 0.14,
			"carboidrati": 20.9,
			"zuccheri": 9.9,
			"fibra_alimentare": 17.5
		},
		{
			"name": "bistecca di suino",
			"calorie": 100,
			"proteine": 18.3,
			"grassi_totali": 3,
			"grassi_saturi": 1.37,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "bovino lessato in gelatina",
			"calorie": 87,
			"proteine": 13.9,
			"grassi_totali": 3.5,
			"grassi_saturi": 1.48,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "bra",
			"calorie": 328,
			"proteine": 30.7,
			"grassi_totali": 22.8,
			"grassi_saturi": 14.15,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "brandy",
			"calorie": 224,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "bresaola",
			"calorie": 175,
			"proteine": 34,
			"grassi_totali": 4.3,
			"grassi_saturi": 1.82,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "brie",
			"calorie": 319,
			"proteine": 19.3,
			"grassi_totali": 26.9,
			"grassi_saturi": 16.92,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "brioches",
			"calorie": 413,
			"proteine": 7.2,
			"grassi_totali": 18.3,
			"grassi_saturi": 10.2,
			"carboidrati": 58.4,
			"zuccheri": 10.6,
			"fibra_alimentare": 2.5
		},
		{
			"name": "brioches con crema",
			"calorie": 391,
			"proteine": 6.9,
			"grassi_totali": 17.1,
			"grassi_saturi": 9.44,
			"carboidrati": 55.6,
			"zuccheri": 12.1,
			"fibra_alimentare": 2.3
		},
		{
			"name": "brioches con marmellata",
			"calorie": 394,
			"proteine": 6.5,
			"grassi_totali": 16.5,
			"grassi_saturi": 9.18,
			"carboidrati": 58.4,
			"zuccheri": 15.4,
			"fibra_alimentare": 2.5
		},
		{
			"name": "broccoletti di rape",
			"calorie": 22,
			"proteine": 2.9,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.07,
			"carboidrati": 2,
			"zuccheri": 2,
			"fibra_alimentare": 2.9
		},
		{
			"name": "broccoli",
			"calorie": 27,
			"proteine": 3,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.06,
			"carboidrati": 3.1,
			"zuccheri": 3.1,
			"fibra_alimentare": 3.1
		},
		{
			"name": "brodo di carne",
			"calorie": 5,
			"proteine": 0.7,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 0.1,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "brodo di dado",
			"calorie": 3,
			"proteine": 0.3,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.2,
			"carboidrati": 0.1,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "brodo di gallina",
			"calorie": 9,
			"proteine": 0.6,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.11,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "brodo vegetale",
			"calorie": 5,
			"proteine": 0.3,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.09,
			"carboidrati": 0.3,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "bucce di arancia",
			"calorie": 97,
			"proteine": 1.5,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.02,
			"carboidrati": 14.4,
			"zuccheri": 14.4,
			"fibra_alimentare": 10.6
		},
		{
			"name": "buccia di arancia candita",
			"calorie": 313,
			"proteine": 0.2,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.02,
			"carboidrati": 82.7,
			"zuccheri": 82.7,
			"fibra_alimentare": 1.1
		},
		{
			"name": "budino al cioccolato",
			"calorie": 133,
			"proteine": 2.7,
			"grassi_totali": 4,
			"grassi_saturi": 0.71,
			"carboidrati": 21.8,
			"zuccheri": 12.6,
			"fibra_alimentare": 1
		},
		{
			"name": "budino alla vaniglia",
			"calorie": 130,
			"proteine": 2.3,
			"grassi_totali": 3.6,
			"grassi_saturi": 0.57,
			"carboidrati": 21.8,
			"zuccheri": 12.6,
			"fibra_alimentare": 0.1
		},
		{
			"name": "burrata",
			"calorie": 396,
			"proteine": 15.2,
			"grassi_totali": 34.9,
			"grassi_saturi": 20.82,
			"carboidrati": 5.7,
			"zuccheri": 5.7,
			"fibra_alimentare": 0
		},
		{
			"name": "burrini",
			"calorie": 517,
			"proteine": 22.1,
			"grassi_totali": 47,
			"grassi_saturi": 27.57,
			"carboidrati": 1.6,
			"zuccheri": 1.6,
			"fibra_alimentare": 0
		},
		{
			"name": "burro",
			"calorie": 758,
			"proteine": 0.8,
			"grassi_totali": 83.4,
			"grassi_saturi": 48.78,
			"carboidrati": 1.1,
			"zuccheri": 1.1,
			"fibra_alimentare": 0
		},
		{
			"name": "burro di arachidi",
			"calorie": 623,
			"proteine": 22.6,
			"grassi_totali": 53.7,
			"grassi_saturi": 10.62,
			"carboidrati": 13.1,
			"zuccheri": 6.7,
			"fibra_alimentare": 6.8
		},
		{
			"name": "cacao amaro",
			"calorie": 355,
			"proteine": 20.4,
			"grassi_totali": 25.6,
			"grassi_saturi": 15.09,
			"carboidrati": 11.5,
			"zuccheri": 0,
			"fibra_alimentare": 28.9
		},
		{
			"name": "cacao dolce",
			"calorie": 349,
			"proteine": 4.5,
			"grassi_totali": 8.6,
			"grassi_saturi": 5.07,
			"carboidrati": 67.7,
			"zuccheri": 62.2,
			"fibra_alimentare": 7.3
		},
		{
			"name": "caciocavallo",
			"calorie": 439,
			"proteine": 37.7,
			"grassi_totali": 31.1,
			"grassi_saturi": 19.95,
			"carboidrati": 2.1,
			"zuccheri": 2.1,
			"fibra_alimentare": 0
		},
		{
			"name": "caciocavallo affumicato",
			"calorie": 439,
			"proteine": 37.7,
			"grassi_totali": 31.1,
			"grassi_saturi": 19.95,
			"carboidrati": 2.1,
			"zuccheri": 2.1,
			"fibra_alimentare": 0
		},
		{
			"name": "caciotta affumicata",
			"calorie": 364,
			"proteine": 27.7,
			"grassi_totali": 27.3,
			"grassi_saturi": 17.34,
			"carboidrati": 2.1,
			"zuccheri": 2.1,
			"fibra_alimentare": 0
		},
		{
			"name": "caciotta romana di pecora",
			"calorie": 364,
			"proteine": 27.7,
			"grassi_totali": 27.3,
			"grassi_saturi": 17.34,
			"carboidrati": 2.1,
			"zuccheri": 2.1,
			"fibra_alimentare": 0
		},
		{
			"name": "caciotta toscana",
			"calorie": 373,
			"proteine": 24.6,
			"grassi_totali": 29.6,
			"grassi_saturi": 18.37,
			"carboidrati": 2.1,
			"zuccheri": 2.1,
			"fibra_alimentare": 0
		},
		{
			"name": "caciottina fresca",
			"calorie": 261,
			"proteine": 17.3,
			"grassi_totali": 21.3,
			"grassi_saturi": 13.22,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "caffe' d'orzo",
			"calorie": 5,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0.01,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "caffe' d'orzo in polvere",
			"calorie": 372,
			"proteine": 5.5,
			"grassi_totali": 3.4,
			"grassi_saturi": 0.63,
			"carboidrati": 80.9,
			"zuccheri": 0,
			"fibra_alimentare": 0.1
		},
		{
			"name": "caffè",
			"calorie": 4,
			"proteine": 0.4,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "caffè decaffeinato",
			"calorie": 4,
			"proteine": 0.4,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "caffè solubile",
			"calorie": 224,
			"proteine": 13.9,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.1,
			"carboidrati": 42.6,
			"zuccheri": 25.2,
			"fibra_alimentare": 0
		},
		{
			"name": "calamaro",
			"calorie": 68,
			"proteine": 12.6,
			"grassi_totali": 1.7,
			"grassi_saturi": 0.3,
			"carboidrati": 0.6,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "calamaro surgelato",
			"calorie": 70,
			"proteine": 13.5,
			"grassi_totali": 1.4,
			"grassi_saturi": 0.19,
			"carboidrati": 1,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "camembert",
			"calorie": 297,
			"proteine": 20.9,
			"grassi_totali": 23.7,
			"grassi_saturi": 14.91,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "canditi",
			"calorie": 283,
			"proteine": 0.5,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 75,
			"zuccheri": 75,
			"fibra_alimentare": 4.5
		},
		{
			"name": "cannella",
			"calorie": 252,
			"proteine": 3.9,
			"grassi_totali": 3.2,
			"grassi_saturi": 0.65,
			"carboidrati": 55.5,
			"zuccheri": 55.5,
			"fibra_alimentare": 24.4
		},
		{
			"name": "cannoli alla crema",
			"calorie": 369,
			"proteine": 6.2,
			"grassi_totali": 20.7,
			"grassi_saturi": 9.9,
			"carboidrati": 42.2,
			"zuccheri": 10.9,
			"fibra_alimentare": 1.1
		},
		{
			"name": "capocollo",
			"calorie": 450,
			"proteine": 20.8,
			"grassi_totali": 40.2,
			"grassi_saturi": 18.39,
			"carboidrati": 1.4,
			"zuccheri": 1.4,
			"fibra_alimentare": 0
		},
		{
			"name": "capperi sott'aceto",
			"calorie": 20,
			"proteine": 2.6,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 2.1,
			"zuccheri": 2.1,
			"fibra_alimentare": 1.5
		},
		{
			"name": "capretto",
			"calorie": 122,
			"proteine": 19.2,
			"grassi_totali": 5,
			"grassi_saturi": 1.54,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "caramelle alla frutta",
			"calorie": 327,
			"proteine": 2.8,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 84.2,
			"zuccheri": 59.3,
			"fibra_alimentare": 0
		},
		{
			"name": "caramelle alla menta",
			"calorie": 393,
			"proteine": 0.5,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.18,
			"carboidrati": 102.7,
			"zuccheri": 102.7,
			"fibra_alimentare": 0
		},
		{
			"name": "caramelle mou",
			"calorie": 430,
			"proteine": 2.1,
			"grassi_totali": 17.2,
			"grassi_saturi": 10.71,
			"carboidrati": 71.1,
			"zuccheri": 70,
			"fibra_alimentare": 0
		},
		{
			"name": "caramelle varie",
			"calorie": 343,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 91.6,
			"zuccheri": 91.6,
			"fibra_alimentare": 0
		},
		{
			"name": "carciofi",
			"calorie": 22,
			"proteine": 2.7,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 2.5,
			"zuccheri": 1.9,
			"fibra_alimentare": 5.5
		},
		{
			"name": "carciofi surgelati",
			"calorie": 38,
			"proteine": 2.6,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.1,
			"carboidrati": 3.9,
			"zuccheri": 3,
			"fibra_alimentare": 3.9
		},
		{
			"name": "carciofini sott'aceto",
			"calorie": 18,
			"proteine": 2.9,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 2.7,
			"zuccheri": 1.3,
			"fibra_alimentare": 5.5
		},
		{
			"name": "carciofini sott'olio ",
			"calorie": 68,
			"proteine": 1.8,
			"grassi_totali": 5.7,
			"grassi_saturi": 0.67,
			"carboidrati": 2.6,
			"zuccheri": 1.1,
			"fibra_alimentare": 3.3
		},
		{
			"name": "cardi",
			"calorie": 10,
			"proteine": 0.6,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 1.7,
			"zuccheri": 1.5,
			"fibra_alimentare": 1.5
		},
		{
			"name": "carne di cavallo",
			"calorie": 113,
			"proteine": 21.7,
			"grassi_totali": 2.7,
			"grassi_saturi": 0.97,
			"carboidrati": 0.5,
			"zuccheri": 0.5,
			"fibra_alimentare": 0
		},
		{
			"name": "carne grassa di agnello",
			"calorie": 279,
			"proteine": 18,
			"grassi_totali": 23,
			"grassi_saturi": 10.86,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carne grassa di castrato",
			"calorie": 332,
			"proteine": 16.5,
			"grassi_totali": 29.5,
			"grassi_saturi": 13.93,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carne grassa di coniglio",
			"calorie": 203,
			"proteine": 18.1,
			"grassi_totali": 14.4,
			"grassi_saturi": 5.55,
			"carboidrati": 0.4,
			"zuccheri": 0.4,
			"fibra_alimentare": 0
		},
		{
			"name": "carne grassa di vitellone",
			"calorie": 204,
			"proteine": 18.1,
			"grassi_totali": 14.6,
			"grassi_saturi": 6.18,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carne magra di agnello",
			"calorie": 121,
			"proteine": 20,
			"grassi_totali": 4.6,
			"grassi_saturi": 2.17,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carne magra di castrato",
			"calorie": 122,
			"proteine": 17,
			"grassi_totali": 6,
			"grassi_saturi": 2.83,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carne magra di coniglio",
			"calorie": 102,
			"proteine": 23.7,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.23,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "carne magra di suino",
			"calorie": 141,
			"proteine": 19.9,
			"grassi_totali": 6.8,
			"grassi_saturi": 3.11,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carne magra di vitello",
			"calorie": 92,
			"proteine": 20.7,
			"grassi_totali": 1,
			"grassi_saturi": 0.36,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "carne magra di vitellone",
			"calorie": 113,
			"proteine": 21.3,
			"grassi_totali": 3.1,
			"grassi_saturi": 1.31,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carne semigrassa di agnello",
			"calorie": 211,
			"proteine": 19,
			"grassi_totali": 15,
			"grassi_saturi": 7.08,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carne semigrassa di castrato",
			"calorie": 227,
			"proteine": 16.8,
			"grassi_totali": 17.8,
			"grassi_saturi": 7.93,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carne semigrassa di coniglio",
			"calorie": 138,
			"proteine": 22.1,
			"grassi_totali": 5.3,
			"grassi_saturi": 2.04,
			"carboidrati": 0.5,
			"zuccheri": 0.5,
			"fibra_alimentare": 0
		},
		{
			"name": "carne semigrassa di suino",
			"calorie": 268,
			"proteine": 17.2,
			"grassi_totali": 22.1,
			"grassi_saturi": 10.11,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carne semigrassa di vitello",
			"calorie": 144,
			"proteine": 20.3,
			"grassi_totali": 7,
			"grassi_saturi": 2.52,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carne semigrassa di vitellone",
			"calorie": 160,
			"proteine": 19.1,
			"grassi_totali": 9.3,
			"grassi_saturi": 3.94,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "carote",
			"calorie": 33,
			"proteine": 1.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 7.6,
			"zuccheri": 7.6,
			"fibra_alimentare": 3.1
		},
		{
			"name": "carpa",
			"calorie": 140,
			"proteine": 18.9,
			"grassi_totali": 7.1,
			"grassi_saturi": 1.37,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "castagne",
			"calorie": 189,
			"proteine": 3.5,
			"grassi_totali": 1.8,
			"grassi_saturi": 0.31,
			"carboidrati": 42.4,
			"zuccheri": 8.1,
			"fibra_alimentare": 9
		},
		{
			"name": "castagne secche",
			"calorie": 349,
			"proteine": 4.7,
			"grassi_totali": 3,
			"grassi_saturi": 0.52,
			"carboidrati": 80.9,
			"zuccheri": 15.4,
			"fibra_alimentare": 13.1
		},
		{
			"name": "catalogna",
			"calorie": 24,
			"proteine": 1.8,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.12,
			"carboidrati": 3.2,
			"zuccheri": 3,
			"fibra_alimentare": 3.1
		},
		{
			"name": "caviale",
			"calorie": 252,
			"proteine": 18.9,
			"grassi_totali": 18.8,
			"grassi_saturi": 4.26,
			"carboidrati": 1.9,
			"zuccheri": 1.9,
			"fibra_alimentare": 0
		},
		{
			"name": "cavolfiore",
			"calorie": 25,
			"proteine": 3.2,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.03,
			"carboidrati": 2.7,
			"zuccheri": 2.4,
			"fibra_alimentare": 2.4
		},
		{
			"name": "cavoli di bruxelles",
			"calorie": 37,
			"proteine": 4.2,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.1,
			"carboidrati": 4.3,
			"zuccheri": 3.3,
			"fibra_alimentare": 5.2
		},
		{
			"name": "cavolo broccolo verde ramoso",
			"calorie": 24,
			"proteine": 3.4,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.05,
			"carboidrati": 2,
			"zuccheri": 2,
			"fibra_alimentare": 3.1
		},
		{
			"name": "cavolo cappuccio rosso",
			"calorie": 20,
			"proteine": 1.9,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.03,
			"carboidrati": 2.7,
			"zuccheri": 2.7,
			"fibra_alimentare": 1
		},
		{
			"name": "cavolo cappuccio verde",
			"calorie": 19,
			"proteine": 2.1,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 2.5,
			"zuccheri": 2.5,
			"fibra_alimentare": 2.9
		},
		{
			"name": "ceci in scatola",
			"calorie": 56,
			"proteine": 4.3,
			"grassi_totali": 1.4,
			"grassi_saturi": 0.18,
			"carboidrati": 7.2,
			"zuccheri": 1.4,
			"fibra_alimentare": 8.1
		},
		{
			"name": "ceci secchi",
			"calorie": 334,
			"proteine": 21.8,
			"grassi_totali": 4.9,
			"grassi_saturi": 0.63,
			"carboidrati": 54.3,
			"zuccheri": 3.7,
			"fibra_alimentare": 13.8
		},
		{
			"name": "cedro candito",
			"calorie": 187,
			"proteine": 0.1,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.02,
			"carboidrati": 49.4,
			"zuccheri": 49.4,
			"fibra_alimentare": 3.3
		},
		{
			"name": "cefalo muggine",
			"calorie": 127,
			"proteine": 15.8,
			"grassi_totali": 6.8,
			"grassi_saturi": 1.78,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 0
		},
		{
			"name": "cereali aggregati",
			"calorie": 342,
			"proteine": 10.7,
			"grassi_totali": 2,
			"grassi_saturi": 0.46,
			"carboidrati": 74.9,
			"zuccheri": 6.4,
			"fibra_alimentare": 11.6
		},
		{
			"name": "cernia",
			"calorie": 80,
			"proteine": 17.9,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.16,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "cernia di fondo",
			"calorie": 125,
			"proteine": 15.6,
			"grassi_totali": 6.7,
			"grassi_saturi": 1.53,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "cernia surgelata",
			"calorie": 88,
			"proteine": 17,
			"grassi_totali": 2,
			"grassi_saturi": 0.46,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "certosino",
			"calorie": 268,
			"proteine": 17.5,
			"grassi_totali": 22,
			"grassi_saturi": 13.65,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "cervello di bovino",
			"calorie": 157,
			"proteine": 9.8,
			"grassi_totali": 12.7,
			"grassi_saturi": 2.95,
			"carboidrati": 0.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 0
		},
		{
			"name": "cetrioli",
			"calorie": 14,
			"proteine": 0.7,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.17,
			"carboidrati": 1.8,
			"zuccheri": 1.8,
			"fibra_alimentare": 0.6
		},
		{
			"name": "cetrioli in salamoia",
			"calorie": 14,
			"proteine": 0.9,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.03,
			"carboidrati": 2.6,
			"zuccheri": 2.4,
			"fibra_alimentare": 1.2
		},
		{
			"name": "cetriolini sott'aceto ",
			"calorie": 14,
			"proteine": 0.9,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.03,
			"carboidrati": 2.6,
			"zuccheri": 2.4,
			"fibra_alimentare": 1.2
		},
		{
			"name": "champagne",
			"calorie": 76,
			"proteine": 0.3,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 1.4,
			"zuccheri": 1.4,
			"fibra_alimentare": 0
		},
		{
			"name": "cheddar",
			"calorie": 381,
			"proteine": 25,
			"grassi_totali": 31,
			"grassi_saturi": 18.52,
			"carboidrati": 0.5,
			"zuccheri": 0.5,
			"fibra_alimentare": 0
		},
		{
			"name": "cherry brandy",
			"calorie": 255,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 32.6,
			"zuccheri": 32.6,
			"fibra_alimentare": 0
		},
		{
			"name": "chicchi di caffè",
			"calorie": 190,
			"proteine": 11.2,
			"grassi_totali": 13.1,
			"grassi_saturi": 5.16,
			"carboidrati": 6.7,
			"zuccheri": 6.7,
			"fibra_alimentare": 49.5
		},
		{
			"name": "ciccioli",
			"calorie": 523,
			"proteine": 40.8,
			"grassi_totali": 40,
			"grassi_saturi": 16.14,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "cicoria",
			"calorie": 10,
			"proteine": 1.4,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 3.6
		},
		{
			"name": "cicoria witloof",
			"calorie": 17,
			"proteine": 0.7,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.07,
			"carboidrati": 3.2,
			"zuccheri": 3.2,
			"fibra_alimentare": 0.6
		},
		{
			"name": "ciliege",
			"calorie": 38,
			"proteine": 0.8,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 9,
			"zuccheri": 9,
			"fibra_alimentare": 1.3
		},
		{
			"name": "cioccolato al latte",
			"calorie": 565,
			"proteine": 8.9,
			"grassi_totali": 37.6,
			"grassi_saturi": 21.93,
			"carboidrati": 50.8,
			"zuccheri": 50.8,
			"fibra_alimentare": 0.8
		},
		{
			"name": "cioccolato bianco",
			"calorie": 529,
			"proteine": 8,
			"grassi_totali": 30.9,
			"grassi_saturi": 18.02,
			"carboidrati": 58.3,
			"zuccheri": 58.3,
			"fibra_alimentare": 0.8
		},
		{
			"name": "cioccolato con nocciole",
			"calorie": 571,
			"proteine": 9.3,
			"grassi_totali": 40.1,
			"grassi_saturi": 20.15,
			"carboidrati": 45.9,
			"zuccheri": 45.9,
			"fibra_alimentare": 1.4
		},
		{
			"name": "cioccolato fondente",
			"calorie": 542,
			"proteine": 5.8,
			"grassi_totali": 34,
			"grassi_saturi": 20.25,
			"carboidrati": 56.7,
			"zuccheri": 56.7,
			"fibra_alimentare": 1.4
		},
		{
			"name": "cioccolato gianduia",
			"calorie": 509,
			"proteine": 13.1,
			"grassi_totali": 28.8,
			"grassi_saturi": 17.15,
			"carboidrati": 52.7,
			"zuccheri": 52.7,
			"fibra_alimentare": 2.5
		},
		{
			"name": "cipolle",
			"calorie": 26,
			"proteine": 1,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 5.7,
			"zuccheri": 5.7,
			"fibra_alimentare": 1.1
		},
		{
			"name": "cipolle secche",
			"calorie": 313,
			"proteine": 10.2,
			"grassi_totali": 1.7,
			"grassi_saturi": 0.3,
			"carboidrati": 68.6,
			"zuccheri": 48.7,
			"fibra_alimentare": 13
		},
		{
			"name": "cipolline",
			"calorie": 38,
			"proteine": 1.3,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 8.5,
			"zuccheri": 8.5,
			"fibra_alimentare": 1.7
		},
		{
			"name": "cipolline sott'aceto",
			"calorie": 24,
			"proteine": 0.9,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.03,
			"carboidrati": 4.9,
			"zuccheri": 3.5,
			"fibra_alimentare": 1.3
		},
		{
			"name": "clementine",
			"calorie": 53,
			"proteine": 0.8,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.02,
			"carboidrati": 12.8,
			"zuccheri": 12.8,
			"fibra_alimentare": 2.2
		},
		{
			"name": "coca cola",
			"calorie": 39,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 10.5,
			"zuccheri": 10.5,
			"fibra_alimentare": 0
		},
		{
			"name": "cocomero",
			"calorie": 15,
			"proteine": 0.4,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 3.7,
			"zuccheri": 3.7,
			"fibra_alimentare": 0.2
		},
		{
			"name": "cognac",
			"calorie": 214,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0.9,
			"zuccheri": 0.9,
			"fibra_alimentare": 0
		},
		{
			"name": "concentrato di pomodoro",
			"calorie": 50,
			"proteine": 0.9,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.06,
			"carboidrati": 11.4,
			"zuccheri": 11.4,
			"fibra_alimentare": 2.3
		},
		{
			"name": "confetti",
			"calorie": 441,
			"proteine": 5.2,
			"grassi_totali": 16.7,
			"grassi_saturi": 1.39,
			"carboidrati": 71.9,
			"zuccheri": 71.9,
			"fibra_alimentare": 4.6
		},
		{
			"name": "cono per gelato",
			"calorie": 366,
			"proteine": 10.1,
			"grassi_totali": 3.3,
			"grassi_saturi": 0.49,
			"carboidrati": 78.8,
			"zuccheri": 1.1,
			"fibra_alimentare": 3
		},
		{
			"name": "coppa",
			"calorie": 494,
			"proteine": 17,
			"grassi_totali": 47.3,
			"grassi_saturi": 21.64,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "coppa di parma",
			"calorie": 398,
			"proteine": 23.6,
			"grassi_totali": 33.5,
			"grassi_saturi": 10.98,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "coratella di agnello",
			"calorie": 110,
			"proteine": 15.7,
			"grassi_totali": 4.7,
			"grassi_saturi": 1.66,
			"carboidrati": 1.2,
			"zuccheri": 1.2,
			"fibra_alimentare": 0
		},
		{
			"name": "coratella di vitello",
			"calorie": 103,
			"proteine": 17.1,
			"grassi_totali": 3.2,
			"grassi_saturi": 1.71,
			"carboidrati": 1.6,
			"zuccheri": 1.6,
			"fibra_alimentare": 0
		},
		{
			"name": "corned beef in scatola",
			"calorie": 219,
			"proteine": 23.2,
			"grassi_totali": 14,
			"grassi_saturi": 5.88,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "cornflakes",
			"calorie": 364,
			"proteine": 6.6,
			"grassi_totali": 0.8,
			"grassi_saturi": 0.13,
			"carboidrati": 88.1,
			"zuccheri": 10.4,
			"fibra_alimentare": 3.8
		},
		{
			"name": "coscia di capriolo",
			"calorie": 103,
			"proteine": 22.2,
			"grassi_totali": 1.6,
			"grassi_saturi": 0.63,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "coscia di faraona",
			"calorie": 114,
			"proteine": 24.3,
			"grassi_totali": 1.8,
			"grassi_saturi": 0.58,
			"carboidrati": 0.2,
			"zuccheri": 0.2,
			"fibra_alimentare": 0
		},
		{
			"name": "coscia di lepre",
			"calorie": 121,
			"proteine": 22.8,
			"grassi_totali": 3.2,
			"grassi_saturi": 0.95,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "coscia di pollo",
			"calorie": 130,
			"proteine": 17.9,
			"grassi_totali": 6.5,
			"grassi_saturi": 2.16,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "coscia di tacchino",
			"calorie": 186,
			"proteine": 20.9,
			"grassi_totali": 11.2,
			"grassi_saturi": 3.39,
			"carboidrati": 0.4,
			"zuccheri": 0.4,
			"fibra_alimentare": 0
		},
		{
			"name": "coscio di starna",
			"calorie": 113,
			"proteine": 26.4,
			"grassi_totali": 2.6,
			"grassi_saturi": 0.79,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "cosciotto di suino",
			"calorie": 102,
			"proteine": 18.7,
			"grassi_totali": 3,
			"grassi_saturi": 1.37,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "costolette di agnello",
			"calorie": 386,
			"proteine": 14.6,
			"grassi_totali": 36.3,
			"grassi_saturi": 17.14,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "cotechino",
			"calorie": 450,
			"proteine": 17.2,
			"grassi_totali": 42.3,
			"grassi_saturi": 14,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "cotechino cotto",
			"calorie": 306,
			"proteine": 21.1,
			"grassi_totali": 24.7,
			"grassi_saturi": 8.18,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "cozze",
			"calorie": 84,
			"proteine": 11.7,
			"grassi_totali": 2.7,
			"grassi_saturi": 0.52,
			"carboidrati": 3.4,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "crackers al formaggio",
			"calorie": 502,
			"proteine": 9.8,
			"grassi_totali": 25.5,
			"grassi_saturi": 9.45,
			"carboidrati": 62.1,
			"zuccheri": 0,
			"fibra_alimentare": 2.8
		},
		{
			"name": "crackers integrali",
			"calorie": 443,
			"proteine": 8.8,
			"grassi_totali": 17.2,
			"grassi_saturi": 3.08,
			"carboidrati": 58.1,
			"zuccheri": 0,
			"fibra_alimentare": 10.5
		},
		{
			"name": "crackers non salati",
			"calorie": 440,
			"proteine": 9.5,
			"grassi_totali": 16.3,
			"grassi_saturi": 3.13,
			"carboidrati": 68.3,
			"zuccheri": 0,
			"fibra_alimentare": 6.1
		},
		{
			"name": "crackers salati",
			"calorie": 428,
			"proteine": 9.4,
			"grassi_totali": 10,
			"grassi_saturi": 1.92,
			"carboidrati": 80.1,
			"zuccheri": 2.6,
			"fibra_alimentare": 2.8
		},
		{
			"name": "crackers senza grassi",
			"calorie": 393,
			"proteine": 10.5,
			"grassi_totali": 1.6,
			"grassi_saturi": 0.24,
			"carboidrati": 79.6,
			"zuccheri": 0,
			"fibra_alimentare": 2.7
		},
		{
			"name": "cracotte",
			"calorie": 348,
			"proteine": 10.5,
			"grassi_totali": 1.2,
			"grassi_saturi": 0.13,
			"carboidrati": 78.8,
			"zuccheri": 3.6,
			"fibra_alimentare": 9.2
		},
		{
			"name": "crauti",
			"calorie": 24,
			"proteine": 1.5,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.08,
			"carboidrati": 4,
			"zuccheri": 4,
			"fibra_alimentare": 1.1
		},
		{
			"name": "crema di cacao",
			"calorie": 537,
			"proteine": 6.9,
			"grassi_totali": 32.4,
			"grassi_saturi": 9.92,
			"carboidrati": 58.1,
			"zuccheri": 58.1,
			"fibra_alimentare": 1.2
		},
		{
			"name": "crema di nocciole",
			"calorie": 537,
			"proteine": 6.9,
			"grassi_totali": 32.4,
			"grassi_saturi": 9.92,
			"carboidrati": 58.1,
			"zuccheri": 58.1,
			"fibra_alimentare": 1.2
		},
		{
			"name": "crema pasticcera",
			"calorie": 192,
			"proteine": 4.8,
			"grassi_totali": 6.4,
			"grassi_saturi": 2.63,
			"carboidrati": 30.7,
			"zuccheri": 25.9,
			"fibra_alimentare": 0.2
		},
		{
			"name": "creme caramel",
			"calorie": 109,
			"proteine": 3,
			"grassi_totali": 2.2,
			"grassi_saturi": 1.35,
			"carboidrati": 20.6,
			"zuccheri": 18,
			"fibra_alimentare": 0.1
		},
		{
			"name": "crescione",
			"calorie": 22,
			"proteine": 3,
			"grassi_totali": 1,
			"grassi_saturi": 0.27,
			"carboidrati": 0.4,
			"zuccheri": 0.4,
			"fibra_alimentare": 3
		},
		{
			"name": "crostata con marmellata",
			"calorie": 339,
			"proteine": 4.9,
			"grassi_totali": 8.2,
			"grassi_saturi": 4.56,
			"carboidrati": 65.5,
			"zuccheri": 39.6,
			"fibra_alimentare": 1.6
		},
		{
			"name": "crusca di grano",
			"calorie": 206,
			"proteine": 14.1,
			"grassi_totali": 5.5,
			"grassi_saturi": 0.93,
			"carboidrati": 26.8,
			"zuccheri": 3.8,
			"fibra_alimentare": 39.6
		},
		{
			"name": "cuore di bovino",
			"calorie": 123,
			"proteine": 16.8,
			"grassi_totali": 6,
			"grassi_saturi": 2.94,
			"carboidrati": 0.5,
			"zuccheri": 0.5,
			"fibra_alimentare": 0
		},
		{
			"name": "cuore di cavallo",
			"calorie": 146,
			"proteine": 18.7,
			"grassi_totali": 7.7,
			"grassi_saturi": 2.41,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "cuore di ovino",
			"calorie": 134,
			"proteine": 16.9,
			"grassi_totali": 7.2,
			"grassi_saturi": 2.78,
			"carboidrati": 0.4,
			"zuccheri": 0.4,
			"fibra_alimentare": 0
		},
		{
			"name": "cuore di suino",
			"calorie": 159,
			"proteine": 18.3,
			"grassi_totali": 9.4,
			"grassi_saturi": 2.71,
			"carboidrati": 0.4,
			"zuccheri": 0.4,
			"fibra_alimentare": 0
		},
		{
			"name": "daikon",
			"calorie": 15,
			"proteine": 0.8,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.03,
			"carboidrati": 2.9,
			"zuccheri": 2.9,
			"fibra_alimentare": 1.5
		},
		{
			"name": "datteri freschi",
			"calorie": 124,
			"proteine": 1.5,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.04,
			"carboidrati": 31.3,
			"zuccheri": 31.3,
			"fibra_alimentare": 3.6
		},
		{
			"name": "datteri secchi",
			"calorie": 253,
			"proteine": 2.7,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.25,
			"carboidrati": 63.1,
			"zuccheri": 63.1,
			"fibra_alimentare": 8.7
		},
		{
			"name": "dentice",
			"calorie": 101,
			"proteine": 16.7,
			"grassi_totali": 3.5,
			"grassi_saturi": 0.45,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 0
		},
		{
			"name": "dentice surgelato",
			"calorie": 103,
			"proteine": 17.2,
			"grassi_totali": 3.5,
			"grassi_saturi": 0.45,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 0
		},
		{
			"name": "dolce verde",
			"calorie": 266,
			"proteine": 19.1,
			"grassi_totali": 20,
			"grassi_saturi": 12.41,
			"carboidrati": 2.5,
			"zuccheri": 2.5,
			"fibra_alimentare": 0
		},
		{
			"name": "dolcificante a base di saccarina",
			"calorie": 397,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 98.6,
			"zuccheri": 98.6,
			"fibra_alimentare": 0
		},
		{
			"name": "dolcificante aspartame",
			"calorie": 400,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 97.5,
			"zuccheri": 70,
			"fibra_alimentare": 0
		},
		{
			"name": "dolcificante fruttosio",
			"calorie": 400,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 99.5,
			"zuccheri": 99.5,
			"fibra_alimentare": 0
		},
		{
			"name": "dolcificante saccarina",
			"calorie": 0,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "edam fresco",
			"calorie": 306,
			"proteine": 26,
			"grassi_totali": 22,
			"grassi_saturi": 13.14,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "edam stagionato",
			"calorie": 345,
			"proteine": 29,
			"grassi_totali": 25,
			"grassi_saturi": 15.8,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "emmenthal",
			"calorie": 403,
			"proteine": 28.5,
			"grassi_totali": 30.6,
			"grassi_saturi": 18.4,
			"carboidrati": 3.6,
			"zuccheri": 3.6,
			"fibra_alimentare": 0
		},
		{
			"name": "erba cipollina fresca",
			"calorie": 23,
			"proteine": 2.8,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.12,
			"carboidrati": 1.7,
			"zuccheri": 1.7,
			"fibra_alimentare": 1.9
		},
		{
			"name": "estratto di carne bovina",
			"calorie": 219,
			"proteine": 54,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.17,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0.9
		},
		{
			"name": "fagiano",
			"calorie": 144,
			"proteine": 24.3,
			"grassi_totali": 5.2,
			"grassi_saturi": 1.51,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "fagioli",
			"calorie": 104,
			"proteine": 6.4,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.12,
			"carboidrati": 19.4,
			"zuccheri": 1.3,
			"fibra_alimentare": 10.6
		},
		{
			"name": "fagioli aduki secchi",
			"calorie": 272,
			"proteine": 19.9,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.19,
			"carboidrati": 50.1,
			"zuccheri": 1,
			"fibra_alimentare": 11.1
		},
		{
			"name": "fagioli in scatola",
			"calorie": 43,
			"proteine": 4.1,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.1,
			"carboidrati": 6.3,
			"zuccheri": 1.1,
			"fibra_alimentare": 6.8
		},
		{
			"name": "fagioli mung secchi",
			"calorie": 279,
			"proteine": 23.9,
			"grassi_totali": 1.1,
			"grassi_saturi": 0.22,
			"carboidrati": 46.3,
			"zuccheri": 1.5,
			"fibra_alimentare": 13.9
		},
		{
			"name": "fagioli secchi",
			"calorie": 311,
			"proteine": 23.6,
			"grassi_totali": 2.5,
			"grassi_saturi": 0.5,
			"carboidrati": 51.7,
			"zuccheri": 4,
			"fibra_alimentare": 17
		},
		{
			"name": "fagiolini",
			"calorie": 17,
			"proteine": 2.1,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 2.4,
			"zuccheri": 2.4,
			"fibra_alimentare": 2.9
		},
		{
			"name": "fagiolini in scatola",
			"calorie": 22,
			"proteine": 1.5,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 4.1,
			"zuccheri": 1,
			"fibra_alimentare": 2.6
		},
		{
			"name": "faraona",
			"calorie": 158,
			"proteine": 23.4,
			"grassi_totali": 6.5,
			"grassi_saturi": 1.77,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "farina di avena",
			"calorie": 388,
			"proteine": 12.6,
			"grassi_totali": 7.1,
			"grassi_saturi": 1.24,
			"carboidrati": 72.9,
			"zuccheri": 0,
			"fibra_alimentare": 7.6
		},
		{
			"name": "farina di castagne",
			"calorie": 323,
			"proteine": 5.8,
			"grassi_totali": 3.7,
			"grassi_saturi": 0.64,
			"carboidrati": 71.6,
			"zuccheri": 27.5,
			"fibra_alimentare": 10.9
		},
		{
			"name": "farina di ceci",
			"calorie": 334,
			"proteine": 21.8,
			"grassi_totali": 4.9,
			"grassi_saturi": 0.63,
			"carboidrati": 54.3,
			"zuccheri": 3.7,
			"fibra_alimentare": 13.8
		},
		{
			"name": "farina di cocco",
			"calorie": 604,
			"proteine": 5.6,
			"grassi_totali": 62,
			"grassi_saturi": 53.27,
			"carboidrati": 6.4,
			"zuccheri": 6.4,
			"fibra_alimentare": 21.1
		},
		{
			"name": "farina di frumento integrale",
			"calorie": 321,
			"proteine": 11.9,
			"grassi_totali": 1.9,
			"grassi_saturi": 0.43,
			"carboidrati": 68.4,
			"zuccheri": 2.1,
			"fibra_alimentare": 9.6
		},
		{
			"name": "farina di frumento tipo 0",
			"calorie": 343,
			"proteine": 11.5,
			"grassi_totali": 1,
			"grassi_saturi": 0.23,
			"carboidrati": 76.9,
			"zuccheri": 1.8,
			"fibra_alimentare": 3.5
		},
		{
			"name": "farina di frumento tipo 00",
			"calorie": 343,
			"proteine": 11,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.16,
			"carboidrati": 78,
			"zuccheri": 1.7,
			"fibra_alimentare": 2.5
		},
		{
			"name": "farina di grano duro",
			"calorie": 304,
			"proteine": 12.6,
			"grassi_totali": 2.8,
			"grassi_saturi": 0.51,
			"carboidrati": 61.1,
			"zuccheri": 3.1,
			"fibra_alimentare": 3.5
		},
		{
			"name": "farina di grano saraceno",
			"calorie": 364,
			"proteine": 8.1,
			"grassi_totali": 1.5,
			"grassi_saturi": 0.33,
			"carboidrati": 84.9,
			"zuccheri": 0.4,
			"fibra_alimentare": 2.1
		},
		{
			"name": "farina di mais",
			"calorie": 365,
			"proteine": 8.7,
			"grassi_totali": 2.7,
			"grassi_saturi": 0.34,
			"carboidrati": 81.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 2.6
		},
		{
			"name": "farina di orzo",
			"calorie": 360,
			"proteine": 10.6,
			"grassi_totali": 1.9,
			"grassi_saturi": 0.4,
			"carboidrati": 80,
			"zuccheri": 0,
			"fibra_alimentare": 14.8
		},
		{
			"name": "farina di riso",
			"calorie": 363,
			"proteine": 7.3,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.14,
			"carboidrati": 87.8,
			"zuccheri": 0,
			"fibra_alimentare": 0.7
		},
		{
			"name": "farina di segale integrale",
			"calorie": 335,
			"proteine": 8.2,
			"grassi_totali": 2,
			"grassi_saturi": 0.23,
			"carboidrati": 75.9,
			"zuccheri": 0,
			"fibra_alimentare": 11.7
		},
		{
			"name": "farina di soia intera",
			"calorie": 447,
			"proteine": 36.8,
			"grassi_totali": 23.5,
			"grassi_saturi": 3.3,
			"carboidrati": 23.5,
			"zuccheri": 11.2,
			"fibra_alimentare": 10.7
		},
		{
			"name": "farina lattea",
			"calorie": 415,
			"proteine": 13.2,
			"grassi_totali": 6.3,
			"grassi_saturi": 0,
			"carboidrati": 81.5,
			"zuccheri": 48.2,
			"fibra_alimentare": 0
		},
		{
			"name": "fave",
			"calorie": 37,
			"proteine": 5.4,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.03,
			"carboidrati": 4.2,
			"zuccheri": 0.4,
			"fibra_alimentare": 5.1
		},
		{
			"name": "fave secche",
			"calorie": 342,
			"proteine": 27.2,
			"grassi_totali": 3,
			"grassi_saturi": 0.5,
			"carboidrati": 55.3,
			"zuccheri": 4.9,
			"fibra_alimentare": 7
		},
		{
			"name": "fecola di patate",
			"calorie": 349,
			"proteine": 1.4,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 91.5,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "fegatini di pollo",
			"calorie": 122,
			"proteine": 20.5,
			"grassi_totali": 4.5,
			"grassi_saturi": 1.4,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "fegato di bovino",
			"calorie": 146,
			"proteine": 21,
			"grassi_totali": 4.4,
			"grassi_saturi": 1.62,
			"carboidrati": 5.9,
			"zuccheri": 5.9,
			"fibra_alimentare": 0
		},
		{
			"name": "fegato di cavallo",
			"calorie": 145,
			"proteine": 22.4,
			"grassi_totali": 4,
			"grassi_saturi": 1.47,
			"carboidrati": 5.3,
			"zuccheri": 5.3,
			"fibra_alimentare": 0
		},
		{
			"name": "fegato di ovino",
			"calorie": 135,
			"proteine": 19.5,
			"grassi_totali": 5.5,
			"grassi_saturi": 1.7,
			"carboidrati": 2,
			"zuccheri": 2,
			"fibra_alimentare": 0
		},
		{
			"name": "fegato suino",
			"calorie": 140,
			"proteine": 22.8,
			"grassi_totali": 4.8,
			"grassi_saturi": 1.48,
			"carboidrati": 1.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 0
		},
		{
			"name": "feta",
			"calorie": 250,
			"proteine": 15.6,
			"grassi_totali": 20.2,
			"grassi_saturi": 14.19,
			"carboidrati": 1.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 0
		},
		{
			"name": "fette biscottate",
			"calorie": 410,
			"proteine": 11.3,
			"grassi_totali": 6,
			"grassi_saturi": 0.83,
			"carboidrati": 83,
			"zuccheri": 2.2,
			"fibra_alimentare": 3.5
		},
		{
			"name": "fette biscottate dolci",
			"calorie": 404,
			"proteine": 10.5,
			"grassi_totali": 6.2,
			"grassi_saturi": 0.88,
			"carboidrati": 81.6,
			"zuccheri": 11.8,
			"fibra_alimentare": 3.5
		},
		{
			"name": "fette biscottate integrali",
			"calorie": 365,
			"proteine": 11.2,
			"grassi_totali": 5.2,
			"grassi_saturi": 0.76,
			"carboidrati": 74.1,
			"zuccheri": 10,
			"fibra_alimentare": 11
		},
		{
			"name": "fette biscottate vitaminizzate",
			"calorie": 410,
			"proteine": 11.3,
			"grassi_totali": 6,
			"grassi_saturi": 0.83,
			"carboidrati": 83,
			"zuccheri": 2.2,
			"fibra_alimentare": 3.5
		},
		{
			"name": "fette di segale integrali",
			"calorie": 366,
			"proteine": 7.9,
			"grassi_totali": 1.3,
			"grassi_saturi": 0.15,
			"carboidrati": 65.7,
			"zuccheri": 3.1,
			"fibra_alimentare": 16.5
		},
		{
			"name": "fichi",
			"calorie": 47,
			"proteine": 0.9,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.04,
			"carboidrati": 11.2,
			"zuccheri": 11.2,
			"fibra_alimentare": 2
		},
		{
			"name": "fichi d'india",
			"calorie": 53,
			"proteine": 0.8,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 13,
			"zuccheri": 13,
			"fibra_alimentare": 5
		},
		{
			"name": "fichi seccati al forno e mandorlati",
			"calorie": 277,
			"proteine": 5.2,
			"grassi_totali": 4.2,
			"grassi_saturi": 0.84,
			"carboidrati": 58.2,
			"zuccheri": 58.2,
			"fibra_alimentare": 10.4
		},
		{
			"name": "fichi seccati al sole",
			"calorie": 232,
			"proteine": 3.6,
			"grassi_totali": 2.1,
			"grassi_saturi": 0.42,
			"carboidrati": 52.9,
			"zuccheri": 52.9,
			"fibra_alimentare": 18.5
		},
		{
			"name": "fichi secchi",
			"calorie": 242,
			"proteine": 3.5,
			"grassi_totali": 2.7,
			"grassi_saturi": 0.54,
			"carboidrati": 58,
			"zuccheri": 58,
			"fibra_alimentare": 10.4
		},
		{
			"name": "filetti sott'olio di sgombro",
			"calorie": 201,
			"proteine": 25.3,
			"grassi_totali": 10.7,
			"grassi_saturi": 2.31,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "finocchio",
			"calorie": 9,
			"proteine": 1.2,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 2.2
		},
		{
			"name": "fiocchi d'orzo",
			"calorie": 360,
			"proteine": 10.6,
			"grassi_totali": 1.9,
			"grassi_saturi": 0.4,
			"carboidrati": 80,
			"zuccheri": 0,
			"fibra_alimentare": 14.8
		},
		{
			"name": "fiocchi di avena",
			"calorie": 395,
			"proteine": 8,
			"grassi_totali": 7.5,
			"grassi_saturi": 1.32,
			"carboidrati": 73.5,
			"zuccheri": 0,
			"fibra_alimentare": 8.3
		},
		{
			"name": "fiocchi di crusca di grano",
			"calorie": 319,
			"proteine": 10.2,
			"grassi_totali": 1.9,
			"grassi_saturi": 0.44,
			"carboidrati": 69.7,
			"zuccheri": 19,
			"fibra_alimentare": 17.3
		},
		{
			"name": "fiocchi di latte magro",
			"calorie": 102,
			"proteine": 11.7,
			"grassi_totali": 5.3,
			"grassi_saturi": 3.17,
			"carboidrati": 2.6,
			"zuccheri": 2.6,
			"fibra_alimentare": 0
		},
		{
			"name": "fiocchi di riso",
			"calorie": 346,
			"proteine": 6.6,
			"grassi_totali": 1.2,
			"grassi_saturi": 0.26,
			"carboidrati": 77.5,
			"zuccheri": 0,
			"fibra_alimentare": 2.2
		},
		{
			"name": "fior di latte",
			"calorie": 265,
			"proteine": 20.6,
			"grassi_totali": 20.3,
			"grassi_saturi": 12.6,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "fiori di zucca",
			"calorie": 12,
			"proteine": 1.7,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.21,
			"carboidrati": 0.5,
			"zuccheri": 0.5,
			"fibra_alimentare": 0.5
		},
		{
			"name": "focaccia",
			"calorie": 292,
			"proteine": 8.4,
			"grassi_totali": 11.8,
			"grassi_saturi": 2,
			"carboidrati": 40.7,
			"zuccheri": 9.9,
			"fibra_alimentare": 4.9
		},
		{
			"name": "foglie di rapa",
			"calorie": 22,
			"proteine": 2.6,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 2.8,
			"zuccheri": 2.8,
			"fibra_alimentare": 2.4
		},
		{
			"name": "fontina",
			"calorie": 343,
			"proteine": 24.5,
			"grassi_totali": 26.9,
			"grassi_saturi": 14.73,
			"carboidrati": 0.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 0
		},
		{
			"name": "formaggino",
			"calorie": 309,
			"proteine": 11.2,
			"grassi_totali": 26.9,
			"grassi_saturi": 16.08,
			"carboidrati": 6,
			"zuccheri": 6,
			"fibra_alimentare": 0
		},
		{
			"name": "formaggino a basso tenore di lipidi",
			"calorie": 239,
			"proteine": 14.7,
			"grassi_totali": 17.3,
			"grassi_saturi": 10.34,
			"carboidrati": 6.5,
			"zuccheri": 6.5,
			"fibra_alimentare": 0
		},
		{
			"name": "formaggio al gorgonzola",
			"calorie": 406,
			"proteine": 13.5,
			"grassi_totali": 39.1,
			"grassi_saturi": 22.13,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "formaggio al mascarpone",
			"calorie": 406,
			"proteine": 13.5,
			"grassi_totali": 39.1,
			"grassi_saturi": 22.13,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "formaggio bel paese",
			"calorie": 374,
			"proteine": 25.4,
			"grassi_totali": 30.2,
			"grassi_saturi": 14.67,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "formaggio latteria",
			"calorie": 450,
			"proteine": 30.7,
			"grassi_totali": 36.4,
			"grassi_saturi": 22.59,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "formaggio spalmabile (philadelphia)",
			"calorie": 313,
			"proteine": 8.6,
			"grassi_totali": 31,
			"grassi_saturi": 18.52,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "fragole",
			"calorie": 27,
			"proteine": 0.9,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.02,
			"carboidrati": 5.3,
			"zuccheri": 5.3,
			"fibra_alimentare": 1.6
		},
		{
			"name": "frattaglie di coniglio",
			"calorie": 147,
			"proteine": 18.7,
			"grassi_totali": 7.9,
			"grassi_saturi": 3.12,
			"carboidrati": 0.4,
			"zuccheri": 0.4,
			"fibra_alimentare": 0
		},
		{
			"name": "frumento duro",
			"calorie": 314,
			"proteine": 13,
			"grassi_totali": 2.9,
			"grassi_saturi": 0.53,
			"carboidrati": 63,
			"zuccheri": 3.2,
			"fibra_alimentare": 12.6
		},
		{
			"name": "frumento tenero",
			"calorie": 319,
			"proteine": 12.3,
			"grassi_totali": 2.6,
			"grassi_saturi": 0.6,
			"carboidrati": 65.8,
			"zuccheri": 3.3,
			"fibra_alimentare": 1.7
		},
		{
			"name": "funghi chiodini",
			"calorie": 15,
			"proteine": 2.1,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.15,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 6.8
		},
		{
			"name": "funghi gallinacci",
			"calorie": 11,
			"proteine": 1.6,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.09,
			"carboidrati": 0.2,
			"zuccheri": 0.2,
			"fibra_alimentare": 4.7
		},
		{
			"name": "funghi gallinacci secchi",
			"calorie": 93,
			"proteine": 16.5,
			"grassi_totali": 2.2,
			"grassi_saturi": 0.52,
			"carboidrati": 1.8,
			"zuccheri": 0.9,
			"fibra_alimentare": 60.5
		},
		{
			"name": "funghi in scatola",
			"calorie": 27,
			"proteine": 3,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.05,
			"carboidrati": 3,
			"zuccheri": 3,
			"fibra_alimentare": 2.4
		},
		{
			"name": "funghi ovuli",
			"calorie": 18,
			"proteine": 2,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.07,
			"carboidrati": 1.9,
			"zuccheri": 1,
			"fibra_alimentare": 1.7
		},
		{
			"name": "funghi porcinelli",
			"calorie": 24,
			"proteine": 3.1,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.13,
			"carboidrati": 1.6,
			"zuccheri": 1.3,
			"fibra_alimentare": 6.5
		},
		{
			"name": "funghi porcini",
			"calorie": 27,
			"proteine": 3.9,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.15,
			"carboidrati": 1.4,
			"zuccheri": 1.2,
			"fibra_alimentare": 2.5
		},
		{
			"name": "funghi prataioli coltivati",
			"calorie": 20,
			"proteine": 2.3,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.1,
			"carboidrati": 1.9,
			"zuccheri": 1,
			"fibra_alimentare": 1.7
		},
		{
			"name": "funghi secchi",
			"calorie": 147,
			"proteine": 40.6,
			"grassi_totali": 4.6,
			"grassi_saturi": 1.15,
			"carboidrati": 15.6,
			"zuccheri": 13.8,
			"fibra_alimentare": 10
		},
		{
			"name": "funghi shiitake secchi",
			"calorie": 296,
			"proteine": 16.3,
			"grassi_totali": 1,
			"grassi_saturi": 0.25,
			"carboidrati": 63.9,
			"zuccheri": 56.5,
			"fibra_alimentare": 11.5
		},
		{
			"name": "funghi sott'olio",
			"calorie": 48,
			"proteine": 3.3,
			"grassi_totali": 3.9,
			"grassi_saturi": 0.48,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 1.6
		},
		{
			"name": "funghi spugnoli",
			"calorie": 17,
			"proteine": 1.7,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.07,
			"carboidrati": 1.9,
			"zuccheri": 1,
			"fibra_alimentare": 7
		},
		{
			"name": "gallina",
			"calorie": 195,
			"proteine": 20.9,
			"grassi_totali": 12.3,
			"grassi_saturi": 4.09,
			"carboidrati": 0.2,
			"zuccheri": 0.2,
			"fibra_alimentare": 0
		},
		{
			"name": "gamberetti di lago",
			"calorie": 105,
			"proteine": 17.1,
			"grassi_totali": 4.1,
			"grassi_saturi": 0.69,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "gamberetti surgelati",
			"calorie": 63,
			"proteine": 13.6,
			"grassi_totali": 1,
			"grassi_saturi": 0.17,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "gambero",
			"calorie": 71,
			"proteine": 13.6,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.09,
			"carboidrati": 2.9,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "gelatina alimentare secca",
			"calorie": 354,
			"proteine": 88.3,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.04,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "gelato al cioccolato",
			"calorie": 216,
			"proteine": 3.8,
			"grassi_totali": 11,
			"grassi_saturi": 6.8,
			"carboidrati": 27,
			"zuccheri": 27,
			"fibra_alimentare": 1.2
		},
		{
			"name": "gelato alla frutta",
			"calorie": 179,
			"proteine": 3.5,
			"grassi_totali": 8,
			"grassi_saturi": 4.96,
			"carboidrati": 24.7,
			"zuccheri": 23.7,
			"fibra_alimentare": 0
		},
		{
			"name": "gelato fior di latte",
			"calorie": 218,
			"proteine": 4.2,
			"grassi_totali": 13.7,
			"grassi_saturi": 8.58,
			"carboidrati": 20.7,
			"zuccheri": 20.7,
			"fibra_alimentare": 0
		},
		{
			"name": "germe di grano",
			"calorie": 416,
			"proteine": 28,
			"grassi_totali": 10,
			"grassi_saturi": 1.72,
			"carboidrati": 55,
			"zuccheri": 19.7,
			"fibra_alimentare": 15.6
		},
		{
			"name": "germogli di erba medica",
			"calorie": 24,
			"proteine": 4,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.07,
			"carboidrati": 0.4,
			"zuccheri": 0.3,
			"fibra_alimentare": 1.7
		},
		{
			"name": "germogli di soia",
			"calorie": 49,
			"proteine": 6.2,
			"grassi_totali": 1.4,
			"grassi_saturi": 0.2,
			"carboidrati": 3,
			"zuccheri": 3,
			"fibra_alimentare": 1.1
		},
		{
			"name": "ghiacciolo all'arancia",
			"calorie": 137,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 36.5,
			"zuccheri": 36.5,
			"fibra_alimentare": 0
		},
		{
			"name": "giardiniera sott'aceto",
			"calorie": 7,
			"proteine": 0.4,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.03,
			"carboidrati": 0.6,
			"zuccheri": 0.4,
			"fibra_alimentare": 1.8
		},
		{
			"name": "giardiniera sott'olio",
			"calorie": 58,
			"proteine": 2.6,
			"grassi_totali": 4.8,
			"grassi_saturi": 0.58,
			"carboidrati": 1.3,
			"zuccheri": 0.6,
			"fibra_alimentare": 2.5
		},
		{
			"name": "girasole semi",
			"calorie": 581,
			"proteine": 19.8,
			"grassi_totali": 47.5,
			"grassi_saturi": 5.34,
			"carboidrati": 18.6,
			"zuccheri": 1.7,
			"fibra_alimentare": 6.2
		},
		{
			"name": "gomme da masticare",
			"calorie": 341,
			"proteine": 0,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.04,
			"carboidrati": 96.7,
			"zuccheri": 96.7,
			"fibra_alimentare": 0
		},
		{
			"name": "gorgonzola",
			"calorie": 359,
			"proteine": 19.4,
			"grassi_totali": 31.2,
			"grassi_saturi": 15.08,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "gorgonzola con noci",
			"calorie": 371,
			"proteine": 19.3,
			"grassi_totali": 32.5,
			"grassi_saturi": 14.69,
			"carboidrati": 0.4,
			"zuccheri": 0.3,
			"fibra_alimentare": 0.3
		},
		{
			"name": "gouda fresco",
			"calorie": 344,
			"proteine": 23,
			"grassi_totali": 28,
			"grassi_saturi": 17.97,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "gouda stagionato",
			"calorie": 361,
			"proteine": 25,
			"grassi_totali": 29,
			"grassi_saturi": 18.62,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "grana",
			"calorie": 381,
			"proteine": 35.5,
			"grassi_totali": 25,
			"grassi_saturi": 16.5,
			"carboidrati": 3.7,
			"zuccheri": 3.7,
			"fibra_alimentare": 0
		},
		{
			"name": "granchio",
			"calorie": 87,
			"proteine": 18.1,
			"grassi_totali": 1.1,
			"grassi_saturi": 0.22,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "granita di limone",
			"calorie": 106,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 28.4,
			"zuccheri": 28.4,
			"fibra_alimentare": 0
		},
		{
			"name": "granito di grano tenero",
			"calorie": 354,
			"proteine": 9.2,
			"grassi_totali": 1,
			"grassi_saturi": 0.23,
			"carboidrati": 82.3,
			"zuccheri": 0,
			"fibra_alimentare": 2.5
		},
		{
			"name": "grappa",
			"calorie": 235,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "grissini",
			"calorie": 433,
			"proteine": 12.3,
			"grassi_totali": 13.9,
			"grassi_saturi": 4.04,
			"carboidrati": 69,
			"zuccheri": 2.2,
			"fibra_alimentare": 3.5
		},
		{
			"name": "grissini integrali",
			"calorie": 443,
			"proteine": 8.8,
			"grassi_totali": 17.2,
			"grassi_saturi": 3.08,
			"carboidrati": 58.1,
			"zuccheri": 1.9,
			"fibra_alimentare": 10.5
		},
		{
			"name": "groviera",
			"calorie": 388,
			"proteine": 30.6,
			"grassi_totali": 29,
			"grassi_saturi": 17.44,
			"carboidrati": 1.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 0
		},
		{
			"name": "halibut",
			"calorie": 110,
			"proteine": 20.8,
			"grassi_totali": 2.3,
			"grassi_saturi": 0.33,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "indivia",
			"calorie": 16,
			"proteine": 0.9,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.07,
			"carboidrati": 2.7,
			"zuccheri": 2.7,
			"fibra_alimentare": 1.6
		},
		{
			"name": "insalata di riso",
			"calorie": 187,
			"proteine": 5.9,
			"grassi_totali": 11.5,
			"grassi_saturi": 2.75,
			"carboidrati": 16.1,
			"zuccheri": 1,
			"fibra_alimentare": 1.3
		},
		{
			"name": "irish moss",
			"calorie": 8,
			"proteine": 1.5,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.04,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 12.3
		},
		{
			"name": "kaki",
			"calorie": 65,
			"proteine": 0.6,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.03,
			"carboidrati": 16,
			"zuccheri": 16,
			"fibra_alimentare": 2.5
		},
		{
			"name": "kiwi",
			"calorie": 44,
			"proteine": 1.2,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.04,
			"carboidrati": 9,
			"zuccheri": 9,
			"fibra_alimentare": 2.6
		},
		{
			"name": "kombu secca",
			"calorie": 43,
			"proteine": 7.1,
			"grassi_totali": 1.6,
			"grassi_saturi": 0.71,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 58.7
		},
		{
			"name": "lamponi",
			"calorie": 34,
			"proteine": 1,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.02,
			"carboidrati": 6.5,
			"zuccheri": 6.5,
			"fibra_alimentare": 7.4
		},
		{
			"name": "lardo",
			"calorie": 891,
			"proteine": 0,
			"grassi_totali": 99,
			"grassi_saturi": 34.12,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "latte di bufala",
			"calorie": 102,
			"proteine": 4.4,
			"grassi_totali": 7.3,
			"grassi_saturi": 4.86,
			"carboidrati": 5.1,
			"zuccheri": 5.1,
			"fibra_alimentare": 0
		},
		{
			"name": "latte di capra",
			"calorie": 72,
			"proteine": 3.9,
			"grassi_totali": 4.3,
			"grassi_saturi": 2.82,
			"carboidrati": 4.7,
			"zuccheri": 4.7,
			"fibra_alimentare": 0
		},
		{
			"name": "latte di mandorle",
			"calorie": 25,
			"proteine": 0.6,
			"grassi_totali": 1.5,
			"grassi_saturi": 0.12,
			"carboidrati": 2.4,
			"zuccheri": 1.7,
			"fibra_alimentare": 0.4
		},
		{
			"name": "latte di pecora",
			"calorie": 103,
			"proteine": 5.3,
			"grassi_totali": 6.9,
			"grassi_saturi": 4.54,
			"carboidrati": 5.2,
			"zuccheri": 5.2,
			"fibra_alimentare": 0
		},
		{
			"name": "latte di soia",
			"calorie": 32,
			"proteine": 2.9,
			"grassi_totali": 1.9,
			"grassi_saturi": 0.21,
			"carboidrati": 0.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 0
		},
		{
			"name": "latte di vacca condensato zuccherato",
			"calorie": 327,
			"proteine": 8.7,
			"grassi_totali": 9,
			"grassi_saturi": 5.59,
			"carboidrati": 56.5,
			"zuccheri": 56.5,
			"fibra_alimentare": 0
		},
		{
			"name": "latte di vacca intero",
			"calorie": 61,
			"proteine": 3.1,
			"grassi_totali": 3.4,
			"grassi_saturi": 2.11,
			"carboidrati": 4.8,
			"zuccheri": 4.8,
			"fibra_alimentare": 0
		},
		{
			"name": "latte di vacca intero in polvere",
			"calorie": 484,
			"proteine": 25.7,
			"grassi_totali": 24.9,
			"grassi_saturi": 15.45,
			"carboidrati": 42,
			"zuccheri": 42,
			"fibra_alimentare": 0
		},
		{
			"name": "latte di vacca parzialmente scremato",
			"calorie": 49,
			"proteine": 3.5,
			"grassi_totali": 1.8,
			"grassi_saturi": 0.89,
			"carboidrati": 5,
			"zuccheri": 5,
			"fibra_alimentare": 0
		},
		{
			"name": "latte di vacca parzialmente scremato in polvere ",
			"calorie": 418,
			"proteine": 28.8,
			"grassi_totali": 12.7,
			"grassi_saturi": 6.28,
			"carboidrati": 50.2,
			"zuccheri": 50.2,
			"fibra_alimentare": 0
		},
		{
			"name": "latte di vacca scremato",
			"calorie": 36,
			"proteine": 3.6,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.14,
			"carboidrati": 5.3,
			"zuccheri": 5.3,
			"fibra_alimentare": 0
		},
		{
			"name": "latte di vacca scremato in polvere",
			"calorie": 351,
			"proteine": 33.1,
			"grassi_totali": 0.9,
			"grassi_saturi": 0.63,
			"carboidrati": 56.2,
			"zuccheri": 56.2,
			"fibra_alimentare": 0
		},
		{
			"name": "latte evaporato",
			"calorie": 151,
			"proteine": 8.4,
			"grassi_totali": 9.4,
			"grassi_saturi": 5.83,
			"carboidrati": 8.5,
			"zuccheri": 8.5,
			"fibra_alimentare": 0
		},
		{
			"name": "latterini",
			"calorie": 145,
			"proteine": 14.6,
			"grassi_totali": 9.6,
			"grassi_saturi": 2.18,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "lattuga",
			"calorie": 19,
			"proteine": 1.8,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.05,
			"carboidrati": 2.2,
			"zuccheri": 2.2,
			"fibra_alimentare": 1.5
		},
		{
			"name": "lattuga a cappuccio",
			"calorie": 19,
			"proteine": 1.5,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.03,
			"carboidrati": 3,
			"zuccheri": 3,
			"fibra_alimentare": 1.3
		},
		{
			"name": "lattuga da taglio",
			"calorie": 14,
			"proteine": 1.1,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 2.2,
			"zuccheri": 2.2,
			"fibra_alimentare": 1.9
		},
		{
			"name": "legumi surgelati",
			"calorie": 64,
			"proteine": 3.3,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.1,
			"carboidrati": 9.5,
			"zuccheri": 3.5,
			"fibra_alimentare": 4
		},
		{
			"name": "lenticchie in scatola",
			"calorie": 61,
			"proteine": 5,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.05,
			"carboidrati": 10.7,
			"zuccheri": 1.1,
			"fibra_alimentare": 5.3
		},
		{
			"name": "lenticchie secche",
			"calorie": 325,
			"proteine": 25,
			"grassi_totali": 2.5,
			"grassi_saturi": 0.34,
			"carboidrati": 54,
			"zuccheri": 2.4,
			"fibra_alimentare": 13.7
		},
		{
			"name": "lievito di birra compresso",
			"calorie": 56,
			"proteine": 12.1,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.05,
			"carboidrati": 1.1,
			"zuccheri": 1.1,
			"fibra_alimentare": 6.9
		},
		{
			"name": "limette",
			"calorie": 38,
			"proteine": 0.7,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.03,
			"carboidrati": 8.8,
			"zuccheri": 8.8,
			"fibra_alimentare": 3.3
		},
		{
			"name": "limone",
			"calorie": 11,
			"proteine": 0.6,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 2.3,
			"zuccheri": 2.3,
			"fibra_alimentare": 5.2
		},
		{
			"name": "lingua di bovino",
			"calorie": 231,
			"proteine": 17.1,
			"grassi_totali": 18,
			"grassi_saturi": 7.71,
			"carboidrati": 0.4,
			"zuccheri": 0.4,
			"fibra_alimentare": 0
		},
		{
			"name": "lingua salmistrata di bovino",
			"calorie": 260,
			"proteine": 22.5,
			"grassi_totali": 18.8,
			"grassi_saturi": 8.18,
			"carboidrati": 0.2,
			"zuccheri": 0.2,
			"fibra_alimentare": 0
		},
		{
			"name": "liquirizia dolce",
			"calorie": 349,
			"proteine": 3.7,
			"grassi_totali": 5.2,
			"grassi_saturi": 3.6,
			"carboidrati": 76.7,
			"zuccheri": 62.4,
			"fibra_alimentare": 2
		},
		{
			"name": "liquori (tra 31 e 35 %vol) ",
			"calorie": 313,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 31,
			"zuccheri": 31,
			"fibra_alimentare": 0
		},
		{
			"name": "luccio",
			"calorie": 80,
			"proteine": 18.7,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.1,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "lumaca",
			"calorie": 98,
			"proteine": 12.9,
			"grassi_totali": 1.7,
			"grassi_saturi": 0.13,
			"carboidrati": 7.8,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "lumache di mare",
			"calorie": 137,
			"proteine": 23.8,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.03,
			"carboidrati": 7.8,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "lupini deamarizzati",
			"calorie": 114,
			"proteine": 16.4,
			"grassi_totali": 2.4,
			"grassi_saturi": 0.28,
			"carboidrati": 7.2,
			"zuccheri": 0.5,
			"fibra_alimentare": 4.8
		},
		{
			"name": "macedonia di frutta in scatola",
			"calorie": 57,
			"proteine": 0.4,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 14.8,
			"zuccheri": 14.8,
			"fibra_alimentare": 1
		},
		{
			"name": "maggiorana secca",
			"calorie": 271,
			"proteine": 12.7,
			"grassi_totali": 7,
			"grassi_saturi": 1.17,
			"carboidrati": 42.5,
			"zuccheri": 42.5,
			"fibra_alimentare": 18.1
		},
		{
			"name": "maionese",
			"calorie": 655,
			"proteine": 4.2,
			"grassi_totali": 70,
			"grassi_saturi": 8.82,
			"carboidrati": 2.2,
			"zuccheri": 2.2,
			"fibra_alimentare": 0
		},
		{
			"name": "mais",
			"calorie": 355,
			"proteine": 9.2,
			"grassi_totali": 3.8,
			"grassi_saturi": 0.48,
			"carboidrati": 75.8,
			"zuccheri": 2.5,
			"fibra_alimentare": 2.9
		},
		{
			"name": "mais dolce crudo",
			"calorie": 93,
			"proteine": 3.4,
			"grassi_totali": 1.8,
			"grassi_saturi": 0.23,
			"carboidrati": 17,
			"zuccheri": 2,
			"fibra_alimentare": 3.3
		},
		{
			"name": "mais dolce in scatola",
			"calorie": 122,
			"proteine": 2.9,
			"grassi_totali": 1.2,
			"grassi_saturi": 0.15,
			"carboidrati": 26.6,
			"zuccheri": 9.6,
			"fibra_alimentare": 3.9
		},
		{
			"name": "mandarini",
			"calorie": 72,
			"proteine": 0.9,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.04,
			"carboidrati": 17.6,
			"zuccheri": 17.6,
			"fibra_alimentare": 1.7
		},
		{
			"name": "mandorle dolci",
			"calorie": 542,
			"proteine": 16,
			"grassi_totali": 51.5,
			"grassi_saturi": 4.27,
			"carboidrati": 4,
			"zuccheri": 4,
			"fibra_alimentare": 14.3
		},
		{
			"name": "mango",
			"calorie": 57,
			"proteine": 0.7,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 14.1,
			"zuccheri": 13.8,
			"fibra_alimentare": 2.9
		},
		{
			"name": "margarina",
			"calorie": 760,
			"proteine": 0.6,
			"grassi_totali": 84,
			"grassi_saturi": 26.41,
			"carboidrati": 0.4,
			"zuccheri": 0.4,
			"fibra_alimentare": 0
		},
		{
			"name": "margarina vegetale",
			"calorie": 746,
			"proteine": 0,
			"grassi_totali": 82.8,
			"grassi_saturi": 17.1,
			"carboidrati": 0.2,
			"zuccheri": 0.2,
			"fibra_alimentare": 0
		},
		{
			"name": "marmellata",
			"calorie": 222,
			"proteine": 0.5,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 58.7,
			"zuccheri": 58.7,
			"fibra_alimentare": 2.2
		},
		{
			"name": "marmellata a ridotto tenore di zucchero",
			"calorie": 123,
			"proteine": 0.5,
			"grassi_totali": 0.1,
			"grassi_saturi": 0,
			"carboidrati": 31.9,
			"zuccheri": 31.9,
			"fibra_alimentare": 0.8
		},
		{
			"name": "marmellata di albicocche",
			"calorie": 242,
			"proteine": 0.7,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.01,
			"carboidrati": 63.2,
			"zuccheri": 63.2,
			"fibra_alimentare": 1.2
		},
		{
			"name": "marmellata di arance",
			"calorie": 261,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 69.5,
			"zuccheri": 69.5,
			"fibra_alimentare": 0.7
		},
		{
			"name": "marmellata di prugne",
			"calorie": 134,
			"proteine": 2.1,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.03,
			"carboidrati": 31.2,
			"zuccheri": 31.2,
			"fibra_alimentare": 2.6
		},
		{
			"name": "marsala all'uovo",
			"calorie": 150,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 12.4,
			"zuccheri": 12.4,
			"fibra_alimentare": 0
		},
		{
			"name": "marsala tipico",
			"calorie": 205,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 28,
			"zuccheri": 28,
			"fibra_alimentare": 0
		},
		{
			"name": "mascarpone",
			"calorie": 453,
			"proteine": 7.6,
			"grassi_totali": 47,
			"grassi_saturi": 29.17,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "mela",
			"calorie": 45,
			"proteine": 0.2,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.07,
			"carboidrati": 11,
			"zuccheri": 11,
			"fibra_alimentare": 2
		},
		{
			"name": "mela cotogna",
			"calorie": 34,
			"proteine": 0.3,
			"grassi_totali": 1,
			"grassi_saturi": 0.1,
			"carboidrati": 6.3,
			"zuccheri": 6.3,
			"fibra_alimentare": 5.9
		},
		{
			"name": "melagrane",
			"calorie": 63,
			"proteine": 0.5,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.03,
			"carboidrati": 15.9,
			"zuccheri": 15.9,
			"fibra_alimentare": 2.2
		},
		{
			"name": "melanzane",
			"calorie": 15,
			"proteine": 1.1,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 2.6,
			"zuccheri": 2.6,
			"fibra_alimentare": 2.6
		},
		{
			"name": "melanzane sott'olio",
			"calorie": 49,
			"proteine": 1.1,
			"grassi_totali": 4,
			"grassi_saturi": 0.46,
			"carboidrati": 2.5,
			"zuccheri": 2.5,
			"fibra_alimentare": 2.5
		},
		{
			"name": "melone",
			"calorie": 33,
			"proteine": 0.8,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 7.4,
			"zuccheri": 7.4,
			"fibra_alimentare": 0.9
		},
		{
			"name": "melù o pesce molo",
			"calorie": 72,
			"proteine": 17.4,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.06,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "menta fresca",
			"calorie": 43,
			"proteine": 3.8,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.12,
			"carboidrati": 5.3,
			"zuccheri": 5.3,
			"fibra_alimentare": 5.6
		},
		{
			"name": "menta secca",
			"calorie": 279,
			"proteine": 24.8,
			"grassi_totali": 4.6,
			"grassi_saturi": 0.76,
			"carboidrati": 34.6,
			"zuccheri": 34.6,
			"fibra_alimentare": 44.7
		},
		{
			"name": "merendine al cioccolato",
			"calorie": 352,
			"proteine": 6.5,
			"grassi_totali": 9.5,
			"grassi_saturi": 3.78,
			"carboidrati": 60,
			"zuccheri": 34,
			"fibra_alimentare": 2.8
		},
		{
			"name": "merendine con marmellata",
			"calorie": 359,
			"proteine": 5.5,
			"grassi_totali": 8.3,
			"grassi_saturi": 2.45,
			"carboidrati": 69.8,
			"zuccheri": 46.5,
			"fibra_alimentare": 1
		},
		{
			"name": "merendine farcite",
			"calorie": 414,
			"proteine": 6.2,
			"grassi_totali": 15.1,
			"grassi_saturi": 5.51,
			"carboidrati": 67.6,
			"zuccheri": 45.7,
			"fibra_alimentare": 1
		},
		{
			"name": "merendine tipo pan di spagna",
			"calorie": 463,
			"proteine": 8.3,
			"grassi_totali": 22.3,
			"grassi_saturi": 8.14,
			"carboidrati": 60.9,
			"zuccheri": 20.9,
			"fibra_alimentare": 2.5
		},
		{
			"name": "meringa",
			"calorie": 379,
			"proteine": 5.3,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 95.4,
			"zuccheri": 95.4,
			"fibra_alimentare": 0
		},
		{
			"name": "merluzzo",
			"calorie": 71,
			"proteine": 17,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.06,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "merluzzo surgelato",
			"calorie": 75,
			"proteine": 17.3,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.11,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "midollo osseo di bovino",
			"calorie": 822,
			"proteine": 3.2,
			"grassi_totali": 89.9,
			"grassi_saturi": 27.34,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "miele",
			"calorie": 303,
			"proteine": 0.6,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 80.3,
			"zuccheri": 80.3,
			"fibra_alimentare": 0
		},
		{
			"name": "milza di bovino",
			"calorie": 107,
			"proteine": 18.5,
			"grassi_totali": 3.7,
			"grassi_saturi": 1.57,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "mirtillo nero",
			"calorie": 30,
			"proteine": 0.6,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.02,
			"carboidrati": 6.9,
			"zuccheri": 6.9,
			"fibra_alimentare": 2.5
		},
		{
			"name": "miso",
			"calorie": 203,
			"proteine": 13.3,
			"grassi_totali": 6.2,
			"grassi_saturi": 0.89,
			"carboidrati": 23.5,
			"zuccheri": 0,
			"fibra_alimentare": 5.4
		},
		{
			"name": "montasio",
			"calorie": 411,
			"proteine": 30.3,
			"grassi_totali": 32.2,
			"grassi_saturi": 19.98,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "mora di gelso",
			"calorie": 36,
			"proteine": 1.3,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 8.1,
			"zuccheri": 8.1,
			"fibra_alimentare": 1.7
		},
		{
			"name": "mora di rovo",
			"calorie": 26,
			"proteine": 1.3,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 5.7,
			"zuccheri": 5.7,
			"fibra_alimentare": 7.3
		},
		{
			"name": "mortadella di bovino",
			"calorie": 388,
			"proteine": 13.3,
			"grassi_totali": 37,
			"grassi_saturi": 12.14,
			"carboidrati": 0.5,
			"zuccheri": 0.5,
			"fibra_alimentare": 0
		},
		{
			"name": "mortadella di suino",
			"calorie": 317,
			"proteine": 14.7,
			"grassi_totali": 28.1,
			"grassi_saturi": 9.22,
			"carboidrati": 1.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 0
		},
		{
			"name": "mozzarella",
			"calorie": 243,
			"proteine": 19.9,
			"grassi_totali": 16.1,
			"grassi_saturi": 10.01,
			"carboidrati": 4.9,
			"zuccheri": 4.9,
			"fibra_alimentare": 0
		},
		{
			"name": "mozzarella di bufala",
			"calorie": 240,
			"proteine": 15.7,
			"grassi_totali": 18.3,
			"grassi_saturi": 11.47,
			"carboidrati": 3.3,
			"zuccheri": 3.3,
			"fibra_alimentare": 0
		},
		{
			"name": "muesli",
			"calorie": 364,
			"proteine": 10.6,
			"grassi_totali": 5.9,
			"grassi_saturi": 1.04,
			"carboidrati": 71.1,
			"zuccheri": 47.4,
			"fibra_alimentare": 8.1
		},
		{
			"name": "natto",
			"calorie": 212,
			"proteine": 17.7,
			"grassi_totali": 11,
			"grassi_saturi": 1.59,
			"carboidrati": 9,
			"zuccheri": 0,
			"fibra_alimentare": 5.4
		},
		{
			"name": "nespole",
			"calorie": 28,
			"proteine": 0.4,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.08,
			"carboidrati": 6.1,
			"zuccheri": 6.1,
			"fibra_alimentare": 2.1
		},
		{
			"name": "nocciole",
			"calorie": 625,
			"proteine": 13,
			"grassi_totali": 62.9,
			"grassi_saturi": 4.08,
			"carboidrati": 1.8,
			"zuccheri": 1.8,
			"fibra_alimentare": 6.7
		},
		{
			"name": "noce di cocco",
			"calorie": 351,
			"proteine": 3.2,
			"grassi_totali": 36,
			"grassi_saturi": 30.93,
			"carboidrati": 3.7,
			"zuccheri": 3.7,
			"fibra_alimentare": 12.2
		},
		{
			"name": "noce moscata",
			"calorie": 395,
			"proteine": 8.8,
			"grassi_totali": 29,
			"grassi_saturi": 20.72,
			"carboidrati": 26.3,
			"zuccheri": 26.3,
			"fibra_alimentare": 23.7
		},
		{
			"name": "noci fresche",
			"calorie": 582,
			"proteine": 10.5,
			"grassi_totali": 57.7,
			"grassi_saturi": 4.72,
			"carboidrati": 5.5,
			"zuccheri": 3.4,
			"fibra_alimentare": 5.2
		},
		{
			"name": "noci secche",
			"calorie": 660,
			"proteine": 15.8,
			"grassi_totali": 63.7,
			"grassi_saturi": 5.21,
			"carboidrati": 6.3,
			"zuccheri": 3.9,
			"fibra_alimentare": 6.2
		},
		{
			"name": "nori secca",
			"calorie": 136,
			"proteine": 30.7,
			"grassi_totali": 1.5,
			"grassi_saturi": 0.33,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 44.4
		},
		{
			"name": "oca",
			"calorie": 373,
			"proteine": 15.8,
			"grassi_totali": 34.4,
			"grassi_saturi": 9.39,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di cocco",
			"calorie": 899,
			"proteine": 0,
			"grassi_totali": 99.9,
			"grassi_saturi": 86.8,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di colza",
			"calorie": 899,
			"proteine": 0,
			"grassi_totali": 99.9,
			"grassi_saturi": 6.31,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di germe di grano",
			"calorie": 899,
			"proteine": 0,
			"grassi_totali": 99.9,
			"grassi_saturi": 18.5,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di mais",
			"calorie": 899,
			"proteine": 0,
			"grassi_totali": 99.9,
			"grassi_saturi": 14.96,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di oliva",
			"calorie": 900,
			"proteine": 0,
			"grassi_totali": 100,
			"grassi_saturi": 16.16,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di oliva extravergine",
			"calorie": 900,
			"proteine": 0,
			"grassi_totali": 100,
			"grassi_saturi": 16.16,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di palma",
			"calorie": 899,
			"proteine": 0,
			"grassi_totali": 99.9,
			"grassi_saturi": 47.1,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di semi di arachidi",
			"calorie": 900,
			"proteine": 0,
			"grassi_totali": 100,
			"grassi_saturi": 19.39,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di semi di girasole",
			"calorie": 899,
			"proteine": 0,
			"grassi_totali": 99.9,
			"grassi_saturi": 11.24,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di semi di mais",
			"calorie": 899,
			"proteine": 0,
			"grassi_totali": 99.9,
			"grassi_saturi": 14.96,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di semi vari",
			"calorie": 900,
			"proteine": 0,
			"grassi_totali": 100,
			"grassi_saturi": 10.28,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di sesamo",
			"calorie": 898,
			"proteine": 0.2,
			"grassi_totali": 99.7,
			"grassi_saturi": 13.15,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di soia",
			"calorie": 899,
			"proteine": 0,
			"grassi_totali": 99.9,
			"grassi_saturi": 14.02,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olio di vinacciolo",
			"calorie": 899,
			"proteine": 0,
			"grassi_totali": 99.9,
			"grassi_saturi": 9.23,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "olive da tavola",
			"calorie": 268,
			"proteine": 1.5,
			"grassi_totali": 27,
			"grassi_saturi": 3.78,
			"carboidrati": 5,
			"zuccheri": 5,
			"fibra_alimentare": 2.4
		},
		{
			"name": "olive nere",
			"calorie": 234,
			"proteine": 1.6,
			"grassi_totali": 25.1,
			"grassi_saturi": 3.51,
			"carboidrati": 0.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 2.4
		},
		{
			"name": "olive verdi",
			"calorie": 142,
			"proteine": 0.8,
			"grassi_totali": 15,
			"grassi_saturi": 2.1,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 4.4
		},
		{
			"name": "ombrina surgelata",
			"calorie": 78,
			"proteine": 17.2,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.09,
			"carboidrati": 0.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 0
		},
		{
			"name": "orata surgelata",
			"calorie": 90,
			"proteine": 19.8,
			"grassi_totali": 1.2,
			"grassi_saturi": 0.15,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "origano fresco",
			"calorie": 66,
			"proteine": 2.2,
			"grassi_totali": 2,
			"grassi_saturi": 0.52,
			"carboidrati": 9.7,
			"zuccheri": 9.7,
			"fibra_alimentare": 8.4
		},
		{
			"name": "origano secco macinato",
			"calorie": 306,
			"proteine": 11,
			"grassi_totali": 10.3,
			"grassi_saturi": 2.67,
			"carboidrati": 49.5,
			"zuccheri": 49.5,
			"fibra_alimentare": 42.8
		},
		{
			"name": "ortica",
			"calorie": 36,
			"proteine": 5.9,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.09,
			"carboidrati": 1.3,
			"zuccheri": 1.3,
			"fibra_alimentare": 4.1
		},
		{
			"name": "orzo perlato",
			"calorie": 318,
			"proteine": 10.4,
			"grassi_totali": 1.4,
			"grassi_saturi": 0.29,
			"carboidrati": 70.5,
			"zuccheri": 0,
			"fibra_alimentare": 9.1
		},
		{
			"name": "orzo solubile",
			"calorie": 372,
			"proteine": 5.5,
			"grassi_totali": 3.4,
			"grassi_saturi": 0.63,
			"carboidrati": 80.9,
			"zuccheri": 0,
			"fibra_alimentare": 0.1
		},
		{
			"name": "orzo solubile",
			"calorie": 5,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0.01,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "ostrica",
			"calorie": 69,
			"proteine": 10.2,
			"grassi_totali": 0.9,
			"grassi_saturi": 0.17,
			"carboidrati": 5.4,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "palombo",
			"calorie": 80,
			"proteine": 16,
			"grassi_totali": 1.2,
			"grassi_saturi": 0.17,
			"carboidrati": 1.3,
			"zuccheri": 1.3,
			"fibra_alimentare": 0
		},
		{
			"name": "pan di spagna",
			"calorie": 314,
			"proteine": 8.5,
			"grassi_totali": 6.4,
			"grassi_saturi": 2.34,
			"carboidrati": 59.4,
			"zuccheri": 32.4,
			"fibra_alimentare": 1.2
		},
		{
			"name": "pancarrè americano comune",
			"calorie": 267,
			"proteine": 8.2,
			"grassi_totali": 3.6,
			"grassi_saturi": 0.81,
			"carboidrati": 46.9,
			"zuccheri": 3,
			"fibra_alimentare": 2.3
		},
		{
			"name": "pancetta affumicata",
			"calorie": 276,
			"proteine": 15.8,
			"grassi_totali": 23.6,
			"grassi_saturi": 8,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "pancetta coppata",
			"calorie": 394,
			"proteine": 20.9,
			"grassi_totali": 34.2,
			"grassi_saturi": 14,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 0
		},
		{
			"name": "pancetta di maiale",
			"calorie": 661,
			"proteine": 8.4,
			"grassi_totali": 69.3,
			"grassi_saturi": 24.3,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "pancetta magretta",
			"calorie": 314,
			"proteine": 21.7,
			"grassi_totali": 25.3,
			"grassi_saturi": 9.28,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "pancetta tesa",
			"calorie": 337,
			"proteine": 20.9,
			"grassi_totali": 28.1,
			"grassi_saturi": 9.61,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "pane al latte",
			"calorie": 347,
			"proteine": 8.6,
			"grassi_totali": 7.6,
			"grassi_saturi": 2.7,
			"carboidrati": 64.9,
			"zuccheri": 3.6,
			"fibra_alimentare": 3.1
		},
		{
			"name": "pane alle olive",
			"calorie": 286,
			"proteine": 7,
			"grassi_totali": 6.7,
			"grassi_saturi": 1.07,
			"carboidrati": 52.6,
			"zuccheri": 3.9,
			"fibra_alimentare": 3.8
		},
		{
			"name": "pane comune",
			"calorie": 279,
			"proteine": 8.1,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 64.7,
			"zuccheri": 0.6,
			"fibra_alimentare": 2.8
		},
		{
			"name": "pane condito",
			"calorie": 284,
			"proteine": 13.3,
			"grassi_totali": 3.4,
			"grassi_saturi": 0.99,
			"carboidrati": 56.9,
			"zuccheri": 1.2,
			"fibra_alimentare": 7.3
		},
		{
			"name": "pane di grano duro",
			"calorie": 245,
			"proteine": 10.1,
			"grassi_totali": 2.3,
			"grassi_saturi": 0.41,
			"carboidrati": 49.1,
			"zuccheri": 2.5,
			"fibra_alimentare": 9.8
		},
		{
			"name": "pane di grano e segale",
			"calorie": 226,
			"proteine": 6.2,
			"grassi_totali": 1.1,
			"grassi_saturi": 0.15,
			"carboidrati": 47.7,
			"zuccheri": 9.9,
			"fibra_alimentare": 4.6
		},
		{
			"name": "pane di segale",
			"calorie": 219,
			"proteine": 8.3,
			"grassi_totali": 1.7,
			"grassi_saturi": 0.32,
			"carboidrati": 45.8,
			"zuccheri": 1.8,
			"fibra_alimentare": 5.8
		},
		{
			"name": "pane di segale biscottato",
			"calorie": 284,
			"proteine": 9.4,
			"grassi_totali": 3.6,
			"grassi_saturi": 0.69,
			"carboidrati": 46.7,
			"zuccheri": 3.7,
			"fibra_alimentare": 6.4
		},
		{
			"name": "pane di soia",
			"calorie": 276,
			"proteine": 12,
			"grassi_totali": 6.8,
			"grassi_saturi": 1.04,
			"carboidrati": 41.7,
			"zuccheri": 3.3,
			"fibra_alimentare": 4.5
		},
		{
			"name": "pane integrale",
			"calorie": 243,
			"proteine": 7.5,
			"grassi_totali": 1.3,
			"grassi_saturi": 0.3,
			"carboidrati": 53.8,
			"zuccheri": 2.5,
			"fibra_alimentare": 5.7
		},
		{
			"name": "pane tipo 0",
			"calorie": 276,
			"proteine": 8.1,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.11,
			"carboidrati": 64,
			"zuccheri": 2,
			"fibra_alimentare": 3.8
		},
		{
			"name": "pane tipo 00",
			"calorie": 290,
			"proteine": 8.2,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.1,
			"carboidrati": 67.5,
			"zuccheri": 1.9,
			"fibra_alimentare": 3
		},
		{
			"name": "pane tipo 1",
			"calorie": 267,
			"proteine": 8.9,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.14,
			"carboidrati": 60.3,
			"zuccheri": 2.2,
			"fibra_alimentare": 3.8
		},
		{
			"name": "pane tostato",
			"calorie": 298,
			"proteine": 8.8,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.12,
			"carboidrati": 69.2,
			"zuccheri": 2.2,
			"fibra_alimentare": 4.1
		},
		{
			"name": "panettone",
			"calorie": 334,
			"proteine": 6.4,
			"grassi_totali": 10.7,
			"grassi_saturi": 5.57,
			"carboidrati": 56.5,
			"zuccheri": 22.9,
			"fibra_alimentare": 2.8
		},
		{
			"name": "pangrattato",
			"calorie": 354,
			"proteine": 11.6,
			"grassi_totali": 1.9,
			"grassi_saturi": 0.44,
			"carboidrati": 77.5,
			"zuccheri": 2.6,
			"fibra_alimentare": 3.4
		},
		{
			"name": "panini all'olio",
			"calorie": 302,
			"proteine": 7.7,
			"grassi_totali": 5.8,
			"grassi_saturi": 0.96,
			"carboidrati": 58.3,
			"zuccheri": 4.2,
			"fibra_alimentare": 3.7
		},
		{
			"name": "panna da cucina",
			"calorie": 206,
			"proteine": 2.8,
			"grassi_totali": 20,
			"grassi_saturi": 11.7,
			"carboidrati": 3.9,
			"zuccheri": 3.9,
			"fibra_alimentare": 0
		},
		{
			"name": "papaya",
			"calorie": 36,
			"proteine": 0.5,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.03,
			"carboidrati": 8.8,
			"zuccheri": 8.8,
			"fibra_alimentare": 2.3
		},
		{
			"name": "paprika",
			"calorie": 306,
			"proteine": 14.8,
			"grassi_totali": 13,
			"grassi_saturi": 2.11,
			"carboidrati": 34.8,
			"zuccheri": 34.8,
			"fibra_alimentare": 20.9
		},
		{
			"name": "parmigiano",
			"calorie": 374,
			"proteine": 36,
			"grassi_totali": 25.6,
			"grassi_saturi": 16.89,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "pasta di mandorle",
			"calorie": 467,
			"proteine": 8,
			"grassi_totali": 25.8,
			"grassi_saturi": 2.14,
			"carboidrati": 54.3,
			"zuccheri": 54.3,
			"fibra_alimentare": 7.2
		},
		{
			"name": "pasta di olive",
			"calorie": 331,
			"proteine": 1.4,
			"grassi_totali": 34.3,
			"grassi_saturi": 5.02,
			"carboidrati": 4.5,
			"zuccheri": 4.5,
			"fibra_alimentare": 2.2
		},
		{
			"name": "pasta di semola",
			"calorie": 356,
			"proteine": 10.8,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.07,
			"carboidrati": 82.8,
			"zuccheri": 2.7,
			"fibra_alimentare": 2.6
		},
		{
			"name": "pasta di semola integrale",
			"calorie": 324,
			"proteine": 13.4,
			"grassi_totali": 2.5,
			"grassi_saturi": 0.58,
			"carboidrati": 66.2,
			"zuccheri": 3.7,
			"fibra_alimentare": 11.5
		},
		{
			"name": "pasta fresca all'uovo",
			"calorie": 309,
			"proteine": 11.8,
			"grassi_totali": 2.8,
			"grassi_saturi": 0.79,
			"carboidrati": 63.2,
			"zuccheri": 1.7,
			"fibra_alimentare": 2.9
		},
		{
			"name": "pasta frolla",
			"calorie": 480,
			"proteine": 7.7,
			"grassi_totali": 24.3,
			"grassi_saturi": 10.25,
			"carboidrati": 61.3,
			"zuccheri": 25.8,
			"fibra_alimentare": 1.7
		},
		{
			"name": "pasta secca all'uovo",
			"calorie": 368,
			"proteine": 13,
			"grassi_totali": 2.4,
			"grassi_saturi": 0.67,
			"carboidrati": 78.6,
			"zuccheri": 2,
			"fibra_alimentare": 3.2
		},
		{
			"name": "pasta sfoglia",
			"calorie": 560,
			"proteine": 5.6,
			"grassi_totali": 40.6,
			"grassi_saturi": 15.77,
			"carboidrati": 45.9,
			"zuccheri": 0.9,
			"fibra_alimentare": 1.6
		},
		{
			"name": "pasticcini al cocco",
			"calorie": 404,
			"proteine": 3.6,
			"grassi_totali": 12.7,
			"grassi_saturi": 11.24,
			"carboidrati": 70.4,
			"zuccheri": 70.4,
			"fibra_alimentare": 1.8
		},
		{
			"name": "pastorella",
			"calorie": 364,
			"proteine": 26.9,
			"grassi_totali": 28.5,
			"grassi_saturi": 17.69,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "patate",
			"calorie": 85,
			"proteine": 2.1,
			"grassi_totali": 1,
			"grassi_saturi": 0.19,
			"carboidrati": 18,
			"zuccheri": 0.4,
			"fibra_alimentare": 1.6
		},
		{
			"name": "patate dolci",
			"calorie": 87,
			"proteine": 1.2,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.06,
			"carboidrati": 21.3,
			"zuccheri": 5.7,
			"fibra_alimentare": 2.3
		},
		{
			"name": "patate novelle",
			"calorie": 67,
			"proteine": 2,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 15.8,
			"zuccheri": 0.2,
			"fibra_alimentare": 1.4
		},
		{
			"name": "patatine in sacchetto",
			"calorie": 536,
			"proteine": 7,
			"grassi_totali": 34.6,
			"grassi_saturi": 10.96,
			"carboidrati": 51.2,
			"zuccheri": 0.7,
			"fibra_alimentare": 4.5
		},
		{
			"name": "pecorino",
			"calorie": 366,
			"proteine": 28.5,
			"grassi_totali": 28,
			"grassi_saturi": 17.79,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "pecorino da grattugiare",
			"calorie": 378,
			"proteine": 29.5,
			"grassi_totali": 28.9,
			"grassi_saturi": 18.36,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "pecorino fresco",
			"calorie": 332,
			"proteine": 26,
			"grassi_totali": 25.4,
			"grassi_saturi": 17.72,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "pecorino romano",
			"calorie": 379,
			"proteine": 28.3,
			"grassi_totali": 29.5,
			"grassi_saturi": 18.74,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "pepe nero",
			"calorie": 255,
			"proteine": 10.9,
			"grassi_totali": 3.3,
			"grassi_saturi": 0.98,
			"carboidrati": 38.3,
			"zuccheri": 38.3,
			"fibra_alimentare": 26.5
		},
		{
			"name": "peperoncini piccanti",
			"calorie": 25,
			"proteine": 1.8,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.09,
			"carboidrati": 3.8,
			"zuccheri": 1.5,
			"fibra_alimentare": 1.9
		},
		{
			"name": "peperoni dolci",
			"calorie": 22,
			"proteine": 0.9,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.05,
			"carboidrati": 4.2,
			"zuccheri": 4.2,
			"fibra_alimentare": 1.9
		},
		{
			"name": "peperoni gialli",
			"calorie": 22,
			"proteine": 0.9,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.05,
			"carboidrati": 5,
			"zuccheri": 4.9,
			"fibra_alimentare": 0.9
		},
		{
			"name": "peperoni sott'aceto",
			"calorie": 15,
			"proteine": 0.8,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.09,
			"carboidrati": 1.7,
			"zuccheri": 1.4,
			"fibra_alimentare": 1.9
		},
		{
			"name": "pera",
			"calorie": 41,
			"proteine": 0.3,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.02,
			"carboidrati": 9.5,
			"zuccheri": 9.5,
			"fibra_alimentare": 2.8
		},
		{
			"name": "pesca",
			"calorie": 27,
			"proteine": 0.8,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 6.1,
			"zuccheri": 6.1,
			"fibra_alimentare": 2.1
		},
		{
			"name": "pesca sciroppata",
			"calorie": 87,
			"proteine": 0.4,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 22.9,
			"zuccheri": 22.9,
			"fibra_alimentare": 1
		},
		{
			"name": "pesce persico",
			"calorie": 75,
			"proteine": 15.4,
			"grassi_totali": 1.5,
			"grassi_saturi": 0.3,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "pesce spada",
			"calorie": 109,
			"proteine": 16.9,
			"grassi_totali": 4.2,
			"grassi_saturi": 1.15,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "petto di faraona",
			"calorie": 107,
			"proteine": 25.1,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.23,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "petto di pollo",
			"calorie": 97,
			"proteine": 22.2,
			"grassi_totali": 0.9,
			"grassi_saturi": 0.25,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "petto di tacchino ",
			"calorie": 134,
			"proteine": 22,
			"grassi_totali": 4.9,
			"grassi_saturi": 1.59,
			"carboidrati": 0.4,
			"zuccheri": 0.4,
			"fibra_alimentare": 0
		},
		{
			"name": "piccione adulto",
			"calorie": 187,
			"proteine": 18.4,
			"grassi_totali": 12.5,
			"grassi_saturi": 3.27,
			"carboidrati": 0.3,
			"zuccheri": 0.3,
			"fibra_alimentare": 0
		},
		{
			"name": "piccione giovane",
			"calorie": 138,
			"proteine": 22.1,
			"grassi_totali": 5.5,
			"grassi_saturi": 1.44,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "pinoli",
			"calorie": 567,
			"proteine": 29.6,
			"grassi_totali": 47.8,
			"grassi_saturi": 7.35,
			"carboidrati": 5,
			"zuccheri": 5,
			"fibra_alimentare": 1.9
		},
		{
			"name": "piselli freschi",
			"calorie": 76,
			"proteine": 7,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.08,
			"carboidrati": 12.4,
			"zuccheri": 4.4,
			"fibra_alimentare": 5.2
		},
		{
			"name": "piselli in scatola",
			"calorie": 53,
			"proteine": 5.8,
			"grassi_totali": 0.8,
			"grassi_saturi": 0.29,
			"carboidrati": 6.8,
			"zuccheri": 2,
			"fibra_alimentare": 9.7
		},
		{
			"name": "piselli in scatola sgocciolati",
			"calorie": 53,
			"proteine": 5.8,
			"grassi_totali": 0.8,
			"grassi_saturi": 0.29,
			"carboidrati": 6.8,
			"zuccheri": 2,
			"fibra_alimentare": 9.7
		},
		{
			"name": "piselli secchi",
			"calorie": 306,
			"proteine": 21.7,
			"grassi_totali": 2,
			"grassi_saturi": 0.74,
			"carboidrati": 53.6,
			"zuccheri": 2.9,
			"fibra_alimentare": 15.7
		},
		{
			"name": "piselli surgelati",
			"calorie": 54,
			"proteine": 5.7,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.15,
			"carboidrati": 7.5,
			"zuccheri": 4.1,
			"fibra_alimentare": 7.8
		},
		{
			"name": "pistacchi freschi",
			"calorie": 390,
			"proteine": 12.8,
			"grassi_totali": 32.6,
			"grassi_saturi": 4.13,
			"carboidrati": 16.4,
			"zuccheri": 11.4,
			"fibra_alimentare": 1.5
		},
		{
			"name": "pistacchi secchi",
			"calorie": 577,
			"proteine": 20.6,
			"grassi_totali": 48.4,
			"grassi_saturi": 6.13,
			"carboidrati": 14,
			"zuccheri": 9.7,
			"fibra_alimentare": 10.8
		},
		{
			"name": "pistacchi tostati",
			"calorie": 601,
			"proteine": 17.9,
			"grassi_totali": 55.4,
			"grassi_saturi": 7.02,
			"carboidrati": 8.2,
			"zuccheri": 5.7,
			"fibra_alimentare": 6.1
		},
		{
			"name": "pizza con pomodoro",
			"calorie": 247,
			"proteine": 4,
			"grassi_totali": 4,
			"grassi_saturi": 0.68,
			"carboidrati": 51.9,
			"zuccheri": 12.6,
			"fibra_alimentare": 3.1
		},
		{
			"name": "pizza con pomodoro e mozzarella",
			"calorie": 271,
			"proteine": 5.6,
			"grassi_totali": 5.6,
			"grassi_saturi": 1.99,
			"carboidrati": 52.9,
			"zuccheri": 12.9,
			"fibra_alimentare": 3.8
		},
		{
			"name": "polenta",
			"calorie": 183,
			"proteine": 4.4,
			"grassi_totali": 1.4,
			"grassi_saturi": 0.17,
			"carboidrati": 40.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 1.3
		},
		{
			"name": "pollo intero",
			"calorie": 175,
			"proteine": 19.1,
			"grassi_totali": 11,
			"grassi_saturi": 3.65,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "polmone di bovino",
			"calorie": 75,
			"proteine": 14,
			"grassi_totali": 2.1,
			"grassi_saturi": 0.72,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "polpa di granchio in scatola",
			"calorie": 77,
			"proteine": 18.1,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.1,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "polpa secca di albicocche",
			"calorie": 188,
			"proteine": 4.8,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.05,
			"carboidrati": 43.4,
			"zuccheri": 43.4,
			"fibra_alimentare": 21.6
		},
		{
			"name": "polpa secca di mela ",
			"calorie": 243,
			"proteine": 0.9,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.05,
			"carboidrati": 57.2,
			"zuccheri": 57.2,
			"fibra_alimentare": 8.7
		},
		{
			"name": "polpo",
			"calorie": 57,
			"proteine": 10.6,
			"grassi_totali": 1,
			"grassi_saturi": 0.23,
			"carboidrati": 1.4,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "polpo surgelato",
			"calorie": 51,
			"proteine": 12.4,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "pomodori da insalata",
			"calorie": 17,
			"proteine": 1.2,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.03,
			"carboidrati": 2.8,
			"zuccheri": 2.8,
			"fibra_alimentare": 1.1
		},
		{
			"name": "pomodori maturi",
			"calorie": 19,
			"proteine": 1,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.03,
			"carboidrati": 3.5,
			"zuccheri": 3.5,
			"fibra_alimentare": 0.9
		},
		{
			"name": "pomodori pelati in scatola",
			"calorie": 21,
			"proteine": 1.2,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.07,
			"carboidrati": 3,
			"zuccheri": 3,
			"fibra_alimentare": 0.9
		},
		{
			"name": "pomodori secchi",
			"calorie": 258,
			"proteine": 14.1,
			"grassi_totali": 3,
			"grassi_saturi": 0.43,
			"carboidrati": 43.5,
			"zuccheri": 43.5,
			"fibra_alimentare": 12.3
		},
		{
			"name": "pomodori sott'olio",
			"calorie": 213,
			"proteine": 5.1,
			"grassi_totali": 14.1,
			"grassi_saturi": 1.89,
			"carboidrati": 17.5,
			"zuccheri": 17.5,
			"fibra_alimentare": 5.8
		},
		{
			"name": "pompelmo",
			"calorie": 26,
			"proteine": 0.6,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 6.2,
			"zuccheri": 6.2,
			"fibra_alimentare": 1.6
		},
		{
			"name": "pompelmo rosa",
			"calorie": 30,
			"proteine": 0.6,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 7.7,
			"zuccheri": 7.7,
			"fibra_alimentare": 1.6
		},
		{
			"name": "pop corn",
			"calorie": 383,
			"proteine": 12.7,
			"grassi_totali": 5,
			"grassi_saturi": 0.68,
			"carboidrati": 76.7,
			"zuccheri": 1.6,
			"fibra_alimentare": 2.2
		},
		{
			"name": "porri",
			"calorie": 29,
			"proteine": 2.1,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 5.2,
			"zuccheri": 5.2,
			"fibra_alimentare": 2.9
		},
		{
			"name": "prezzemolo fresco",
			"calorie": 20,
			"proteine": 3.7,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.1,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 5.6
		},
		{
			"name": "prezzemolo secco",
			"calorie": 181,
			"proteine": 15.8,
			"grassi_totali": 7,
			"grassi_saturi": 0.18,
			"carboidrati": 14.5,
			"zuccheri": 12.4,
			"fibra_alimentare": 44.7
		},
		{
			"name": "prosciutto cotto",
			"calorie": 215,
			"proteine": 19.8,
			"grassi_totali": 14.7,
			"grassi_saturi": 5.1,
			"carboidrati": 0.9,
			"zuccheri": 0.9,
			"fibra_alimentare": 0
		},
		{
			"name": "prosciutto cotto affumicato",
			"calorie": 215,
			"proteine": 19.8,
			"grassi_totali": 14.7,
			"grassi_saturi": 5.1,
			"carboidrati": 0.9,
			"zuccheri": 0.9,
			"fibra_alimentare": 0
		},
		{
			"name": "prosciutto cotto magro",
			"calorie": 132,
			"proteine": 22.2,
			"grassi_totali": 4.4,
			"grassi_saturi": 1.53,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "prosciutto crudo",
			"calorie": 370,
			"proteine": 22.2,
			"grassi_totali": 31.2,
			"grassi_saturi": 10.25,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "prosciutto crudo affumicato",
			"calorie": 218,
			"proteine": 28.6,
			"grassi_totali": 11.5,
			"grassi_saturi": 3.78,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "prosciutto crudo magro",
			"calorie": 218,
			"proteine": 28.6,
			"grassi_totali": 11.5,
			"grassi_saturi": 3.78,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "provola affumicata",
			"calorie": 260,
			"proteine": 21.2,
			"grassi_totali": 19.6,
			"grassi_saturi": 12.16,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "provoloncino dolce",
			"calorie": 348,
			"proteine": 25,
			"grassi_totali": 26.6,
			"grassi_saturi": 15.73,
			"carboidrati": 2.1,
			"zuccheri": 2.1,
			"fibra_alimentare": 0
		},
		{
			"name": "provolone",
			"calorie": 374,
			"proteine": 26.3,
			"grassi_totali": 28.9,
			"grassi_saturi": 17.09,
			"carboidrati": 2.1,
			"zuccheri": 2.1,
			"fibra_alimentare": 0
		},
		{
			"name": "provolone piccante",
			"calorie": 400,
			"proteine": 29.4,
			"grassi_totali": 30.4,
			"grassi_saturi": 17.98,
			"carboidrati": 2.1,
			"zuccheri": 2.1,
			"fibra_alimentare": 0
		},
		{
			"name": "prugne",
			"calorie": 42,
			"proteine": 0.5,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 10.5,
			"zuccheri": 10.5,
			"fibra_alimentare": 1.4
		},
		{
			"name": "prugne regina claudia",
			"calorie": 41,
			"proteine": 0.8,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 9.7,
			"zuccheri": 9.7,
			"fibra_alimentare": 2.3
		},
		{
			"name": "prugne secche",
			"calorie": 220,
			"proteine": 2.2,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.04,
			"carboidrati": 55,
			"zuccheri": 55,
			"fibra_alimentare": 9
		},
		{
			"name": "quaglia",
			"calorie": 161,
			"proteine": 25,
			"grassi_totali": 6.8,
			"grassi_saturi": 1.91,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "radicchio rosso",
			"calorie": 13,
			"proteine": 1.4,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 1.6,
			"zuccheri": 1.6,
			"fibra_alimentare": 3
		},
		{
			"name": "radicchio verde",
			"calorie": 14,
			"proteine": 1.9,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.12,
			"carboidrati": 0.5,
			"zuccheri": 0.5,
			"fibra_alimentare": 1
		},
		{
			"name": "rana",
			"calorie": 64,
			"proteine": 15.5,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.07,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "rape",
			"calorie": 18,
			"proteine": 1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 3.8,
			"zuccheri": 3.8,
			"fibra_alimentare": 2.6
		},
		{
			"name": "ravanelli",
			"calorie": 11,
			"proteine": 0.8,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.03,
			"carboidrati": 1.8,
			"zuccheri": 1.8,
			"fibra_alimentare": 1.3
		},
		{
			"name": "razza",
			"calorie": 68,
			"proteine": 14.2,
			"grassi_totali": 0.9,
			"grassi_saturi": 0,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 0
		},
		{
			"name": "regaglie di pollo",
			"calorie": 196,
			"proteine": 13.9,
			"grassi_totali": 15.6,
			"grassi_saturi": 4.75,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "rene di bovino",
			"calorie": 118,
			"proteine": 18.4,
			"grassi_totali": 4.6,
			"grassi_saturi": 1.93,
			"carboidrati": 0.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 0
		},
		{
			"name": "rhum",
			"calorie": 222,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "ribes nero",
			"calorie": 28,
			"proteine": 0.9,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 6.6,
			"zuccheri": 6.6,
			"fibra_alimentare": 7.8
		},
		{
			"name": "ribes rosso",
			"calorie": 21,
			"proteine": 1.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 4.4,
			"zuccheri": 4.4,
			"fibra_alimentare": 7.4
		},
		{
			"name": "ricotta di pecora",
			"calorie": 271,
			"proteine": 8.4,
			"grassi_totali": 25.1,
			"grassi_saturi": 17.51,
			"carboidrati": 3.2,
			"zuccheri": 3.2,
			"fibra_alimentare": 0
		},
		{
			"name": "ricotta di pecora",
			"calorie": 204,
			"proteine": 10.3,
			"grassi_totali": 16.6,
			"grassi_saturi": 11.24,
			"carboidrati": 3.6,
			"zuccheri": 3.6,
			"fibra_alimentare": 0
		},
		{
			"name": "ricotta di vacca",
			"calorie": 204,
			"proteine": 10.3,
			"grassi_totali": 16.6,
			"grassi_saturi": 11.24,
			"carboidrati": 3.6,
			"zuccheri": 3.6,
			"fibra_alimentare": 0
		},
		{
			"name": "ricotta intera",
			"calorie": 109,
			"proteine": 12.5,
			"grassi_totali": 5.1,
			"grassi_saturi": 3.17,
			"carboidrati": 3.6,
			"zuccheri": 3.6,
			"fibra_alimentare": 0
		},
		{
			"name": "ricotta magra",
			"calorie": 72,
			"proteine": 13.5,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.12,
			"carboidrati": 4.1,
			"zuccheri": 4.1,
			"fibra_alimentare": 0
		},
		{
			"name": "riso bianco",
			"calorie": 362,
			"proteine": 7,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.14,
			"carboidrati": 87.6,
			"zuccheri": 0.2,
			"fibra_alimentare": 1.4
		},
		{
			"name": "riso integrale sbramato",
			"calorie": 357,
			"proteine": 6.7,
			"grassi_totali": 2.8,
			"grassi_saturi": 0.56,
			"carboidrati": 81.3,
			"zuccheri": 1.3,
			"fibra_alimentare": 3.8
		},
		{
			"name": "riso parboiled",
			"calorie": 364,
			"proteine": 6.7,
			"grassi_totali": 1,
			"grassi_saturi": 0.27,
			"carboidrati": 79.3,
			"zuccheri": 0,
			"fibra_alimentare": 2.2
		},
		{
			"name": "riso soffiato",
			"calorie": 369,
			"proteine": 6.1,
			"grassi_totali": 0.9,
			"grassi_saturi": 0.2,
			"carboidrati": 89.7,
			"zuccheri": 10.6,
			"fibra_alimentare": 1.1
		},
		{
			"name": "robiola",
			"calorie": 309,
			"proteine": 18.9,
			"grassi_totali": 25.9,
			"grassi_saturi": 16.07,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "rosmarino fresco",
			"calorie": 96,
			"proteine": 1.4,
			"grassi_totali": 4.4,
			"grassi_saturi": 1.14,
			"carboidrati": 13.5,
			"zuccheri": 13.5,
			"fibra_alimentare": 7.7
		},
		{
			"name": "rosmarino secco",
			"calorie": 331,
			"proteine": 4.9,
			"grassi_totali": 15.2,
			"grassi_saturi": 3.94,
			"carboidrati": 46.4,
			"zuccheri": 46.4,
			"fibra_alimentare": 17.7
		},
		{
			"name": "rucola",
			"calorie": 25,
			"proteine": 2.6,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.09,
			"carboidrati": 2.1,
			"zuccheri": 2.1,
			"fibra_alimentare": 1.6
		},
		{
			"name": "salame cacciatore",
			"calorie": 424,
			"proteine": 28.5,
			"grassi_totali": 34,
			"grassi_saturi": 10.8,
			"carboidrati": 1.2,
			"zuccheri": 1.2,
			"fibra_alimentare": 0
		},
		{
			"name": "salame da cuocere",
			"calorie": 326,
			"proteine": 36,
			"grassi_totali": 19.6,
			"grassi_saturi": 6.23,
			"carboidrati": 1.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 0
		},
		{
			"name": "salame di bovino",
			"calorie": 454,
			"proteine": 27.4,
			"grassi_totali": 38.3,
			"grassi_saturi": 14.5,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "salame di suino",
			"calorie": 475,
			"proteine": 22.5,
			"grassi_totali": 42.8,
			"grassi_saturi": 13.6,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "salame fabriano",
			"calorie": 421,
			"proteine": 26.5,
			"grassi_totali": 34.3,
			"grassi_saturi": 12.19,
			"carboidrati": 1.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 0
		},
		{
			"name": "salame felino",
			"calorie": 375,
			"proteine": 30.5,
			"grassi_totali": 27.9,
			"grassi_saturi": 9.51,
			"carboidrati": 0.5,
			"zuccheri": 0.5,
			"fibra_alimentare": 0
		},
		{
			"name": "salame milano",
			"calorie": 390,
			"proteine": 26.7,
			"grassi_totali": 30.9,
			"grassi_saturi": 9.82,
			"carboidrati": 1.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 0
		},
		{
			"name": "salame napoli",
			"calorie": 358,
			"proteine": 26.7,
			"grassi_totali": 27.3,
			"grassi_saturi": 8.04,
			"carboidrati": 1.7,
			"zuccheri": 1.7,
			"fibra_alimentare": 0
		},
		{
			"name": "salame ungherese",
			"calorie": 406,
			"proteine": 24.2,
			"grassi_totali": 34,
			"grassi_saturi": 9.74,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 0
		},
		{
			"name": "salatini",
			"calorie": 514,
			"proteine": 7.2,
			"grassi_totali": 24.3,
			"grassi_saturi": 7.06,
			"carboidrati": 71.1,
			"zuccheri": 7.2,
			"fibra_alimentare": 3.3
		},
		{
			"name": "salmone",
			"calorie": 180,
			"proteine": 20.2,
			"grassi_totali": 11,
			"grassi_saturi": 1.9,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "salmone affumicato",
			"calorie": 142,
			"proteine": 25.4,
			"grassi_totali": 4.5,
			"grassi_saturi": 0.78,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "salmone in salamoia",
			"calorie": 188,
			"proteine": 21.1,
			"grassi_totali": 11.5,
			"grassi_saturi": 2.44,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "salsiccia di bovino fresca",
			"calorie": 394,
			"proteine": 14.2,
			"grassi_totali": 37.4,
			"grassi_saturi": 14.16,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "salsiccia di fegato",
			"calorie": 424,
			"proteine": 12.4,
			"grassi_totali": 41.2,
			"grassi_saturi": 12,
			"carboidrati": 0.9,
			"zuccheri": 0.9,
			"fibra_alimentare": 0
		},
		{
			"name": "salsiccia di suino fresca",
			"calorie": 304,
			"proteine": 15.4,
			"grassi_totali": 26.7,
			"grassi_saturi": 12.22,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "salsiccia di suino secca",
			"calorie": 514,
			"proteine": 22,
			"grassi_totali": 47.3,
			"grassi_saturi": 21.64,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "salvia fresca",
			"calorie": 119,
			"proteine": 3.9,
			"grassi_totali": 4.6,
			"grassi_saturi": 2.54,
			"carboidrati": 15.6,
			"zuccheri": 15.6,
			"fibra_alimentare": 14.7
		},
		{
			"name": "salvia secca macinata",
			"calorie": 315,
			"proteine": 10.6,
			"grassi_totali": 12.7,
			"grassi_saturi": 7.03,
			"carboidrati": 42.7,
			"zuccheri": 42.7,
			"fibra_alimentare": 18.1
		},
		{
			"name": "sangue di bovino",
			"calorie": 75,
			"proteine": 17.8,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.04,
			"carboidrati": 0.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 0
		},
		{
			"name": "sangue di suino",
			"calorie": 78,
			"proteine": 19,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.01,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "sarago",
			"calorie": 103,
			"proteine": 15,
			"grassi_totali": 4.4,
			"grassi_saturi": 0.57,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "sarda",
			"calorie": 129,
			"proteine": 20.8,
			"grassi_totali": 4.5,
			"grassi_saturi": 1.02,
			"carboidrati": 1.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 0
		},
		{
			"name": "sardine salate",
			"calorie": 133,
			"proteine": 25.1,
			"grassi_totali": 3.6,
			"grassi_saturi": 0.82,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "sardine sott'olio",
			"calorie": 198,
			"proteine": 22.3,
			"grassi_totali": 12.1,
			"grassi_saturi": 2.4,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "scalogno",
			"calorie": 20,
			"proteine": 1.5,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.03,
			"carboidrati": 3.3,
			"zuccheri": 3.3,
			"fibra_alimentare": 1.5
		},
		{
			"name": "scamorza",
			"calorie": 209,
			"proteine": 22.7,
			"grassi_totali": 10.1,
			"grassi_saturi": 6.28,
			"carboidrati": 7.2,
			"zuccheri": 7.2,
			"fibra_alimentare": 0
		},
		{
			"name": "scamorza affumicata",
			"calorie": 209,
			"proteine": 22.7,
			"grassi_totali": 10.1,
			"grassi_saturi": 6.28,
			"carboidrati": 7.2,
			"zuccheri": 7.2,
			"fibra_alimentare": 0
		},
		{
			"name": "scarola",
			"calorie": 15,
			"proteine": 1.6,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 1.7,
			"zuccheri": 1.7,
			"fibra_alimentare": 1.5
		},
		{
			"name": "schiena di capriolo",
			"calorie": 122,
			"proteine": 22.4,
			"grassi_totali": 3.6,
			"grassi_saturi": 1.41,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "sciroppo di malto",
			"calorie": 318,
			"proteine": 6.2,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 71.3,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "sciroppo per bibite",
			"calorie": 297,
			"proteine": 0.3,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 79,
			"zuccheri": 79,
			"fibra_alimentare": 0
		},
		{
			"name": "scorza di limone",
			"calorie": 69,
			"proteine": 1.5,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.04,
			"carboidrati": 16,
			"zuccheri": 16,
			"fibra_alimentare": 4.7
		},
		{
			"name": "scorzonera",
			"calorie": 27,
			"proteine": 1.3,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.07,
			"carboidrati": 10.2,
			"zuccheri": 1.5,
			"fibra_alimentare": 3.2
		},
		{
			"name": "sedano",
			"calorie": 20,
			"proteine": 2.3,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 2.4,
			"zuccheri": 2.2,
			"fibra_alimentare": 1.6
		},
		{
			"name": "sego di bue",
			"calorie": 872,
			"proteine": 0.8,
			"grassi_totali": 96.5,
			"grassi_saturi": 48.06,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "semi di cumino",
			"calorie": 375,
			"proteine": 17.8,
			"grassi_totali": 22.3,
			"grassi_saturi": 0.72,
			"carboidrati": 33.7,
			"zuccheri": 33.7,
			"fibra_alimentare": 10.5
		},
		{
			"name": "semi di finocchio",
			"calorie": 345,
			"proteine": 15.8,
			"grassi_totali": 14.9,
			"grassi_saturi": 0.48,
			"carboidrati": 12.5,
			"zuccheri": 12.5,
			"fibra_alimentare": 39.8
		},
		{
			"name": "semi di lino",
			"calorie": 373,
			"proteine": 24.5,
			"grassi_totali": 31,
			"grassi_saturi": 3,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 34.8
		},
		{
			"name": "semi di soia",
			"calorie": 398,
			"proteine": 36.9,
			"grassi_totali": 18.1,
			"grassi_saturi": 2.54,
			"carboidrati": 23.3,
			"zuccheri": 11,
			"fibra_alimentare": 11.9
		},
		{
			"name": "semola",
			"calorie": 341,
			"proteine": 11.5,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.12,
			"carboidrati": 77.6,
			"zuccheri": 1.9,
			"fibra_alimentare": 3.6
		},
		{
			"name": "seppia",
			"calorie": 72,
			"proteine": 14,
			"grassi_totali": 1.5,
			"grassi_saturi": 0.43,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 0
		},
		{
			"name": "seppia surgelata",
			"calorie": 63,
			"proteine": 14.1,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.11,
			"carboidrati": 0.7,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "sgombro o maccarello",
			"calorie": 168,
			"proteine": 17,
			"grassi_totali": 11.1,
			"grassi_saturi": 2.6,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "sgombro o maccarello in salamoia",
			"calorie": 177,
			"proteine": 19.3,
			"grassi_totali": 11.1,
			"grassi_saturi": 2.6,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "shoyu",
			"calorie": 53,
			"proteine": 5.2,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 7.7,
			"zuccheri": 0.8,
			"fibra_alimentare": 0.8
		},
		{
			"name": "soda dolce",
			"calorie": 22,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 5.8,
			"zuccheri": 5.8,
			"fibra_alimentare": 0
		},
		{
			"name": "sofficini al formaggio surgelati",
			"calorie": 278,
			"proteine": 7.2,
			"grassi_totali": 15.8,
			"grassi_saturi": 3.14,
			"carboidrati": 28.6,
			"zuccheri": 0.8,
			"fibra_alimentare": 1.4
		},
		{
			"name": "sogliola",
			"calorie": 86,
			"proteine": 16.9,
			"grassi_totali": 1.7,
			"grassi_saturi": 0.22,
			"carboidrati": 0.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 0
		},
		{
			"name": "sogliola surgelata",
			"calorie": 84,
			"proteine": 17.3,
			"grassi_totali": 1.3,
			"grassi_saturi": 0.17,
			"carboidrati": 0.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 0
		},
		{
			"name": "sottilette",
			"calorie": 330,
			"proteine": 20.8,
			"grassi_totali": 27,
			"grassi_saturi": 16.14,
			"carboidrati": 0.9,
			"zuccheri": 0.9,
			"fibra_alimentare": 0
		},
		{
			"name": "speck",
			"calorie": 301,
			"proteine": 28.3,
			"grassi_totali": 20.9,
			"grassi_saturi": 5.72,
			"carboidrati": 0.5,
			"zuccheri": 0.5,
			"fibra_alimentare": 0
		},
		{
			"name": "spigola",
			"calorie": 82,
			"proteine": 16.5,
			"grassi_totali": 1.5,
			"grassi_saturi": 0.24,
			"carboidrati": 0.6,
			"zuccheri": 0.6,
			"fibra_alimentare": 0
		},
		{
			"name": "spinaci",
			"calorie": 31,
			"proteine": 3.4,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.07,
			"carboidrati": 3,
			"zuccheri": 0.4,
			"fibra_alimentare": 1.9
		},
		{
			"name": "spinaci in scatola",
			"calorie": 19,
			"proteine": 2.8,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.08,
			"carboidrati": 0.8,
			"zuccheri": 0.8,
			"fibra_alimentare": 3.1
		},
		{
			"name": "spremuta di arancia",
			"calorie": 33,
			"proteine": 0.5,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 8.2,
			"zuccheri": 8.2,
			"fibra_alimentare": 0
		},
		{
			"name": "spremuta di pompelmo",
			"calorie": 30,
			"proteine": 0.3,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 7.9,
			"zuccheri": 7.9,
			"fibra_alimentare": 0
		},
		{
			"name": "spumanti aromatici",
			"calorie": 82,
			"proteine": 0.2,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 5.1,
			"zuccheri": 5.1,
			"fibra_alimentare": 0
		},
		{
			"name": "spumanti demitr",
			"calorie": 71,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 2.5,
			"zuccheri": 2.5,
			"fibra_alimentare": 0
		},
		{
			"name": "spumanti secchi",
			"calorie": 65,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 0
		},
		{
			"name": "stoccafisso ammollato",
			"calorie": 92,
			"proteine": 20.7,
			"grassi_totali": 0.9,
			"grassi_saturi": 0.13,
			"carboidrati": 0.3,
			"zuccheri": 0.3,
			"fibra_alimentare": 0
		},
		{
			"name": "stoccafisso secco",
			"calorie": 358,
			"proteine": 80.1,
			"grassi_totali": 3.5,
			"grassi_saturi": 0.68,
			"carboidrati": 1.1,
			"zuccheri": 1.1,
			"fibra_alimentare": 0
		},
		{
			"name": "stracchino",
			"calorie": 300,
			"proteine": 18.5,
			"grassi_totali": 25.1,
			"grassi_saturi": 15.58,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "strutto o sugna",
			"calorie": 892,
			"proteine": 0.3,
			"grassi_totali": 99,
			"grassi_saturi": 42.47,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "succo di albicocca",
			"calorie": 47,
			"proteine": 0.3,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 12,
			"zuccheri": 12,
			"fibra_alimentare": 0.2
		},
		{
			"name": "succo di ananas",
			"calorie": 56,
			"proteine": 0.3,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 13.6,
			"zuccheri": 13.6,
			"fibra_alimentare": 0.2
		},
		{
			"name": "succo di arancia concentrato",
			"calorie": 185,
			"proteine": 2.9,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.06,
			"carboidrati": 44.9,
			"zuccheri": 44.9,
			"fibra_alimentare": 0
		},
		{
			"name": "succo di arancia e pompelmo senza zucchero",
			"calorie": 43,
			"proteine": 0.6,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 10.2,
			"zuccheri": 10.2,
			"fibra_alimentare": 0.1
		},
		{
			"name": "succo di arancia senza zucchero",
			"calorie": 36,
			"proteine": 0.5,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 8.8,
			"zuccheri": 8.8,
			"fibra_alimentare": 0.1
		},
		{
			"name": "succo di barbabietola",
			"calorie": 14,
			"proteine": 0.8,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 2.9,
			"zuccheri": 2.9,
			"fibra_alimentare": 1.9
		},
		{
			"name": "succo di carote",
			"calorie": 24,
			"proteine": 0.5,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 5.7,
			"zuccheri": 5.7,
			"fibra_alimentare": 0.6
		},
		{
			"name": "succo di frutta",
			"calorie": 56,
			"proteine": 0.3,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 14.5,
			"zuccheri": 14.5,
			"fibra_alimentare": 0.2
		},
		{
			"name": "succo di limone conservato",
			"calorie": 21,
			"proteine": 0.4,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.04,
			"carboidrati": 6.1,
			"zuccheri": 6.1,
			"fibra_alimentare": 0.4
		},
		{
			"name": "succo di limone fresco",
			"calorie": 6,
			"proteine": 0.2,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 1.4,
			"zuccheri": 1.4,
			"fibra_alimentare": 0
		},
		{
			"name": "succo di mandarino",
			"calorie": 43,
			"proteine": 0.5,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.02,
			"carboidrati": 9.9,
			"zuccheri": 9.9,
			"fibra_alimentare": 0.2
		},
		{
			"name": "succo di mandarino senza zucchero",
			"calorie": 50,
			"proteine": 0.5,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.01,
			"carboidrati": 11.8,
			"zuccheri": 11.8,
			"fibra_alimentare": 0.2
		},
		{
			"name": "succo di mela senza zucchero",
			"calorie": 38,
			"proteine": 0.1,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 9.9,
			"zuccheri": 9.9,
			"fibra_alimentare": 0
		},
		{
			"name": "succo di melagrana",
			"calorie": 44,
			"proteine": 0.2,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 11.6,
			"zuccheri": 11.6,
			"fibra_alimentare": 0
		},
		{
			"name": "succo di pera",
			"calorie": 60,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 15.2,
			"zuccheri": 15.2,
			"fibra_alimentare": 0.6
		},
		{
			"name": "succo di pesca",
			"calorie": 54,
			"proteine": 0.3,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 13.3,
			"zuccheri": 13.3,
			"fibra_alimentare": 0.6
		},
		{
			"name": "succo di pomodoro conservato",
			"calorie": 14,
			"proteine": 0.8,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 3,
			"zuccheri": 3,
			"fibra_alimentare": 0.6
		},
		{
			"name": "succo di pompelmo senza zucchero",
			"calorie": 33,
			"proteine": 0.4,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 8.3,
			"zuccheri": 8.3,
			"fibra_alimentare": 0
		},
		{
			"name": "succo di pompelmo zuccherato",
			"calorie": 46,
			"proteine": 0.6,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 11,
			"zuccheri": 11,
			"fibra_alimentare": 0.1
		},
		{
			"name": "succo di spinaci",
			"calorie": 10,
			"proteine": 1.4,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.03,
			"carboidrati": 0.5,
			"zuccheri": 0.5,
			"fibra_alimentare": 0.9
		},
		{
			"name": "succo di uva senza zucchero",
			"calorie": 46,
			"proteine": 0.3,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.03,
			"carboidrati": 11.7,
			"zuccheri": 11.7,
			"fibra_alimentare": 0
		},
		{
			"name": "succo tropicale",
			"calorie": 47,
			"proteine": 0.2,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 11.6,
			"zuccheri": 11.6,
			"fibra_alimentare": 0
		},
		{
			"name": "surimi",
			"calorie": 99,
			"proteine": 15.2,
			"grassi_totali": 0.9,
			"grassi_saturi": 0.17,
			"carboidrati": 6.9,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "tacchino intero",
			"calorie": 146,
			"proteine": 21,
			"grassi_totali": 6.8,
			"grassi_saturi": 2.2,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "taleggio",
			"calorie": 306,
			"proteine": 20.6,
			"grassi_totali": 24.8,
			"grassi_saturi": 15.39,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "tamari salsa di soia",
			"calorie": 60,
			"proteine": 10.5,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 4.8,
			"zuccheri": 4.3,
			"fibra_alimentare": 0.8
		},
		{
			"name": "tapioca",
			"calorie": 363,
			"proteine": 0.6,
			"grassi_totali": 0.2,
			"grassi_saturi": 0.05,
			"carboidrati": 95.8,
			"zuccheri": 0,
			"fibra_alimentare": 0.4
		},
		{
			"name": "tartufo nero",
			"calorie": 31,
			"proteine": 6,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.11,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 8.5
		},
		{
			"name": "te'",
			"calorie": 0,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "te' in foglie",
			"calorie": 108,
			"proteine": 19.6,
			"grassi_totali": 2,
			"grassi_saturi": 0.44,
			"carboidrati": 3,
			"zuccheri": 3,
			"fibra_alimentare": 44.7
		},
		{
			"name": "tempeh",
			"calorie": 166,
			"proteine": 20.7,
			"grassi_totali": 6.4,
			"grassi_saturi": 0.92,
			"carboidrati": 6.4,
			"zuccheri": 0.9,
			"fibra_alimentare": 4.1
		},
		{
			"name": "timo fresco",
			"calorie": 95,
			"proteine": 3,
			"grassi_totali": 2.5,
			"grassi_saturi": 0.69,
			"carboidrati": 15.1,
			"zuccheri": 15.1,
			"fibra_alimentare": 12.3
		},
		{
			"name": "timo secco macinato",
			"calorie": 276,
			"proteine": 9.1,
			"grassi_totali": 7.4,
			"grassi_saturi": 2.73,
			"carboidrati": 45.3,
			"zuccheri": 45.3,
			"fibra_alimentare": 18.6
		},
		{
			"name": "tinca",
			"calorie": 76,
			"proteine": 17.9,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.1,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "tiramisu'",
			"calorie": 411,
			"proteine": 13.2,
			"grassi_totali": 25.8,
			"grassi_saturi": 14.32,
			"carboidrati": 33.5,
			"zuccheri": 21.1,
			"fibra_alimentare": 7.5
		},
		{
			"name": "tisana",
			"calorie": 1,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0.2,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "tofu",
			"calorie": 76,
			"proteine": 8.1,
			"grassi_totali": 4.8,
			"grassi_saturi": 0.07,
			"carboidrati": 0.7,
			"zuccheri": 0,
			"fibra_alimentare": 1.2
		},
		{
			"name": "tonno",
			"calorie": 158,
			"proteine": 21.5,
			"grassi_totali": 8,
			"grassi_saturi": 2.09,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "tonno in salamoia sgocciolato",
			"calorie": 103,
			"proteine": 25.1,
			"grassi_totali": 0.3,
			"grassi_saturi": 0.1,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "tonno sott'olio sgocciolato",
			"calorie": 258,
			"proteine": 22.8,
			"grassi_totali": 18.5,
			"grassi_saturi": 3.13,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "tordo",
			"calorie": 143,
			"proteine": 20.1,
			"grassi_totali": 7.7,
			"grassi_saturi": 2.33,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "torrone con mandorle",
			"calorie": 479,
			"proteine": 10.8,
			"grassi_totali": 26.8,
			"grassi_saturi": 2.23,
			"carboidrati": 52,
			"zuccheri": 52,
			"fibra_alimentare": 1.9
		},
		{
			"name": "torta al cioccolato",
			"calorie": 315,
			"proteine": 4.9,
			"grassi_totali": 17.5,
			"grassi_saturi": 6.97,
			"carboidrati": 36.8,
			"zuccheri": 27.6,
			"fibra_alimentare": 3.6
		},
		{
			"name": "torta margherita",
			"calorie": 368,
			"proteine": 8.9,
			"grassi_totali": 10.4,
			"grassi_saturi": 5.12,
			"carboidrati": 63.6,
			"zuccheri": 34.1,
			"fibra_alimentare": 1
		},
		{
			"name": "tortellini freschi",
			"calorie": 310,
			"proteine": 12.2,
			"grassi_totali": 7.1,
			"grassi_saturi": 3.19,
			"carboidrati": 52.6,
			"zuccheri": 2.3,
			"fibra_alimentare": 1.3
		},
		{
			"name": "tortellini secchi",
			"calorie": 389,
			"proteine": 15.7,
			"grassi_totali": 10.8,
			"grassi_saturi": 4.84,
			"carboidrati": 61,
			"zuccheri": 2.7,
			"fibra_alimentare": 2
		},
		{
			"name": "triglia",
			"calorie": 123,
			"proteine": 15.8,
			"grassi_totali": 6.2,
			"grassi_saturi": 1.62,
			"carboidrati": 1.1,
			"zuccheri": 1.1,
			"fibra_alimentare": 0
		},
		{
			"name": "trippa di bovino",
			"calorie": 108,
			"proteine": 15.8,
			"grassi_totali": 5,
			"grassi_saturi": 2.57,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "trota",
			"calorie": 86,
			"proteine": 14.7,
			"grassi_totali": 3,
			"grassi_saturi": 0.7,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "tuorlo d'uovo di gallina",
			"calorie": 355,
			"proteine": 16.3,
			"grassi_totali": 31.9,
			"grassi_saturi": 9.85,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 0
		},
		{
			"name": "uovo di anatra",
			"calorie": 190,
			"proteine": 12.2,
			"grassi_totali": 15.4,
			"grassi_saturi": 4.12,
			"carboidrati": 0.7,
			"zuccheri": 0.7,
			"fibra_alimentare": 0
		},
		{
			"name": "uovo di gallina intero",
			"calorie": 156,
			"proteine": 13,
			"grassi_totali": 11.1,
			"grassi_saturi": 3.31,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "uovo di gallina intero in polvere",
			"calorie": 574,
			"proteine": 47,
			"grassi_totali": 41.2,
			"grassi_saturi": 12.96,
			"carboidrati": 4.1,
			"zuccheri": 4.1,
			"fibra_alimentare": 0
		},
		{
			"name": "uovo di oca",
			"calorie": 189,
			"proteine": 13.8,
			"grassi_totali": 14.4,
			"grassi_saturi": 3.6,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "uovo di tacchina",
			"calorie": 147,
			"proteine": 12.8,
			"grassi_totali": 10.2,
			"grassi_saturi": 3.64,
			"carboidrati": 1,
			"zuccheri": 1,
			"fibra_alimentare": 0
		},
		{
			"name": "uva",
			"calorie": 61,
			"proteine": 0.5,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.03,
			"carboidrati": 15.6,
			"zuccheri": 15.6,
			"fibra_alimentare": 1.5
		},
		{
			"name": "uva secca",
			"calorie": 283,
			"proteine": 1.9,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.2,
			"carboidrati": 72,
			"zuccheri": 72,
			"fibra_alimentare": 6.8
		},
		{
			"name": "uva sultanina",
			"calorie": 283,
			"proteine": 1.9,
			"grassi_totali": 0.6,
			"grassi_saturi": 0.2,
			"carboidrati": 72,
			"zuccheri": 72,
			"fibra_alimentare": 6.8
		},
		{
			"name": "valeriana",
			"calorie": 21,
			"proteine": 2,
			"grassi_totali": 0.4,
			"grassi_saturi": 0.05,
			"carboidrati": 3.6,
			"zuccheri": 3.6,
			"fibra_alimentare": 1.5
		},
		{
			"name": "vaniglia estratto alcolico",
			"calorie": 288,
			"proteine": 0.1,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.01,
			"carboidrati": 12.7,
			"zuccheri": 12.7,
			"fibra_alimentare": 0
		},
		{
			"name": "verdura surgelata",
			"calorie": 64,
			"proteine": 3.3,
			"grassi_totali": 0.5,
			"grassi_saturi": 0.1,
			"carboidrati": 9.5,
			"zuccheri": 3.5,
			"fibra_alimentare": 4
		},
		{
			"name": "vermouth dolce",
			"calorie": 151,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 15.9,
			"zuccheri": 15.9,
			"fibra_alimentare": 0
		},
		{
			"name": "vermouth secco",
			"calorie": 109,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 3,
			"zuccheri": 3,
			"fibra_alimentare": 0
		},
		{
			"name": "vino bianco",
			"calorie": 71,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "vino rosato",
			"calorie": 71,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 2.5,
			"zuccheri": 2.5,
			"fibra_alimentare": 0
		},
		{
			"name": "vino rosso",
			"calorie": 76,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0.2,
			"zuccheri": 0.2,
			"fibra_alimentare": 0
		},
		{
			"name": "vodka",
			"calorie": 222,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "vongola",
			"calorie": 72,
			"proteine": 10.2,
			"grassi_totali": 2.5,
			"grassi_saturi": 0.24,
			"carboidrati": 2.2,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "vongole in scatola al naturale",
			"calorie": 148,
			"proteine": 25.6,
			"grassi_totali": 2,
			"grassi_saturi": 0.19,
			"carboidrati": 5.1,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "wakame secca",
			"calorie": 71,
			"proteine": 12.4,
			"grassi_totali": 2.4,
			"grassi_saturi": 0.49,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 47.1
		},
		{
			"name": "whisky",
			"calorie": 238,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 0.1,
			"zuccheri": 0.1,
			"fibra_alimentare": 0
		},
		{
			"name": "wurstel",
			"calorie": 270,
			"proteine": 13.7,
			"grassi_totali": 23.3,
			"grassi_saturi": 6.94,
			"carboidrati": 1.4,
			"zuccheri": 1.4,
			"fibra_alimentare": 0
		},
		{
			"name": "wurstel di pollo",
			"calorie": 257,
			"proteine": 12.9,
			"grassi_totali": 19.5,
			"grassi_saturi": 5.54,
			"carboidrati": 6.8,
			"zuccheri": 6.8,
			"fibra_alimentare": 0
		},
		{
			"name": "wurstel di tacchino",
			"calorie": 226,
			"proteine": 14.3,
			"grassi_totali": 17.7,
			"grassi_saturi": 5.89,
			"carboidrati": 1.5,
			"zuccheri": 1.5,
			"fibra_alimentare": 0
		},
		{
			"name": "yogurt di latte intero",
			"calorie": 63,
			"proteine": 3.5,
			"grassi_totali": 3.9,
			"grassi_saturi": 2.07,
			"carboidrati": 3.6,
			"zuccheri": 3.6,
			"fibra_alimentare": 0
		},
		{
			"name": "yogurt ai cereali e malto",
			"calorie": 95,
			"proteine": 3.5,
			"grassi_totali": 3.6,
			"grassi_saturi": 1.87,
			"carboidrati": 12.9,
			"zuccheri": 9,
			"fibra_alimentare": 0.2
		},
		{
			"name": "yogurt di latte intero alla frutta",
			"calorie": 88,
			"proteine": 2.8,
			"grassi_totali": 3.3,
			"grassi_saturi": 1.75,
			"carboidrati": 12.6,
			"zuccheri": 12.6,
			"fibra_alimentare": 0.2
		},
		{
			"name": "yogurt di latte magro alla frutta",
			"calorie": 90,
			"proteine": 4.1,
			"grassi_totali": 0.7,
			"grassi_saturi": 0.39,
			"carboidrati": 17.9,
			"zuccheri": 17.9,
			"fibra_alimentare": 0.2
		},
		{
			"name": "yogurt di latte parzialmente scremato",
			"calorie": 43,
			"proteine": 3.4,
			"grassi_totali": 1.7,
			"grassi_saturi": 0.95,
			"carboidrati": 3.8,
			"zuccheri": 3.8,
			"fibra_alimentare": 0
		},
		{
			"name": "yogurt di latte scremato",
			"calorie": 36,
			"proteine": 3.3,
			"grassi_totali": 0.9,
			"grassi_saturi": 0.5,
			"carboidrati": 4,
			"zuccheri": 4,
			"fibra_alimentare": 0
		},
		{
			"name": "zafferano",
			"calorie": 310,
			"proteine": 11.4,
			"grassi_totali": 5.9,
			"grassi_saturi": 1.59,
			"carboidrati": 61.5,
			"zuccheri": 42.4,
			"fibra_alimentare": 3.9
		},
		{
			"name": "zampone",
			"calorie": 360,
			"proteine": 19.1,
			"grassi_totali": 31.6,
			"grassi_saturi": 10.35,
			"carboidrati": 0,
			"zuccheri": 0,
			"fibra_alimentare": 0
		},
		{
			"name": "zucca gialla",
			"calorie": 18,
			"proteine": 1.1,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.05,
			"carboidrati": 3.5,
			"zuccheri": 2.5,
			"fibra_alimentare": 0.5
		},
		{
			"name": "zucchero (saccarosio)",
			"calorie": 392,
			"proteine": 0,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 104.5,
			"zuccheri": 104.5,
			"fibra_alimentare": 0
		},
		{
			"name": "zucchero di canna",
			"calorie": 362,
			"proteine": 0.1,
			"grassi_totali": 0,
			"grassi_saturi": 0,
			"carboidrati": 101.3,
			"zuccheri": 101.3,
			"fibra_alimentare": 0
		},
		{
			"name": "zucchine",
			"calorie": 11,
			"proteine": 1.3,
			"grassi_totali": 0.1,
			"grassi_saturi": 0.02,
			"carboidrati": 1.4,
			"zuccheri": 1.3,
			"fibra_alimentare": 1.3
		},
		{
			"name": "piadina",
			"calorie": 338,
			"proteine": 8.4,
			"grassi_totali": 11.7,
			"grassi_saturi": 5.2,
			"carboidrati": 53,
			"zuccheri": 2.8,
			"fibra_alimentare": 2.8
		},
		{
			"name": "gallette di riso",
			"calorie": 392,
			"proteine": 7.1,
			"grassi_totali": 1.9,
			"grassi_saturi": 0.6,
			"carboidrati": 81.1,
			"zuccheri": 0.9,
			"fibra_alimentare": 4.2
		}
	]
}