import React, { useRef } from "react";

//IMPORTING STYLE
import "../styles/DietPage.css"

//IMPORTING ICONS
import delete_food from "../icons/delete_food.png"

//IMPORTING FOOD
import foodList from '../food_list';

export default function DietPageLogged(props) {
    //_________________________________DECLARING_REFS_________________________________
    const areFoodsLoaded = useRef(false)
    const once1 = useRef(false)
    const updateValuesNeeded = useRef(false)

    //_________________________________DECLARING_STATES_________________________________
    //FOOD NAME
    const [foodName, setFoodName] = React.useState("")
    //FOOD QUANTITY
    const [foodQuantity, setFoodQuantity] = React.useState("")
    //FOODS ARRAY
    const [foodsEaten, updateFoodsEaten] = React.useState([])
    //NUTRITIONAL VALUES
    const [food_values, setFoodValues] = React.useState({
        calorie: 0,
        proteine: 0,
        grassi_totali: 0,
        grassi_saturi: 0,
        carboidrati: 0,
        zuccheri: 0,
        fibra_alimentare: 0
    })

    //_________________________________CREATING_PAGE_COMPONENTS_________________________________
    //CREATING FOODS IN THE INPUT DROPDOWN
    let foodsDropdown = 0;
    const createFoods = foodList.foods.map(food => {
        if (food.name.includes(foodName.toLowerCase()) && (food.name != foodName)) {
            return (
                <div id="food" key={foodsDropdown++} onClick={() => foodClicked(food.name)}>
                    <p>{food.name}</p>
                </div>
            )
        }
    })

    //CREATING FOODS EATEN
    let key = 0
    const createFoodsEaten = foodsEaten.map((food) => {
        return (
            <section id="food_section" key={key++}>
                <p id="food_name">{food.name}</p>
                <p id="food_quantity">{food.quantita}g</p>
                <button
                    id="delete_food_button"
                    onClick={() => removeFood(food)}>
                    <img id="delete_food_icon" src={delete_food} draggable="false" />
                </button>
            </section>
        )
    })

    //FOOD LIST EMPTY ELEMENT
    function emptyFoodList() {
        return (
            <section id="no_foods_yet">
                <button onClick={foodInputFocus}>Inserisci un alimento</button>
            </section>
        )
    }

    //_________________________________DECLARING_FUNCTIONS_________________________________
    //LOADING FOODS FROM DATABASE
    function loadFoods() {
        const httprLoadFoods = new XMLHttpRequest()
        httprLoadFoods.onreadystatechange = () => {
            if (httprLoadFoods.readyState == 4 && httprLoadFoods.status == 200) {
                if (httprLoadFoods.response != "") {
                    let foods_array = JSON.parse(httprLoadFoods.response)
                    updateFoodsEaten(() => {
                        let temp_array_foods = []
                        foods_array.map((foodObj) => {
                            temp_array_foods.push({
                                id: foodObj.idAlimento,
                                name: foodObj.name,
                                quantita: parseFloat(foodObj.weight),
                                calorie: parseFloat(foodObj.calories),
                                proteine: parseFloat(foodObj.protein),
                                grassi_totali: parseFloat(foodObj.totalFat),
                                grassi_saturi: parseFloat(foodObj.saturatedFat),
                                carboidrati: parseFloat(foodObj.carbohydrate),
                                zuccheri: parseFloat(foodObj.sugar),
                                fibra_alimentare: parseFloat(foodObj.dietaryFiber)
                            })
                        })

                        return [...temp_array_foods]
                    })

                    updateValuesNeeded.current = true
                    areFoodsLoaded.current = true
                } else {
                    return
                }
            }
        }
        httprLoadFoods.open("POST", "php/loadFoods.php", true)
        httprLoadFoods.send()
    }

    //ADDING FOOD
    function addFood() {
        if (foodName != "" && foodQuantity != "") {
            let foodIndex = -1
            for (let i = 0; i < foodList.foods.length; i++) {
                if (foodList.foods[i].name === foodName) {
                    foodIndex = i
                    break
                }
            }
            if (foodIndex != -1) {
                let food_temp = foodList.foods[foodIndex]
                food_temp = {
                    ...food_temp,
                    "quantita": foodQuantity,
                    "calorie": (food_temp.calorie * foodQuantity) / 100,
                    "proteine": (food_temp.proteine * foodQuantity) / 100,
                    "grassi_totali": (food_temp.grassi_totali * foodQuantity) / 100,
                    "grassi_saturi": (food_temp.grassi_saturi * foodQuantity) / 100,
                    "carboidrati": (food_temp.carboidrati * foodQuantity) / 100,
                    "zuccheri": (food_temp.zuccheri * foodQuantity) / 100,
                    "fibra_alimentare": (food_temp.fibra_alimentare * foodQuantity) / 100
                }

                //SENDING THE NEW FOOD ADDED TO addFood.php
                const httprAddFood = new XMLHttpRequest()
                httprAddFood.onreadystatechange = () => {
                    if (httprAddFood.readyState == 4 && httprAddFood.status == 200) {
                        updateFoodsEaten((prevArray) => {
                            if (prevArray.length == 0) {
                                return [
                                    {
                                        id: httprAddFood.response,
                                        ...food_temp
                                    }
                                ]
                            }
                            return [
                                ...prevArray,
                                {
                                    id: httprAddFood.response,
                                    ...food_temp
                                }
                            ]
                        })
                        toggleDropdownCloseFunction()
                        updateValuesNeeded.current = true
                    }
                }
                httprAddFood.open("POST", "php/addFood.php", true)
                httprAddFood.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                httprAddFood.send(`alimento=${JSON.stringify(food_temp)}`)

                setFoodName("")
                setFoodQuantity("")
            }
        } else {
            if (foodName == "") document.getElementById("add_food_name_input").className = "add_food_name_input_invalid"
            if (foodQuantity == "") document.getElementById("add_food_quantity_input").className = "add_food_quantity_input_invalid"
        }
    }

    //REMOVE FOOD
    function removeFood(foodParam) {
        const httprRemoveFood = new XMLHttpRequest()
        httprRemoveFood.onreadystatechange = () => {
            if (httprRemoveFood.readyState == 4 && httprRemoveFood.status == 200) {

            }
        }
        httprRemoveFood.open("POST", "php/removeFood.php", true)
        httprRemoveFood.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        httprRemoveFood.send(`id=${foodParam.id}`)

        //REMOVE FOOD FROM LOCAL ARRAY
        updateFoodsEaten((prevArray) => {
            let index = -1;
            for (let i = 0; i < prevArray.length; i++) {
                if (prevArray[i].id == foodParam.id) {
                    index = i
                    break
                }
            }

            prevArray.splice(index, 1)
            return [...prevArray]
        })
        updateValuesNeeded.current = true
    }

    //FOOD NAME INPUT FOCUS
    function hasFocus() {
        document.getElementById("add_food_name_input").className = "add_food_name_input_valid"
        if (foodsDropdown != 0) {
            if (!document.getElementById("add_food_container_cover").classList.contains("add_food_container_cover_on")) {
                document.getElementById("add_food_container_cover").classList.toggle("add_food_container_cover_on")
                document.getElementById("add_food_dropdown").className = "add_food_dropdown_on"
            }
        }
    }

    //FOODS DROPDOWN FOOD CLICK
    function foodClicked(name) {
        setFoodName(name)
        document.getElementById("add_food_container_cover").classList.toggle("add_food_container_cover_on")
        document.getElementById("add_food_dropdown").className = "add_food_dropdown_off"
        document.getElementById("add_food_name_input").style.color = "green"
    }

    //HANDLING FOOD NAME INPUT CHANGE
    function foodInput(e) {
        let foodsArray = foodList.foods
        let foodsFound = 0
        let onlyOne = false

        for (let i = 0; i < foodsArray.length; i++) {
            if (foodsArray[i].name.includes(e.target.value.toLowerCase())) {
                foodsFound++
                if (foodsArray[i].name === e.target.value.toLowerCase()) {
                    onlyOne = true
                }
            }
        }
        if (foodsFound > 0) {
            document.getElementById("add_food_dropdown").className = "add_food_dropdown_on"
            if (!document.getElementById("add_food_container_cover").classList.contains("add_food_container_cover_on")) {
                document.getElementById("add_food_container_cover").classList.toggle("add_food_container_cover_on")
            }
            document.getElementById("add_food_name_input").style.color = "black"
        } else {
            if (document.getElementById("add_food_container_cover").classList.contains("add_food_container_cover_on")) {
                document.getElementById("add_food_container_cover").classList.toggle("add_food_container_cover_on")
            }
            document.getElementById("add_food_dropdown").className = "add_food_dropdown_off"
            document.getElementById("add_food_name_input").style.color = "red"
        }

        if (onlyOne && foodsFound === 1) {
            document.getElementById("add_food_dropdown").className = "add_food_dropdown_off"
            if (document.getElementById("add_food_container_cover").classList.contains("add_food_container_cover_on")) {
                document.getElementById("add_food_container_cover").classList.toggle("add_food_container_cover_on")
            }
            document.getElementById("add_food_name_input").style.color = "green"
        } else if (onlyOne && foodsFound > 0) {
            document.getElementById("add_food_dropdown").className = "add_food_dropdown_on"
            if (!document.getElementById("add_food_container_cover").classList.contains("add_food_container_cover_on")) {
                document.getElementById("add_food_container_cover").classList.toggle("add_food_container_cover_on")
            }
            document.getElementById("add_food_name_input").style.color = "green"
        }
        setFoodName(e.target.value)
    }

    function quantityInput(e) {
        if (((e.target.value > 0 && e.target.value < 999)) || e.target.value === "") {
            setFoodQuantity(e.target.value)
        }
    }

    //SET THE FOCUS ON THE FOOD INPUT
    function foodInputFocus() {
        document.getElementById("add_food_name_input").focus()
    }

    function toggleDropdownCloseFunction() {
        if (document.getElementById("add_food_container_cover").classList.contains("add_food_container_cover_on")) {
            document.getElementById("add_food_container_cover").classList.toggle("add_food_container_cover_on")
            document.getElementById("add_food_dropdown").className = "add_food_dropdown_off"
        }
    }

    //CALCULATING OBJECTIVES
    const obbiettivo_calorie = 2500
    const obbiettivo_proteine = (obbiettivo_calorie * 12) / 100
    const obbiettivo_grassi_totali = (obbiettivo_calorie * 25) / 100
    const obbiettivo_grassi_saturi = (obbiettivo_calorie * 10) / 100
    const obbiettivo_carboidrati = (obbiettivo_calorie * 63) / 100
    const obbiettivo_zuccheri = (obbiettivo_calorie * 15) / 100
    const obbiettivo_fibra_alimentare = 25

    //CALCULATING PERCENTAGES
    let percentage_calories = Math.round(((food_values.calorie * 100) / obbiettivo_calorie) * 10) / 10
    let percentage_proteine = Math.round(((food_values.proteine * 100) / obbiettivo_proteine) * 10) / 10
    let percentage_grassi_totali = Math.round(((food_values.grassi_totali * 100) / obbiettivo_grassi_totali) * 10) / 10
    let percentage_grassi_saturi = Math.round(((food_values.grassi_saturi * 100) / obbiettivo_grassi_saturi) * 10) / 10
    let percentage_carboidrati = Math.round(((food_values.carboidrati * 100) / obbiettivo_carboidrati) * 10) / 10
    let percentage_zuccheri = Math.round(((food_values.zuccheri * 100) / obbiettivo_zuccheri) * 10) / 10

    //CREATING AN ARRAY THAT HOLDS THE VALUES PERCENTAGE
    let values_percentage = {}
    if (foodsEaten.length != 0) {
        values_percentage = {
            calorie: percentage_calories,
            proteine: percentage_proteine,
            grassi_totali: percentage_grassi_totali,
            grassi_saturi: percentage_grassi_saturi,
            carboidrati: percentage_carboidrati,
            zuccheri: percentage_zuccheri,
            fibra_alimentare: Math.round(((food_values.fibra_alimentare * 100) / obbiettivo_fibra_alimentare) * 100) / 100
        }
    } else {
        values_percentage = {
            calorie: 0,
            proteine: 0,
            grassi_totali: 0,
            grassi_saturi: 0,
            carboidrati: 0,
            zuccheri: 0,
            fibra_alimentare: 0
        }
    }

    //UPDATE VALUES IF NEEDED
    if (updateValuesNeeded.current) {
        setFoodValues((prevValues) => {
            //RESET VALUES
            prevValues.calorie = 0
            prevValues.proteine = 0
            prevValues.grassi_totali = 0
            prevValues.grassi_saturi = 0
            prevValues.carboidrati = 0
            prevValues.zuccheri = 0
            prevValues.fibra_alimentare = 0

            //ITERATING THE FOODS EATEN AND CALCULATING THE VALUES
            foodsEaten.map((food) => {
                prevValues.calorie = Math.round((prevValues.calorie + food.calorie) * 10) / 10
                prevValues.proteine = (Math.round((prevValues.proteine + food.proteine * 4) * 10) / 10)
                prevValues.grassi_totali = (Math.round((prevValues.grassi_totali + food.grassi_totali * 9) * 10) / 10)
                prevValues.grassi_saturi = Math.round((prevValues.grassi_saturi + food.grassi_saturi * 9) * 10) / 10
                prevValues.carboidrati = (Math.round((prevValues.carboidrati + food.carboidrati * 4) * 10) / 10)
                prevValues.zuccheri = Math.round((prevValues.zuccheri + food.zuccheri * 4) * 10) / 10
                prevValues.fibra_alimentare = Math.round((prevValues.fibra_alimentare + food.fibra_alimentare) * 10) / 10
            })
            return prevValues
        })
        updateValuesNeeded.current = false
    }

    //LOADING THE FOODS
    if (!areFoodsLoaded.current) loadFoods()


    //_________________________________RENDERING_COMPONENT_________________________________
    return (
        <div id="diet_page">
            <div id="add_food_container">
                <div id="add_food_input_container">
                    <input
                        id="add_food_name_input"
                        className="add_food_name_input_valid"
                        type="text"
                        placeholder="Inserisci Alimento"
                        value={foodName}

                        onChange={(e) => foodInput(e)}
                        onFocus={hasFocus}
                    />
                    <input
                        id="add_food_quantity_input"
                        className="add_food_quantity_input_valid"
                        type="number"
                        placeholder="Grammi (g)"
                        value={foodQuantity}
                        onChange={(e) => quantityInput(e)}
                        onFocus={() => document.getElementById("add_food_quantity_input").className = "add_food_quantity_input_valid"}
                    />
                </div>
                <button id="add_food_button" onClick={addFood}>Aggiungi</button>
                <div id="add_food_dropdown" className="add_food_dropdown_off">
                    {createFoods}
                </div>
            </div>
            <div id="diet_page_container">
                <div id="foods_eaten_list">
                    {foodsEaten.length > 0 ? createFoodsEaten : emptyFoodList()}
                </div>
                <div id="foods_eaten_graph">
                    <table id="foods_eaten_graph_table">
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[0]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: values_percentage.calorie + "%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">{food_values.calorie} Kcal</p>
                            </td>
                            {
                                <td>
                                    <p id="value_percentage">{values_percentage.calorie}%</p>
                                </td>
                            }
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[1]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: values_percentage.proteine + "%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">{food_values.proteine} Kcal</p>
                            </td>
                            {
                                <td>
                                    <p id="value_percentage">{values_percentage.proteine}%</p>
                                </td>
                            }
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[2]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: values_percentage.grassi_totali + "%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">{food_values.grassi_totali} Kcal</p>
                            </td>
                            {
                                <td>
                                    <p id="value_percentage">{values_percentage.grassi_totali}%</p>
                                </td>
                            }
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[3]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: values_percentage.grassi_saturi + "%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">{food_values.grassi_saturi} Kcal</p>
                            </td>
                            {
                                <td>
                                    <p id="value_percentage">{values_percentage.grassi_saturi}%</p>
                                </td>
                            }
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[4]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: values_percentage.carboidrati + "%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">{food_values.carboidrati} Kcal</p>
                            </td>
                            {
                                <td>
                                    <p id="value_percentage">{values_percentage.carboidrati}%</p>
                                </td>
                            }
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[5]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: values_percentage.zuccheri + "%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">{food_values.zuccheri} Kcal</p>
                            </td>
                            {
                                <td>
                                    <p id="value_percentage">{values_percentage.zuccheri}%</p>
                                </td>
                            }
                        </tr>
                        <tr>
                            <td>
                                <p id="value_name">{foodList.value_names[6]}</p>
                            </td>
                            <td>
                                <section id="value_bar_container">
                                    <section id="value_bar" style={{ width: values_percentage.fibra_alimentare + "%" }}></section>
                                </section>
                            </td>
                            <td>
                                <p id="value_quantity">{food_values.fibra_alimentare} g</p>
                            </td>
                            {
                                <td>
                                    <p id="value_percentage">{values_percentage.fibra_alimentare}%</p>
                                </td>
                            }
                        </tr>
                    </table>
                </div>
            </div>
            <div id="add_food_container_cover" onClick={toggleDropdownCloseFunction} onTouchStart={toggleDropdownCloseFunction}></div>
        </div>
    )
}