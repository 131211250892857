import React, { useEffect } from 'react';

//IMPORTING STYLE
import './styles/App.css';

//IMPORTING COMPONENTS
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import MainPage from './components/MainPage';
import LoginTab from './components/LoginTab';
import Footer from './components/Footer';

function App() {
  //_______________________________________DECLARING_EFFECTS_______________________________________
  useEffect(() => {
    document.body.scrollIntoView({ block: "start", inline: "nearest" })
    logInWithCookies()
  }, [])


  //_______________________________________DECLARING_STATES_______________________________________
  const [logged, changeLogged] = React.useState(false)
  const [loginTabShown, toggleLoginTab] = React.useState(false)
  const [pageName, setPage] = React.useState("home-page")
  const [user, setUser] = React.useState({})


  //_______________________________________DECLARING_FUNCTIONS_______________________________________
  //LOG IN AUTOMATICALLY IN CASE COOKIES WERE FOUND
  function logInWithCookies() {
    const httpr = new XMLHttpRequest()
    httpr.onreadystatechange = function () {
      if (httpr.readyState == 4 && httpr.status == 200) {
        switch (httpr.response) {
          case "not_set":
            return
          case "remember_not_match":
            //IF REMEMBER DOESN'T MATCH CLEAR COOKIE
            console.log("Remember code doesn't match")
            const clearCookieRequest = new XMLHttpRequest()
            clearCookieRequest.onreadystatechange = () => {
              if (clearCookieRequest.readyState == 4 && clearCookieRequest.status == 200) {
                //REQUEST RESPONSE
                console.log(clearCookieRequest.response)
              }
            }
            clearCookieRequest.open("POST", "php/clearCookie.php", true)
            clearCookieRequest.send()
            return
          default:
            if (httpr.responseText.substring(0, 5) === "Error") {
              console.log(httpr.response)
              return
            }
            changeLoggedState()
            changeUser(JSON.parse(httpr.response))
            return
        }

      }

    }
    httpr.open("POST", "php/checkCookie.php", true)
    httpr.send()
  }

  //TOOGLE THE LOGIN STATE
  function changeLoggedState() {
    changeLogged(prevValue => !prevValue)
  }

  //SHOW - HIDE THE LOGIN TAB
  function toggleLoginTabFunction() {
    toggleLoginTab(prevValue => !prevValue)
  }

  //CHANGE THE ACTIVE PAGE
  function changePage(pageName) {
    setPage(pageName)
    document.getElementById("side_menu").className = "side_menu_off"
    document.getElementById("header_account_button").disabled = false
  }

  //CHANGE THE USER INFOS
  function changeUser(obj) {
    let weight = obj.weight
    let height = obj.height / 100
    //CALCULATING BMI VALUE
    let bmiValue = Math.round((weight / Math.pow(height, 2)) * 100) / 100
    //CALCULATING AGE
    const now = new Date();
    const birthDayDate = new Date(obj.bday)
    const diff = Math.abs(now - birthDayDate);
    const user_age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

    setUser(prevUser => ({
      ...prevUser,
      fName: obj.firstName,
      lName: obj.lastName,
      birthday: obj.bday,
      age: user_age,
      weight: obj.weight,
      height: obj.height,
      bmi: bmiValue
    }))
  }


  //_______________________________________RENDERING_COMPONENT_______________________________________
  return (
    <div className="App">
      <Header
        loggedState={logged}
        handleLoggedState={changeLoggedState}
        handleClick={toggleLoginTabFunction}
        userInfos={user}
        handleCurrentPage={setPage}
      />
      <SideMenu
        handleClick={changePage}
        getLoggedState={logged}
      />
      {loginTabShown && <LoginTab
        handleClick={changePage}
        currentPage={pageName}
        handleLoginTab={toggleLoginTab}
        handleLoginState={changeLoggedState}
        handleUserInfos={changeUser}
      />}
      <MainPage
        currentPage={pageName}
        userInfos={user}
        changeUserInfos={changeUser}
        handleCurrentPage={setPage}
        currentLoggedState={logged}
        handleLoggedState={changeLoggedState}
        showLoginTab={toggleLoginTabFunction}
      />
      <Footer />
    </div>
  );
}

export default App;