import React from "react";

//IMPORTING STYLE
import '../styles/HomePage.css';

//IMPORTING IMAGES
import background_image_1 from "../images/first-tab-background.png"
import background_image_2 from "../images/pc_background.png"
import background_image_3 from "../images/first_tab_image.webp"

export default function HomePage() {
    //_________________________________RENDERING_COMPONENT_________________________________
    return (
        <div id="home_page">
            <div className="home_tab">
                <section className="home_section">
                    <p className="home_section_title">MANGIA BENE<br></br>VIVI MEGLIO</p>
                    <p className="home_section_label">Siamo entusiasti di presentarti <span>Smart Meal</span>, il tuo compagno affidabile
                        nella gestione alimentare intelligente. Inserisci i pasti consumati e consulta i tuoi fabbisogni essenziali</p>

                </section>
                <img id="first_tab_background" src={background_image_3} draggable="false"></img>
            </div>
            <div className="home_tab_blueberry">
                <img id="second_tab_background" src={background_image_1} draggable="false"></img>
                <section className="home_section">
                    <p className="home_section_title">GESTISCI LA TUA ALIMENTAZIONE</p>
                    <p className="home_section_label">Tieni sotto controllo il tuo progresso con strumenti intuitivi di monitoraggio.
                        Visualizza il tuo consumo calorico, le tue abitudini alimentari e resta motivato nel raggiungere i tuoi obiettivi.</p>
                </section>
            </div>
            <div className="home_tab">
                <section className="home_section">
                    <p className="home_section_title">CONTROLLA LA TUA ALIMENTAZIONE</p>
                    <p className="home_section_label">Tieni sotto controllo il tuo progresso con strumenti intuitivi di monitoraggio. Visualizza i tuoi fabbisogni, le tue abitudini alimentari e resta motivato nel raggiungere i tuoi obiettivi.</p>
                </section>
                <img id="third_tab_background" src={background_image_2} draggable="false"></img>
            </div>
        </div>
    )
}