import React, { useEffect, useRef } from "react";

import "../styles/HistoricPage.css"

export default function HistoricPageNonLogged(props) {
    //________________________________EFFECTS________________________________
    useEffect(() => {
        let date = new Date()
        setTest(date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear())
        /* document.getElementById("pie_chart").style.background = "conic-gradient(#ef5350 0% 30%, #ffd904 30% 50%, #4e27ff 50% 60%, #66bb6a 60% 75%, #ab47bc 75% 85%, #86c9ff 85% 100%);" */
        document.getElementById("pie_chart").style.animation = "filling_up 0s 0s ease 1 forwards"

        document.getElementById("header_account_button").disabled = true

        //SET ANIMATIONS
        document.getElementById("historic_cover_non_logged").style.animation = "historic_not_logged_screen_blur .7s ease forwards"
        document.getElementById("historic_options_label").style.animation = "historic_move_in .6s ease .1s forwards"
        document.getElementById("historic_not_logged_login_button").style.animation = "historic_move_in .6s ease .3s forwards"
        document.getElementById("historic_vertical_bar").style.animation = "historic_move_in .6s ease .4s forwards"
        document.getElementById("historic_not_logged_register_button").style.animation = "historic_move_in .6s ease .5s forwards"

    }, [])
    const [test, setTest] = React.useState("")


    //_________________________________DECLARING_FUNCTIONS_________________________________
    //LOGIN
    function loginButton() {
        props.showLoginTab()
    }

    //LOGOUT
    function logoutButton() {
        props.handleCurrentPage("register-page")
        document.getElementById("header_account_button").disabled = false
    }


    //________________________________RENDERING_COMPONENT________________________________
    return (
        <div id="historic_page">
            <div id="historic_container">
                <div id="historic_header">
                    <p id="historic_header_title">Storico dei Cibi Mangiati</p>
                    <select
                        id="filter_switch"
                        value={"month"}
                    >
                        <option value="day">Giorno</option>
                        <option value="month">Mese</option>
                        <option value="year">Anno</option>
                    </select>
                </div>
                <div id="historic_content">
                    <div id="historic_content_foods">
                        <p id="historic_content_foods_title">{test}</p>
                        <div id="historic_content_foods_container">
                            <p id="historic_content_food">fragole - 80g</p>
                            <p id="historic_content_food">banana - 60g</p>
                            <p id="historic_content_food">pasta di semola - 120g</p>
                            <p id="historic_content_food">pancetta affumicata - 80g</p>
                            <p id="historic_content_food">bignè - 40g</p>
                            <p id="historic_content_food">carne di cavallo - 100g</p>
                        </div>
                    </div>
                    <div id="graphs">
                        <div id="pie_chart" style={{
                            background: `conic-gradient(#ffd904 0% 33.3%, #4e27ff 33.3% 66.6%, #66bb6a 66.6% 100%)`
                        }}></div>
                        <div id="pie_chart_values">
                            <table id="pie_chart_table">
                                <tr><td><p id="calorie">Calorie</p></td><td>949.4 Kcal</td></tr>
                                <tr><td><p id="proteine">Proteine</p></td><td>202.4 Kcal</td></tr>
                                <tr><td><p id="grassi">Grassi</p></td><td>257.3 Kcal</td></tr>
                                <tr><td><p id="carboidrati">Carboidrati</p></td><td>522.4 Kcal</td></tr>
                                <tr><td><p id="zuccheri">Zuccheri</p></td><td><p>0000000</p></td></tr>
                                <tr><td><p id="fibra_alimentare">Fibra Alimentare</p></td><td><p>5.7 g</p></td></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div id="historic_cover_non_logged">
                <div id="historic_options_container">
                    <p id="historic_options_label">Per utilizzare questa pagina:</p>
                    <div id="historic_buttons_container">
                        <button id="historic_not_logged_login_button" onClick={loginButton}>Accedi</button>
                        <div id="historic_vertical_bar"></div>
                        <button id="historic_not_logged_register_button" onClick={logoutButton}>Registrati</button>
                    </div>
                </div>
            </div>
        </div >
    )
}