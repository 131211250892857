import React, { useEffect, useRef } from "react";

import "../styles/HistoricPage.css"

export default function HistoricPage() {
    //________________________________EFFECTS________________________________
    useEffect(() => {
        let date = new Date()
        setTest(date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear())
        loadFoods("month")
        document.getElementById("pie_chart").style.animation = "filling_up .7s .2s ease 1 forwards"

        document.getElementById("graphs").addEventListener("animationend", () => {
            if (setlistener.current) {
                document.getElementById("graphs").style.animation = "flip_graph_card_2 .4s ease-out 1 forwards"
            }
        })
    }, [])


    const setlistener = useRef(false)

    //________________________________DECLARING_STATES________________________________
    const [filter, setFilter] = React.useState("month")
    const [foodsArray, setFoodsArray] = React.useState([])
    const [test, setTest] = React.useState("")
    const [foodsValues, setFoodValues] = React.useState({
        calorie: 0,
        proteine: 0,
        grassi_totali: 0,
        carboidrati: 0,
        zuccheri: 0,
        fibra_alimentare: 0
    })

    const [percentages, setPercentages] = React.useState({
        first_stop: "33.3%",
        second_stop: "66.6%"
    })


    //________________________________DECLARING_FUNCTIONS________________________________
    function requestFoods(date, filterValue) {
        const httpr = new XMLHttpRequest()
        httpr.onreadystatechange = () => {
            if (httpr.readyState == 4 && httpr.status == 200) {
                switch (httpr.response) {
                    case "no_foods_recorded":
                        setFoodsArray([])
                        return
                    default:
                        //IF RESPONSE CONTAINS Error
                        if (httpr.responseText.substring(0, 5) === "Error") {
                            console.log(httpr.response)
                            return;
                        }

                        //DEFAULT ACTION
                        setFoodsArray(() => {
                            let foodsList = JSON.parse(httpr.response)
                            let tempObj = {
                                calorie: 0,
                                proteine: 0,
                                grassi_totali: 0,
                                carboidrati: 0,
                                zuccheri: 0,
                                fibra_alimentare: 0
                            }
                            for (let i = 0; i < foodsList.length; i++) {
                                while ((i + 1) < foodsList.length && foodsList[i].name === foodsList[i + 1].name) {
                                    //DUPLICATED FOODS FOUND
                                    foodsList[i].weight = parseFloat(foodsList[i].weight) + parseFloat(foodsList[i + 1].weight)
                                    foodsList.splice(i + 1, 1)
                                }

                                let calorie = tempObj.calorie + parseFloat(foodsList[i].calories)
                                let proteine = tempObj.proteine + parseFloat(foodsList[i].protein)
                                let grassi_totali = tempObj.grassi_totali + parseFloat(foodsList[i].totalFat)
                                let carboidrati = tempObj.carboidrati + parseFloat(foodsList[i].carbohydrate)
                                let zuccheri = tempObj.zuccheri + parseFloat(foodsList[i].sugar)
                                let fibra_alimentare = tempObj.fibra_alimentare + parseFloat(foodsList[i].dietaryFiber)

                                tempObj = {
                                    calorie: calorie,
                                    proteine: proteine,
                                    grassi_totali: grassi_totali,
                                    carboidrati: carboidrati,
                                    zuccheri: zuccheri,
                                    fibra_alimentare: fibra_alimentare
                                }
                            }
                            setFoodValues(() => {
                                setPercentages(() => {
                                    let total = tempObj.proteine * 4 + tempObj.grassi_totali * 9 + tempObj.carboidrati * 4
                                    let percentualeProteine = ((tempObj.proteine * 4) / total) * 100
                                    let percentualeGrassi = ((tempObj.grassi_totali * 9) / total) * 100
                                    return {
                                        first_stop: (Math.round(percentualeProteine * 10) / 10) + "%",
                                        second_stop: (Math.round(percentualeGrassi * 10) / 10) + "%"
                                    }
                                })
                                let date = new Date()
                                if (filterValue === "month" || filterValue === "year") {
                                    return {
                                        calorie: Math.round((tempObj.calorie / parseInt(date.getDate())) * 10) / 10,
                                        proteine: Math.round((tempObj.proteine / parseInt(date.getDate())) * 40) / 10,
                                        grassi_totali: Math.round((tempObj.grassi_totali / parseInt(date.getDate())) * 90) / 10,
                                        carboidrati: Math.round((tempObj.carboidrati / parseInt(date.getDate())) * 40) / 10,
                                        zuccheri: Math.round((tempObj.zuccheri / parseInt(date.getDate())) * 40) / 10,
                                        fibra_alimentare: Math.round((tempObj.fibra_alimentare / parseInt(date.getDate())) * 10) / 10,
                                    }
                                }
                                return {
                                    calorie: Math.round(tempObj.calorie * 10) / 10,
                                    proteine: Math.round(tempObj.proteine * 40) / 10,
                                    grassi_totali: Math.round(tempObj.grassi_totali * 90) / 10,
                                    carboidrati: Math.round(tempObj.carboidrati * 40) / 10,
                                    zuccheri: Math.round(tempObj.zuccheri * 40) / 10,
                                    fibra_alimentare: Math.round(tempObj.fibra_alimentare * 10) / 10,
                                }
                            })
                            return foodsList
                        })
                        break
                }
            }
        }
        httpr.open("POST", "php/historic.php", true)
        httpr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
        httpr.send(`date=${date}&filter=${filterValue}`)
    }

    //FILTER CHANGE
    function filterSwitchChange(e) {
        let filterValueTemp = e.target.value
        setFilter(filterValueTemp)
        e.target.blur()
        setTest(filterValueTemp)
        loadFoods(filterValueTemp)
        let date = new Date()
        switch (filterValueTemp) {
            case "day":
                setTest(date.getDate() + " " + date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear())
                break
            case "month":
                setTest(date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear())
                break
            case "year":
                setTest(date.getFullYear())
                break
        }
        changeFilterAnimation()
    }

    //ON RENDER FUNCTION
    function loadFoods(filterValue) {
        let date = new Date()
        let formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
        requestFoods(formattedDate, filterValue)
    }


    //________________________________CREATING_ANIMATIONS________________________________
    //CHANGE FILTER ANIMATION
    function changeFilterAnimation() {
        setlistener.current = true
        document.getElementById("graphs").style.animation = "flip_graph_card_1 .4s ease-in 1"
    }


    //________________________________CREATING_ELEMENTS________________________________
    const createFoods = foodsArray.map((food) => {
        return (
            <p id="historic_content_food">{food.name} - {food.weight}g</p>
        )
    })

    //________________________________RENDERING_COMPONENT________________________________
    return (
        <div id="historic_page">
            <div id="historic_container">
                <div id="historic_header">
                    <p id="historic_header_title">Storico dei Cibi Mangiati</p>
                    <select
                        id="filter_switch"
                        value={filter}
                        onChange={(e) => filterSwitchChange(e)}
                    >
                        <option value="day">Giorno</option>
                        <option value="month">Mese</option>
                        <option value="year">Anno</option>
                    </select>
                </div>
                <div id="historic_content">
                    <div id="historic_content_foods">
                        {foodsArray.length > 0 && <p id="historic_content_foods_title">{test}</p>}
                        {foodsArray.length > 0
                            ? <div id="historic_content_foods_container">
                                {createFoods}
                            </div>
                            : <div id="no_foods_registered">
                                <p>Nessun alimento registrato</p>
                            </div>
                        }
                    </div>
                    <div id="graphs">
                        <div id="pie_chart" style={{
                            background: `conic-gradient(#ffd904 0% ${percentages.first_stop}, #4e27ff ${percentages.first_stop} ${percentages.second_stop}, #66bb6a ${percentages.second_stop} 100%)`
                        }}></div>
                        <div id="pie_chart_values">
                            {(filter === "month" || filter === "year") && <p id="daily_average_p">Media Giornaliera</p>}
                            <table id="pie_chart_table">
                                <tr><td><p id="calorie">Calorie</p></td><td>{foodsValues.calorie}Kcal</td></tr>
                                <tr><td><p id="proteine">Proteine</p></td><td>{foodsValues.proteine}Kcal</td></tr>
                                <tr><td><p id="grassi">Grassi</p></td><td>{foodsValues.grassi_totali}Kcal</td></tr>
                                <tr><td><p id="carboidrati">Carboidrati</p></td><td>{foodsValues.carboidrati}Kcal</td></tr>
                                <tr><td><p id="zuccheri">Zuccheri</p></td><td><p>{foodsValues.zuccheri}Kcal</p></td></tr>
                                <tr><td><p id="fibra_alimentare">Fibra Alimentare</p></td><td><p>{foodsValues.fibra_alimentare}g</p></td></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}