import React from "react";

//IMPORTING STYLE
import '../styles/Header.css';

//IMPORTING ICONS
import menu_logo from "../icons/menu_logo.svg"
import man_icon from "../icons/man_icon.png"
import edit_icon from "../icons/edit_icon.png"
import logout_icon from "../icons/logout_icon.png"

export default function Header(props) {
    //_________________________________CREATING_PAGE_COMPONENTS_________________________________
    //RENDER THE ACCOUNT DROPDOWN 
    const accountDropdown = () => {
        if (props.loggedState) {
            return (
                <div id="account_dropdown_infos">
                    <table id="account_dropdown_infos_table">
                        <tr>
                            <td className="account_dropdown_infos_label"><p className="label">Nome:</p></td>
                            <td className="account_dropdown_infos_value"><p>{props.userInfos.fName}</p></td>
                        </tr>
                        <tr>
                            <td className="account_dropdown_infos_label"><p className="label">Cognome:</p></td>
                            <td className="account_dropdown_infos_value"><p>{props.userInfos.lName}</p></td>
                        </tr>
                        <tr>
                            <td className="account_dropdown_infos_label"><p className="label">Età:</p></td>
                            <td className="account_dropdown_infos_value"><p>{props.userInfos.age}</p></td>
                        </tr>
                        <tr>
                            <td className="account_dropdown_infos_label"><p className="label">Altezza:</p></td>
                            <td className="account_dropdown_infos_value"><p>{props.userInfos.height} cm</p></td>
                        </tr>
                        <tr>
                            <td className="account_dropdown_infos_label"><p className="label">Peso:</p></td>
                            <td className="account_dropdown_infos_value"><p>{props.userInfos.weight} Kg</p></td>
                        </tr>
                        <tr>
                            <td className="account_dropdown_infos_label"><p className="label">BMI:</p></td>
                            <td className="account_dropdown_infos_value"><p>{props.userInfos.bmi}</p></td>
                        </tr>
                    </table>
                    <div id="accoutnt_dropdown_buttons_container">
                        <button id="edit_profile_button" onClick={editProfile}><img id="edit_profile_button_icon" src={edit_icon} draggable="false"></img></button>
                        <button id="logout_button" onClick={logOut}><img id="logout_button_icon" src={logout_icon} draggable="false"></img></button>
                    </div>
                </div>
            )
        } else {
            return (
                <div id="account_dropdown_login">
                    <p>Effettua il login</p>
                    <button id="login_button" onClick={loginButtonClick}>Accedi</button>
                </div>
            )
        }
    }


    //_________________________________DECLARING_FUNCTIONS_________________________________
    //SHOWING THE SIDE MENU ON THE MENU BUTTON CLICK
    function side_menu_handler() {
        document.getElementById("side_menu").className = "side_menu_on"
        document.body.style.overflow = "hidden"
        //CLOSING THE ACCOUNT DROPDOWN IF OPEN
        if (document.getElementById("account_dropdown").className === "account_dropdown_on")
            document.getElementById("account_dropdown").className = "account_dropdown_off"
        if (document.getElementById("account_dropdown_infos_cover").classList.contains("account_dropdown_infos_cover_on")) {
            document.getElementById("account_dropdown_infos_cover").classList.toggle("account_dropdown_infos_cover_on")
        }
    }

    //SHOWING THE ACCOUNT DROPDOWN BY CLICKING THE ACCOUNT BUTTON IN THE HEADER
    function account_button_handler() {
        if (document.getElementById("account_dropdown").className === "account_dropdown_off") {
            document.getElementById("account_dropdown").className = "account_dropdown_on"
            if (!document.getElementById("account_dropdown_infos_cover").classList.contains("account_dropdown_infos_cover_on")) {
                document.getElementById("account_dropdown_infos_cover").classList.toggle("account_dropdown_infos_cover_on")
            }
            document.body.style.overflow = "hidden"
        } else {
            document.getElementById("account_dropdown").className = "account_dropdown_off"
            if (document.getElementById("account_dropdown_infos_cover").classList.contains("account_dropdown_infos_cover_on")) {
                document.getElementById("account_dropdown_infos_cover").classList.toggle("account_dropdown_infos_cover_on")
            }
            document.body.style.overflow = ""
        }
        //CLOSING THE SIDE MENU IF OPEN
        if (document.getElementById("side_menu").className === "side_menu_on")
            document.getElementById("side_menu").className = "side_menu_off"
    }

    //LOGIN BUTTON IN THE ACCOUNT DROPDOWN
    function loginButtonClick() {
        document.getElementById("account_dropdown").className = "account_dropdown_off"
        document.getElementById("account_dropdown_infos_cover").classList.toggle("account_dropdown_infos_cover_on")
        document.getElementById("header_account_button").disabled = true
        document.getElementById("header_menu_button").disabled = true
        props.handleClick()
        document.body.style.overflow = "hidden"                 //DISABLE SCROLLING
    }

    //EDIT PROFILE BUTTON
    function editProfile() {
        document.getElementById("account_dropdown").className = "account_dropdown_off"
        document.getElementById("account_dropdown_infos_cover").classList.toggle("account_dropdown_infos_cover_on")
        props.handleCurrentPage("edit-profile-page")
    }

    //LOGOUT BUTTON
    function logOut() {
        //CLEAR COOKIES
        const clearCookieRequest = new XMLHttpRequest()
        clearCookieRequest.onreadystatechange = () => {
            if (clearCookieRequest.readyState == 4 && clearCookieRequest.status == 200) {

            }
        }
        clearCookieRequest.open("POST", "php/clearCookie.php", true)
        clearCookieRequest.send()

        //CLOSING THE ACCOUNT DROPDOWN
        document.getElementById("account_dropdown").className = "account_dropdown_off"
        document.getElementById("account_dropdown_infos_cover").classList.remove("account_dropdown_infos_cover_on")
        document.body.style.overflow = ""

        props.handleLoggedState()
        props.handleCurrentPage("home-page")
    }

    //CLICKING OUTSIDE THE ACCOUNT DROPDOWN
    function closeAccountInfos() {
        document.getElementById("account_dropdown").className = "account_dropdown_off"
        document.getElementById("account_dropdown_infos_cover").classList.toggle("account_dropdown_infos_cover_on")
        document.body.style.overflow = ""
    }


    //_________________________________RENDERING_COMPONENT_________________________________
    return (
        <header id="header_container">
            <div id="header">
                <button id="header_menu_button" onClick={side_menu_handler}>
                    <img src={menu_logo} id="header_menu_logo" draggable="false"></img>
                </button>
                <h2 id="header_title">SMART MEAL</h2>
                <div>
                    <button id="header_account_button" onClick={account_button_handler}><img src={man_icon} id="account_icon" draggable="false"></img></button>
                    <div id="account_dropdown" className="account_dropdown_off">
                        {accountDropdown()}
                    </div>
                </div>
            </div>
            <div id="account_dropdown_infos_cover" onClick={closeAccountInfos} onTouchStart={closeAccountInfos}></div>
        </header >
    )
}