import React from "react";

import "../styles/EditPassword.css"

export default function EditPassword(props) {
    //DISABLING HEADER BUTTON
    document.getElementById("header_account_button").disabled = true

    //______________________________DECLARING_STATES______________________________
    const [email, setEmail] = React.useState("")
    const [message, setMessage] = React.useState("Ti abbiamo inviato una e-mail, segui le istruzioni per completare il reset della password")


    //______________________________DECLARING_FUNCTIONS______________________________
    function sendRequest() {
        document.getElementById("edit_password_email_sent").style.animation = "edit_password_blur .5s ease .4s 1 forwards"
        document.getElementById("edit_password_email_sent_content").style.animation = "edit_password_move_in .6s ease .7s 1 forwards"
        document.getElementById("reset_passwordlogin_button_email_sent").style.animation = "edit_password_move_in .6s ease .9s 1 forwards"

        const httpr = new XMLHttpRequest()
        httpr.onreadystatechange = () => {
            if (httpr.readyState == 4 && httpr.status == 200) {
                switch (httpr.response) {
                    case "email_inexistent":
                        setMessage("E-mail inesistente, non c'è nessun account con questa e-mail")
                        document.getElementById("reset_passwordlogin_button_email_sent").innerHTML = "Home"
                        document.getElementById("reset_passwordlogin_button_email_sent").onclick = () => goToHome()
                        document.getElementById("edit_password_email_sent").className = "edit_password_email_sent_on"
                        return
                    case "email_sent":
                        setMessage("Ti abbiamo inviato una e-mail, segui le istruzioni per completare il reset della password")
                        document.getElementById("reset_passwordlogin_button_email_sent").innerHTML = "Login"
                        document.getElementById("reset_passwordlogin_button_email_sent").onclick = () => goToLogin()
                        document.getElementById("edit_password_email_sent").className = "edit_password_email_sent_on"
                        return
                    default:
                        if (httpr.responseText.substring(0, 5) === "Error") {
                            console.log(httpr.response)
                            return;
                        }
                        break;
                }
            }
        }
        httpr.open("POST", "php/changePasswordRequest.php", true);
        httpr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        httpr.send(`email=${email}`);
    }

    //GO TO LOGIN AFTER CLICKING CONF
    function goToLogin() {
        document.body.scrollIntoView({ block: "start", inline: "nearest" })
        props.handleCurrentPage("home-page")
        document.body.style.overflow = "hidden"
        document.getElementById("header_account_button").disabled = true
        document.getElementById("header_menu_button").disabled = true
        props.showLoginTab()
    }

    function goToHome() {
        document.body.scrollIntoView({ block: "start", inline: "nearest" })
        props.handleCurrentPage("home-page")
        document.body.style.overflow = ""
        document.getElementById("header_account_button").disabled = false
        document.getElementById("header_menu_button").disabled = false
    }

    //______________________________RENDERING_THE_COMPONENT______________________________
    return (
        <div id="edit_password_page">
            <div id="edit_password_email_input">
                <p id="edit_password_email_title">Ripristina la Password</p>
                <div id="edit_password_input_container">
                    <p id="edit_password_email_description">Inserisci la e-mail alla quale desideri ricevere la procedura di reset della password</p>
                    <input
                        id="edit_password_input"
                        placeholder="E-mail"
                        maxLength={35}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <button id="edit_password_submit" onClick={sendRequest}>Invia</button>
            </div>
            <div id="edit_password_email_sent" className="edit_password_email_sent_off">
                <div id="edit_password_email_sent_content">
                    <p>{message}</p>
                    <button
                        id="reset_passwordlogin_button_email_sent"
                    >Login</button>
                </div>
            </div>
        </div>
    )
}